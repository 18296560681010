<template>
  <div class="overlay-instock" v-show="current == 'INSTOCK'">
    <div class="overlay-instock__heading">
      <h3 class="overlay-instock__title">READY TO SHIP</h3>
      <OverlayCloseButton class="overlay-instock__close" aria-label="Close" @click="close" />
    </div>
    <div class="overlay-instock__text">
      <p>* Indicates this option is ready to ship in 1-3 business days.</p>
      <p>
        If you are purchasing a made to order piece in addition to something that is in stock, they will
        be shipped together when both are ready. If you would like your in-stock item earlier, please
        place two separate orders.
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import OverlayCloseButton from './OverlayCloseButton.vue'
import { useOverlayInject } from '../../context/useOverlayContext'

const { current, close } = useOverlayInject()
</script>

<style lang="scss">
@import '~styles/base';

.overlay-instock {
  position: fixed;
  z-index: 1000;
  padding: 15px 30px 30px;
  background: var(--color-light);
  border: 1px solid var(--color-dark);

  @include breakpoint('l+') {
    top: 190px;
    right: columns(5);
    width: columns(6.5);
  }

  @include breakpoint(l) {
    top: 120px;
    right: 30px;
    left: 30px;
    width: auto;
  }

  &__heading {
    position: relative;
    margin-bottom: 30px;
  }
  &__title {
    @include uppercase;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 15px;
    height: 15px;
    cursor: pointer;
    i {
      width: 100%;
      height: 1px;
      background: #000;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      &:last-child {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  &__text {
    @include content;
  }
}
</style>
