<template>
  <div class="product-slideshow-controls">
    <button
      @click="onPrev"
      class="product-slideshow-controls__button product-slideshow-controls__button--prev"
      v-if="ifPrev"
    >
      ←
    </button>
    <button
      @click="onNext"
      class="product-slideshow-controls__button product-slideshow-controls__button--next"
      v-if="ifNext"
    >
      →
    </button>
    <div class="product-slideshow-controls__pager">{{ index + 1 }} / {{ total }}</div>
  </div>
</template>

<script lang="ts" setup>
import { computed, unref } from 'vue'
import { useSlideshowInject } from '../../context/useSlideshowContext'

const { emblaApi, index, total } = useSlideshowInject()

const onPrev = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollPrev()
  }
}

const onNext = () => {
  if (emblaApi.value) {
    emblaApi.value.scrollNext()
  }
}

const ifPrev = computed(() => unref(index) > 0)
const ifNext = computed(() => unref(index) < unref(total) - 1)
</script>

<style lang="scss">
@import '~styles/base';

.product-slideshow-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;

  &:focus,
  &:hover {
    .product-slideshow-controls__button {
      opacity: 1;
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s ease;
    padding: var(--gutter);

    @include breakpoint('l+') {
      opacity: 0;
    }
    @include breakpoint('l') {
      pointer-events: auto;
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }

  &__pager {
    position: absolute;
    bottom: 10px;
    right: var(--gutter);
  }
}
</style>
