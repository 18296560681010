<template>
  <div class="footer-display" ref="footerEl">
    <div class="footer-display__inner">
      <div class="footer-display__logo">
        <SvgMonogram class="footer-display__monogram" />
      </div>
      <div class="footer-display__newsletter">
        <h2 class="footer-display__heading">Newsletter Sign Up</h2>
        <Newsletter />
      </div>
      <SanityLinks class="footer-display__menu" :links="linklists['footer'].links" />
      <div class="footer-display__credit">
        <span>© J. Hannah</span>
        <span>Made in Los Angeles</span>
        <a class="footer-display__link" href="https://1of1studio.com/" target="_blank" rel="nofollow">
          Site by 1/1
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Ref, onMounted, onUnmounted, ref } from 'vue'

import SvgMonogram from '../svg/SvgMonogram.vue'
import Newsletter from '../newsletter/Newsletter.vue'
import SanityLinks from '../shopify/link/ShopifyLinks.vue'
import { useAppInject } from '../../context/useAppContext'

const { setFooterHeight, linklists } = useAppInject()
const footerEl: Ref<HTMLElement | null> = ref(null)

onMounted(() => {
  if (footerEl.value) setFooterHeight(footerEl.value.offsetHeight)
})

onUnmounted(() => {
  setFooterHeight(0)
})
</script>

<style lang="scss">
@import '~styles/base.scss';

.footer-display {
  @include breakpoint('l+') {
    padding-top: 220px;
    padding-bottom: 80px;
  }
  @include breakpoint('l') {
    padding-top: 160px;
    padding-bottom: 30px;
  }

  &__inner {
    @include wrapper;
    gap: var(--gutter);
    display: grid;

    @include breakpoint('l+') {
      grid-template-columns: #{columns(4)} #{columns(8)} #{columns(9)} #{columns(3)};
    }
  }

  &__logo {
    padding-left: #{columns(2, 0)};

    @include breakpoint('l') {
      display: none;
    }
  }

  &__monogram {
    width: 50px;
    height: 50px;
  }

  &__heading {
    padding-bottom: 1em;
  }

  &__menu {
    display: grid;
    gap: 4px var(--gutter);

    .shopify-link {
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }

    @include breakpoint('l+') {
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }

    @include breakpoint('l') {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 40px;
    }
  }

  &__newsletter {
    @include breakpoint('l') {
      padding-bottom: 60px;
    }
  }

  &__credit {
    display: flex;
    flex-direction: column;
    gap: 4px 0;
  }
}
</style>
