import { ProductFieldsFragment, VariantFieldsFragment } from '_library/src/storefront/generated'
import { fetchProductByHandle, DEFAULT_COUNTRY_CODE, AddLineItemProps } from '_library'
import { Ref, computed, onMounted, ref, toValue, unref, watchEffect } from 'vue'
import { ProductSelectionType, useProductSelection } from './useProductSelection'
import { gidToNumber } from '../utils/shopify'

type Mode = 'INSIDE' | 'OUTSIDE'

export const fontFromVariant = (variant: VariantFieldsFragment) => {
  return variant.title?.split(' - ')?.[1]?.split(' (')?.[0]
}

export type ProductEngravingType = {
  title: string
  enabled: boolean
  isActive: Ref<boolean>
  selection: Ref<ProductSelectionType>
  characters: string[]
  characterLimit: number
  engravingProduct: Ref<ProductFieldsFragment | null>
  selectedFont: Ref<string>
  selectedCharacters: Ref<string>
  cartItem: Ref<AddLineItemProps>
  selectFont: (font: string) => void
  setIsActive: (isActive: boolean) => void
  addCharacter: (character: string) => void
}

export const useProductEngraving = (product: ProductFieldsFragment, mode: Mode) => {
  let handle: string, title: string, characterLimit: number, enabled: boolean, characters: string[]

  const isActive: Ref<boolean> = ref(false)
  const engravingProduct: Ref<ProductFieldsFragment | null> = ref(null)
  const selection: Ref<ProductSelectionType | null> = ref(null)

  if (mode == 'INSIDE') {
    enabled = product.engravingInsideEnabled?.value == 'true'
    const fallbackTitle = product.productType == 'Ring' ? 'Inside Engraving' : 'Back Engraving'
    title = product.engravingInsideTitle?.value || fallbackTitle
    handle = product.engravingInsideHandle?.value || 'inside-engraving'
    characterLimit = parseInt(product.engravingInsideCharacterLimit?.value || '1')
    characters = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ☺♡⎵']
  } else {
    enabled = product.engravingOutsideEnabled?.value == 'true' || false
    title = product.engravingOutsideTitle?.value || 'Front Engraving'
    handle = product.engravingOutsideHandle?.value || 'front-engraving'
    characterLimit = parseInt(product.engravingOutsideCharacterLimit?.value || '1')
    characters = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']
  }

  const selectedFont = ref('')
  const selectedCharacters = ref('')

  const setIsActive = (newIsActive: boolean) => (isActive.value = newIsActive)
  const selectFont: ProductEngravingType['selectFont'] = (font) => (selectedFont.value = font)
  const addCharacter: ProductEngravingType['addCharacter'] = (character) => {
    if (selectedCharacters.value.length < characterLimit) {
      selectedCharacters.value = `${selectedCharacters.value}${character}`
    } else {
      selectedCharacters.value = `${character}`
    }
  }

  onMounted(async () => {
    engravingProduct.value = await fetchProductByHandle(handle, { countryCode: DEFAULT_COUNTRY_CODE })
    if (engravingProduct.value) {
      selection.value = useProductSelection(engravingProduct.value)
      selectedFont.value = fontFromVariant(engravingProduct.value?.variants.edges[0].node)
    }
  })

  const cartItem = computed(() => {
    if (!enabled) return
    if (!isActive.value) return
    if (!selection.value) return

    const selectedVariant = toValue(selection.value.selectedVariant)
    const variantId = gidToNumber(selectedVariant.id)

    return {
      variantId,
      quantity: 1,
      properties: {
        Product: `${product.title}`,
        Engraving: selectedCharacters.value,
        'Engraving font': selectedFont.value,
      },
    }
  })

  // Watch for font change
  watchEffect(() => {
    if (!selection?.value?.selectedVariant) return
    const font = fontFromVariant(unref(selection.value.selectedVariant))
    selectedFont.value = font
  })

  return {
    title,
    enabled,
    isActive,
    selection,
    characterLimit,
    characters,
    engravingProduct,
    selectedFont,
    selectedCharacters,
    selectFont,
    addCharacter,
    setIsActive,
    cartItem,
  } as ProductEngravingType
}
