<template>
  <div class="overlay-question" v-if="current == 'QUESTION'">
    <div class="overlay-question__heading">
      <h3 class="overlay-question__heading--center">Ask a Question</h3>
      <p class="overlay-question__variant-title" v-text="title"></p>
      <OverlayCloseButton class="overlay-question__close" />
    </div>
    <form class="overlay-question__form" @submit.prevent="onSubmit">
      <input name="contact[product]" type="hidden" v-if="productTitle" :value="productTitle" />
      <input v-if="variantTitle" name="contact[variant]" type="hidden" :value="variantTitle" />
      <label for="emailAddress" class="overlay-question__form__label">Email Address</label>
      <input
        class="overlay-question__form__input"
        name="emailAddress"
        type="email"
        v-model="email"
        required
      />
      <label for="questionText" class="overlay-question__form__label">Query</label>
      <textarea
        class="overlay-question__form__textarea overlay-question__form__textarea--min-height"
        name="questionText"
        v-model="query"
        required
      ></textarea>
      <div class="overlay-question__form__submit">
        <button class="overlay-question__form__submit__button">Submit</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useOverlayInject } from '../../context/useOverlayContext'
import { submitContact } from '../../services/shopify/account'
import OverlayCloseButton from './OverlayCloseButton.vue'

const email = ref('')
const query = ref('')

const { current, close } = useOverlayInject()

const props = defineProps<{
  productTitle: string
  variantTitle?: string
}>()

const title =
  props.variantTitle && props.variantTitle != 'Default Title' ? props.variantTitle : props.productTitle

const onSubmit = async (event: any) => {
  const form = event.target as HTMLFormElement
  const formData = new FormData(form)
  const formValues = Object.fromEntries(formData as any) as { [key: string]: string }
  await submitContact(formValues)
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-question {
  position: fixed;
  z-index: 1000;
  padding: 15px 30px 30px;
  background: var(--color-light);
  border: 1px solid var(--color-dark);

  @include breakpoint('l+') {
    top: 190px;
    right: columns(4.5, 1);
    width: columns(6.5, 0);
  }

  @include breakpoint(l) {
    top: 120px;
    right: 30px;
    left: 30px;
    width: auto;
  }

  &__heading {
    position: relative;
    margin-bottom: 30px;

    &--center {
      @include uppercase;
      text-align: center;
    }
  }

  &__variant-title {
    margin-top: 10px;
    text-align: center;
  }

  &__form {
    &__label {
      display: block;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    &__input,
    &__textarea {
      @include text-xsmall;
      width: 100%;
      border: none;
      font-family: inherit;
      border-bottom: 1px solid var(--color-dark);
    }

    &__textarea--min-height {
      min-height: 66px;
    }

    &__submit {
      display: flex;
      justify-content: center;
      padding-top: 40px;

      &__button {
        width: auto;
        font-family: inherit;
        text-transform: uppercase;
        @include helper-underline;

        &:focus,
        &:hover {
          &:after {
            border: none;
          }
        }
      }
    }

    &__info-text {
      @include content;
    }
  }
}
</style>
