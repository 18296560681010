<template>
  <div :class="`newsletter newsletter--${mode}`">
    <transition name="fade" mode="out-in">
      <div v-if="message" key="message" class="newsletter__message" v-html="message"></div>
      <div v-else-if="note" key="note" class="newsletter__note" v-html="note" />
    </transition>

    <form method="get" class="newsletter__form" @submit.prevent="submit">
      <input
        v-model="email"
        aria-label="Email address"
        type="text"
        required
        placeholder="Email Address"
        class="newsletter__email"
      />
      <button class="newsletter__submit">Subscribe</button>
    </form>
    <img v-if="image" :src="image.src" :alt="image.alt" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { subscribe } from 'klaviyo-subscribe'

const props = withDefaults(
  defineProps<{
    listId?: string
    note?: string
    image?: any
    mode?: 'POPUP' | 'DEFAULT'
  }>(),
  {
    listId: 'M7BKjA',
    mode: 'DEFAULT',
    note: '',
  }
)

const email = ref('')
const message = ref('')

const submit = async () => {
  try {
    const response = await subscribe(props.listId, email.value)
    if (response.success) {
      message.value = 'Thanks for signing up!'
      email.value = ''
    } else {
      throw new Error(response.errors[0])
    }
  } catch (exception: any) {
    message.value = `${exception.message}`
  }
}
</script>

<style lang="scss" scoped>
@import '~styles/base';

.newsletter {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  &--POPUP {
    .newsletter__form {
      width: 100%;
    }
  }

  &__note,
  &__message {
    flex: 1;
    margin: 20px 0 20px;
  }

  &__form {
    display: flex;
    width: 180px;
    max-width: 100%;
    margin-bottom: 10px;
  }

  &__email {
    font-family: 'Folio';
    width: 100%;
    padding-right: 10px;
    border-bottom: 1px solid var(--color-dark);
  }

  &__submit {
    border-bottom: 1px solid var(--color-dark);
    color: var(--color-dark) !important;
    line-height: inherit;
  }

  @include media('xl') {
    &__form {
      width: 160px;
    }
  }
}
</style>
