<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1200 1200"
    style="enable-background: new 0 0 1200 1200"
    xml:space="preserve"
  >
    <g>
      <path
        d="M579,451.5l2.3,0.8c1.6,0.5,3.7,2.1,6.5,4.9c2.8,2.7,5.4,6.3,7.9,10.7v217.3c0,25.2-3.4,42-10.2,50.6
		c-6.8,8.6-17.8,9.2-33,2v8c6.7,4.5,13.8,7.4,21.2,8.7c7.4,1.2,14.6,0.5,21.6-2c7-2.6,13.3-6.8,19-12.7c5.6-5.9,10.2-14.2,13.6-25
		c3.4-10.8,5.2-23.2,5.2-37.3V467.8c2.5-4.4,5.2-8,8.2-10.7c3-2.7,5-4.3,6-4.8c1-0.4,1.9-0.7,2.5-0.9v-5.6H579V451.5z"
      />
      <path
        d="M297.3,157.2v5.6l2.3,0.8c1.6,0.5,3.7,2.1,6.5,4.9c2.8,2.7,5.4,6.3,7.9,10.7v71.8c-3.2,4.8-6.3,7.9-9.1,9.1h-102l-1.2-0.6
		c-0.8-0.5-2-1.5-3.5-3.1c-1.6-1.6-3.1-3.4-4.5-5.5v-71.8c2.5-4.4,5.2-8,8.2-10.7s5-4.3,6-4.8c1-0.4,1.9-0.7,2.5-0.9v-5.6h-71.1v5.6
		l2.3,0.8c1.5,0.5,3.7,2.1,6.5,4.9c2.8,2.7,5.4,6.3,7.9,10.7v196c-2.5,4.4-5.2,8-8.2,10.7c-3,2.7-5,4.3-6,4.8
		c-1,0.4-1.9,0.7-2.5,0.9v5.6h71.1v-5.6l-2.3-0.8c-1.6-0.5-3.7-2.1-6.5-4.9c-2.8-2.7-5.4-6.3-7.9-10.7v-86c3.2-4.8,6.3-7.8,9.1-9.1
		h102l1.2,0.6c0.8,0.5,2,1.6,3.5,3.1c1.5,1.5,3,3.4,4.5,5.4v86c-2.5,4.4-5.2,8-8.2,10.7s-5,4.3-6,4.8c-1,0.4-1.9,0.7-2.5,0.9v5.6
		h71.1v-5.6l-2.3-0.8c-1.6-0.5-3.7-2.1-6.5-4.9s-5.4-6.3-7.9-10.7v-196c2.5-4.4,5.2-8,8.2-10.7s5-4.3,6-4.8c1-0.4,1.9-0.7,2.5-0.9
		v-5.6H297.3z"
      />
      <path
        d="M1013.8,162.9l2.3,1c1.6,0.5,3.7,2.1,6.5,4.9c2.8,2.7,5.4,6.2,7.9,10.5v137.2L885.7,157.2H838v6l15,19.1v192.9
		c-2.5,4.4-5.2,8-8.2,10.7c-3,2.7-5,4.3-6,4.8c-1,0.5-1.9,0.8-2.5,0.9v5.6h53.5v-5.6l-0.8-0.4c-0.7-0.3-1.6-0.7-2.7-1.5
		c-1.2-0.7-2.4-1.6-3.7-2.7c-1.3-1.1-2.8-2.7-4.6-4.8c-1.8-2.1-3.4-4.4-5-7V206.7l170.6,190.6h6.8v-218c1.6-2.6,3.2-4.9,5-7
		c1.8-2.1,3.3-3.7,4.6-4.7c1.3-1.1,2.5-2,3.7-2.7c1.2-0.7,2-1.2,2.5-1.5l1-0.4v-5.6h-53.4V162.9z"
      />
      <path
        d="M594.9,1006.4l45.6,93.9h-90L594.9,1006.4z M608.4,952.5c-5.1,9.6-13.9,15.4-20.8,22.3l-92.5,195.8
		c-2.5,4.4-5.2,8-8.2,10.7c-3,2.7-5,4.3-6,4.8c-1,0.5-1.9,0.8-2.5,0.9v5.6h57.8v-5.6l-2.3-0.8c-1.6-0.5-3.7-2.1-6.5-4.9
		c-2.8-2.7-5.4-6.3-7.9-10.7l22.4-50.4h107.7l23.2,50.4c-2.5,4.3-5.2,7.7-8.2,10.4c-3,2.7-5,4.2-6,4.7c-1,0.4-1.9,0.7-2.5,0.9v6h81
		v-6l-2.1-0.6c-1.5-0.5-3.7-2.1-6.4-4.8c-2.7-2.6-5.3-6.2-7.8-10.6l-105.3-218H608.4z"
      />
      <path
        d="M316.9,806.9l2.3,1c1.6,0.5,3.7,2.1,6.5,4.9c2.8,2.7,5.4,6.2,7.9,10.5v137.2L188.8,801.3h-47.7v6l15,19.1v192.9
		c-2.5,4.4-5.2,8-8.2,10.7c-3,2.7-5,4.3-6,4.8c-1,0.5-1.9,0.8-2.5,0.9v5.6h53.5v-5.6l-0.8-0.4c-0.7-0.3-1.6-0.7-2.7-1.5
		c-1.2-0.7-2.4-1.6-3.7-2.7c-1.3-1.1-2.8-2.7-4.6-4.8c-1.8-2.1-3.4-4.4-5-7V850.7l170.6,190.6h6.8v-218c1.6-2.6,3.2-4.9,5-7
		c1.8-2.1,3.3-3.7,4.6-4.7c1.3-1.1,2.5-2,3.7-2.7c1.2-0.7,2-1.2,2.5-1.5l1-0.4v-5.6h-53.4V806.9z"
      />
      <path
        d="M997.2,801.2v5.6l2.3,0.8c1.6,0.5,3.7,2.1,6.5,4.9c2.8,2.7,5.4,6.3,7.9,10.7v71.8c-3.2,4.8-6.3,7.9-9.1,9.1h-102l-1.2-0.6
		c-0.8-0.5-2-1.5-3.5-3.1c-1.6-1.6-3.1-3.4-4.5-5.5v-71.8c2.5-4.4,5.2-8,8.2-10.7c3-2.7,5-4.3,6-4.8c1-0.4,1.9-0.7,2.5-0.9v-5.6
		h-71.1v5.6l2.3,0.8c1.5,0.5,3.7,2.1,6.5,4.9s5.4,6.3,7.9,10.7v196c-2.5,4.4-5.2,8-8.2,10.7c-3,2.7-5,4.3-6,4.8
		c-1,0.4-1.9,0.7-2.5,0.9v5.6h71.1v-5.6l-2.3-0.8c-1.6-0.5-3.7-2.1-6.5-4.9c-2.8-2.7-5.4-6.3-7.9-10.7v-86c3.2-4.8,6.3-7.8,9.1-9.1
		h102l1.2,0.6c0.8,0.5,2,1.6,3.5,3.1c1.5,1.5,3,3.4,4.5,5.4v86c-2.5,4.4-5.2,8-8.2,10.7c-3,2.7-5,4.3-6,4.8c-1,0.4-1.9,0.7-2.5,0.9
		v5.6h71.1v-5.6l-2.3-0.8c-1.6-0.5-3.7-2.1-6.5-4.9c-2.8-2.7-5.4-6.3-7.9-10.7v-196c2.5-4.4,5.2-8,8.2-10.7c3-2.7,5-4.3,6-4.8
		c1-0.4,1.9-0.7,2.5-0.9v-5.6H997.2z"
      />
      <path
        d="M622.9,196.1l-45.6-93.9h90L622.9,196.1z M609.4,250c5.1-9.6,13.9-15.4,20.8-22.3l92.5-195.8c2.5-4.4,5.2-8,8.2-10.7
		c3-2.7,5-4.3,6-4.8c1-0.5,1.9-0.8,2.5-0.9V10h-57.8v5.6l2.3,0.8c1.6,0.5,3.7,2.1,6.5,4.9c2.8,2.7,5.4,6.3,7.9,10.7L676,82.4H568.3
		l-23.2-50.4c2.5-4.3,5.2-7.7,8.2-10.4c3-2.7,5-4.2,6-4.7c1-0.4,1.9-0.7,2.5-0.9v-6h-81v6l2.1,0.6c1.6,0.5,3.7,2.1,6.4,4.8
		c2.7,2.6,5.3,6.2,7.8,10.6l105.3,218H609.4z"
      />
    </g>
    <polygon
      points="30.8,569.1 9.9,590 9.9,603.5 30.8,624.4 44.1,624.4 64.9,603.5 64.9,590 44.1,569.1 "
    />
    <polygon
      points="1158.4,569.1 1137.5,590 1137.5,603.5 1158.4,624.4 1171.7,624.4 1192.5,603.5 1192.5,590 1171.7,569.1 "
    />
  </svg>
</template>

<script lang="ts" setup></script>