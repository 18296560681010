<template>
  <input
    class="input-hidden"
    type="hidden"
    :value="value"
    :name="name"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    value: String,
    name: String,
  },
}
</script>

<style lang="scss">
.input-hidden {
  display: none;
}
</style>
