import { provide, inject, Ref, ref } from 'vue'
import { LiquidLinkList, LiquidSettings, LiquidShop, LiquidTemplate } from '../types/liquid'

export type AppContextType = {
  shop: LiquidShop
  settings: LiquidSettings
  linklists: { [key: string]: LiquidLinkList }
  template: LiquidTemplate
  templateHandle: string
  assetUrl: string
  isLoggedIn: boolean
  footerHeight: Ref<number>
}

export const APP_INJECTION_KEY = Symbol('APP')

export const useAppContext = () => {
  const footerHeight = ref(0)

  const linklists = window._data.linklists.reduce(
    (c: AppContextType['linklists'], v: LiquidLinkList) => {
      c[v.handle] = v
      return c
    },
    {}
  )

  const values = {
    linklists,
    shop: window._data.shop,
    settings: window._data.settings,
    template: window._data.template,
    templateHandle: window._data.template_handle,
    assetUrl: window._data.asset_url,
    isLoggedIn: window._data.logged_in,

    footerHeight,
    setFooterHeight: (height: number) => (footerHeight.value = height),
  }

  provide<AppContextType>(APP_INJECTION_KEY, values)
  return values
}

export const useAppInject = () => {
  const context = inject<AppContextType>(APP_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
