<template>
  <div class="overlay-border-left"></div>
</template>

<style lang="scss">
.overlay-border-left {
  position: absolute;
  left: 0;
  width: 1px;
  top: 0;
  bottom: 0;
  background: var(--color-dark);
}
</style>
