import { ProductFieldsFragment } from '_library/src/storefront/generated'
import { ProductChainType } from './useProductChain'
import { Ref, computed } from 'vue'
import { ProductEngravingType } from './useProductEngraving'
import { ProductSelectionType } from './useProductSelection'

type ProductPriceProps = {
  product: ProductFieldsFragment
  selection: ProductSelectionType
  chain: ProductChainType
  insideEngraving: ProductEngravingType
  outsideEngraving: ProductEngravingType
}

export const useProductPrice = ({
  product,
  selection,
  chain,
  insideEngraving,
  outsideEngraving,
}: ProductPriceProps) => {
  const priceMax: Ref<number> = computed(() => {
    const chainProduct = chain.chainProduct

    const maxPrice = parseFloat(product?.priceRange?.maxVariantPrice.amount)

    if (maxPrice && chainProduct?.value) {
      const chainMaxPrice = parseFloat(chainProduct.value?.priceRange?.maxVariantPrice.amount)

      return maxPrice + chainMaxPrice
    }

    return maxPrice
  })

  const priceMin: Ref<number> = computed(() => {
    return parseFloat(product.priceRange?.minVariantPrice.amount || '9999')
  })

  const priceCompare: Ref<number | undefined> = computed(() => {
    const priceCompare = parseFloat(product?.compareAtPriceRange?.maxVariantPrice.amount || '0')
    if (priceCompare > 0 && priceCompare > priceMin.value) return priceCompare
    return undefined
  })

  const hasPriceRange = computed(() => {
    return priceMin.value < priceMax.value
  })

  // Construct total selection price
  const priceTotal = computed(() => {
    const variantPrice = Number(parseFloat(selection.selectedVariant.value?.price.amount) || 0)

    // console.log({ variantPrice })

    const chainPrice = Number(chain.isActive.value && chain.selection.value.selectedAmount) || 0

    // console.log({ chainPrice })
    const insideEngravingPrice =
      Number(insideEngraving.isActive.value && insideEngraving.selection.value.selectedAmount) || 0

    // console.log({ insideEngravingPrice })

    const outsideEngravingPrice =
      Number(outsideEngraving.isActive.value && outsideEngraving.selection.value.selectedAmount) || 0

    // console.log({ outsideEngravingPrice })

    return insideEngravingPrice + outsideEngravingPrice + chainPrice + variantPrice
  })

  return { priceMin, priceMax, priceCompare, hasPriceRange, priceTotal }
}
