<template>
  <OverlayQuestion
    v-if="selection.selectedVariant.value"
    :product-title="product.title"
    :variant-title="selection.selectedVariant.value.title"
  />
</template>

<script lang="ts" setup>
import { useProductInject } from '../../context/useProductContext'
import OverlayQuestion from '../overlay/OverlayQuestion.vue'

const { product, selection } = useProductInject()
</script>

<style lang="scss"></style>
