<template>
  <TabFeature :tabs="posts.map((v) => v[0])" v-if="posts.length" class="home-journal">
    <template #tabs>
      <TabFeatureTab v-for="[tab, content] in posts" :key="tab.title" :id="tab.id" :title="tab.title">
        <JournalFeatureTabContent :content="content" />
      </TabFeatureTab>
    </template>
  </TabFeature>
</template>

<script lang="ts" setup="">
import { onMounted, ref } from 'vue'
import TabFeature from '../tab-feature/TabFeature.vue'
import TabFeatureTab from '../tab-feature/TabFeatureTab.vue'
import JournalFeatureTabContent from './HomeJournalTabContent.vue'

const leftPad = (num: number) => String(num).padStart(2, '0')

const props = withDefaults(
  defineProps<{
    limit?: number
  }>(),
  {
    limit: 4,
  }
)

const posts = ref<any>([])

onMounted(async () => {
  const response = await fetch('https://jhannah-services.vercel.app/api/journal')
  const journal = await response.json()
  const newPosts: any = journal?.items.slice(0, props.limit).map((post) => {
    return [
      {
        title: formatDate(post.addedOn),
        id: post.id,
      },
      post,
    ]
  })

  posts.value = newPosts
})

function formatDate(ms: number) {
  if (isNaN(ms)) return '...'
  const date = new Date(ms)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear().toString().substring(2)
  return `${leftPad(month)}/${leftPad(day)}/${year}`
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-journal {
  width: auto;
  --heading-height: 50px;

  display: flex;
  flex-direction: column;
  margin: var(--index-section-spacing) calc(var(--inset) + columns(1, 0)) var(--index-section-spacing)
    auto;

  @media (min-width: 1000px) {
    width: columns(9, -1);
    /* padding: 0 columns(1); */
  }

  @media (max-width: 999px) {
    margin: 0;
  }

  .heading {
    height: var(--heading-height);
    right: var(--tab-padding);
    left: var(--tab-padding);
    top: var(--tab-padding);
    justify-content: space-between;
    position: absolute;
    display: flex;
    z-index: 20;
  }

  h2 {
    @include body-m-caps;
  }

  .tab-feature-tab {
    width: auto;
  }
}
</style>
