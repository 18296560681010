<template>
  <CommonContainer class="page-template-press" title="PRESS">
    <section class="page-template-press__content">
      <nav class="page-template-press__list">
        <div
          class="page-template-press__item"
          v-for="article in articles"
          :key="article.handle"
          @mouseover.prevent="setImage(article)"
        >
          <a class="page-template-press__link" :href="article.url" target="_blank"
            >{{ article.title }} <span>Open</span></a
          >
        </div>
      </nav>
      <div class="page-template-press__preview">
        <div class="page-template-press__images">
          <div
            :class="`page-template-press__image page-template-press__image--${
              image == article.image ? 'active' : 'hidden'
            }`"
            v-for="article in articles"
            :key="article.handle"
          >
            <ShopifyImage
              v-if="article.image"
              :src="article.image"
              :key="article.image"
              :alt="article.title"
            />
          </div>
        </div>
      </div>
    </section>
  </CommonContainer>
</template>

<script lang="ts" setup="">
import { ref } from 'vue'
import { LiquidArticle, LiquidPage } from '../../types/liquid'

import ShopifyImage from '../shopify/ShopifyImage.vue'
import CommonContainer from '../common/CommonContainer.vue'

const props = defineProps<{
  page: LiquidPage
  articles: LiquidArticle[]
}>()

const image = ref(props.articles[0].image)

const setImage = (article: LiquidArticle) => {
  image.value = article.image
}
</script>

<style lang="scss">
@import '~styles/base.scss';
@import '~styles/legacy.scss';

.page-template-press {
  .page-template-container__title {
    text-align: center;
    padding-bottom: 80px;
    h1 {
      @include text-xlarge;
      @include uppercase;
    }
  }

  &__content {
    display: grid;
    margin-left: columns(4);
    margin-right: columns(4);
    grid-template-columns: columns(8) 1fr columns(10);

    @include breakpoint(m) {
      margin-left: columns(2);
      margin-right: columns(2);
      grid-template-columns: columns(10) 1fr columns(10);
    }

    @include breakpoint(xxs) {
      margin-left: auto;
      margin-right: auto;
      grid-template-columns: 1fr;
    }
  }

  &__list {
    @include breakpoint(xxs) {
      text-align: center;
    }
  }

  &__item {
    @include serif;
    @include text-medium;
    @include helper-link-underline;
    padding-bottom: 10px;
    cursor: pointer;
    + * {
      padding-top: 10px;
    }
  }

  &__link {
    @include breakpoint(xxs) {
      text-align: center;
    }
    span {
      // text-decoration:underline;
      font-style: italic;
      transition: opacity 0.2s;
      opacity: 0;
    }
    &:focus,
    &:hover {
      span {
        opacity: 1;
      }
    }
  }

  &__preview {
    grid-column: -2 / -1;

    @include breakpoint(xxs) {
      display: none;
    }
  }

  &__images {
    position: sticky;
    top: 160px;
    display: grid;
  }

  &__image {
    grid-column: 1/-1;
    grid-row: 1/-1;
    opacity: 0;
    transition: opacity 0.2s ease;
    img {
      width: 100%;
      max-width: none;
    }

    &--active {
      opacity: 1;
    }
  }
}
</style>
