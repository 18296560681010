<template>
  <ShopifyImage
    class="shopify-media__image"
    v-if="imageSrc"
    :src="imageSrc"
    :ratio="ratio || '1x1'"
    :alt="alt"
    :class="$attrs.class"
  />
  <div v-if="isVideo" class="shopify-media__video">
    <CommonVideo class="shopify-media__player" :src="videoSrc" v-if="videoSrc" />
    <ShopifyImage
      class="shopify-media__thumbnail"
      v-if="previewImageSrc"
      :src="previewImageSrc"
      :ratio="ratio || '1x1'"
      :alt="alt"
      :class="$attrs.class"
    />
  </div>
</template>

<script lang="ts" setup>
import { MediaFieldsFragment, MediaImage, Video } from '_library/src/storefront/generated'
import CommonVideo from '../common/CommonVideo.vue'
import { Maybe } from 'graphql/jsutils/Maybe'
import ShopifyImage from './ShopifyImage.vue'

const props = defineProps<{
  media: MediaFieldsFragment & Maybe<MediaImage> & Maybe<Video>
  ratio?: string
}>()

const isImage = props.media?.mediaContentType == 'IMAGE'
const imageSrc = isImage && props.media?.image?.originalSrc
const alt = (isImage && props.media?.image?.altText) || ''

const isVideo = props.media?.mediaContentType == 'VIDEO'
const previewImageSrc = props.media?.previewImage?.originalSrc
const videoSrc = props.media?.sources?.[0].url
</script>

<style lang="scss">
.shopify-media {
  &__video {
    display: inline-flex;
    position: relative;
    overflow: hidden;
  }

  &__player {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__thumbnail {
    display: inline-flex;
  }
}
</style>
