<template>
  <div class="journal-feature-tab-content" :class="{ disable: !url }">
    <h2>J.H Journal</h2>
    <a :href="url" target="_blank" rel="noopener" class="image-container" aria-label="View Post">
      <div class="image">
        <CommonImage v-if="content.assetUrl" :src="content.assetUrl" alt="" />
      </div>
    </a>
    <div class="caption">
      <div>({{ formattedDate }})</div>
      <h3>
        <a :href="url" target="_blank" rel="noopener">{{ content.title }}</a>
      </h3>
    </div>
    <PillButton v-if="url" tag="a" :href="url" target="_blank" rel="noopener"> View Post </PillButton>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import CommonImage from '../common/CommonImage.vue'
import PillButton from '../PillButton.vue'

const props = defineProps({
  content: {
    type: Object,
    default: () => ({}),
  },
})

const formattedDate = computed(() => {
  if (isNaN(props.content.addedOn)) return '...'
  const date = new Date(props.content.addedOn)
  const month = date.getMonth() + 1
  const day = date.getDate()
  const year = date.getFullYear().toString().substring(2)
  return `${month}.${day}.${year}`
})

const url = computed(() => `http://jhannahjournal.com${props.content.fullUrl}`)
</script>

<style lang="scss" scoped>
@import '~styles/base.scss';

.journal-feature-tab-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;

  &.disable {
    pointer-events: none;
  }
}

.image-container {
  position: relative;
  width: calc(100% - 40px);
  padding: 60% 20px 0;
  margin: var(--heading-height) 0;
  filter: grayscale(1);
}

.image {
  @include absolute-cover;

  display: flex;

  img {
    object-fit: contain;
  }
}

h2,
.caption {
  @include body-m-caps;
}

.caption {
  max-width: 250px;
  min-height: 55px;
  margin: auto 0 15px;
}
</style>
