<template>
  <div class="product-slideshow">
    <button class="product-slideshow__button" aria-label="Open Overlay" @click="onOpenOverlay">
      <SvgPlus />
    </button>
    <CommonSlideshow
      class="product-slideshow__container"
      :slider-config="sliderConfig"
      :thumbnail-config="thumbnailConfig"
    >
      <template #default>
        <ShopifyMedia
          ratio="5x7"
          v-for="(media, mediaIndex) in medias"
          :media="media"
          :key="`media_${mediaIndex}`"
        />
      </template>
      <template #after>
        <ProductSlideshowControls class="product-slideshow__controls" />
        <div class="product-slideshow__thumbnails">
          <ProductSlideshowThumbnails />
        </div>
      </template>
    </CommonSlideshow>
  </div>
</template>

<script lang="ts" setup>
import { EmblaOptionsType } from 'embla-carousel-vue'
import { useProductInject } from '../../context/useProductContext'
import CommonSlideshow from '../common/CommonSlideshow.vue'
import ShopifyMedia from '../shopify/ShopifyMedia.vue'
import SvgPlus from '../svg/SvgPlus.vue'
import { useOverlayInject } from '../../context/useOverlayContext'
import ProductSlideshowThumbnails from './ProductSlideshowThumbnails.vue'
import ProductSlideshowControls from './ProductSlideshowControls.vue'

const { media: medias } = useProductInject()
const { setOverlay } = useOverlayInject()

const sliderConfig = { containScroll: 'keepSnaps', align: 'start' } as EmblaOptionsType

const thumbnailConfig = {
  dragFree: true,
  align: 'start',
  axis: 'y',
} as EmblaOptionsType

const onOpenOverlay = () => {
  setOverlay('PRODUCT_GALLERY')
}
</script>

<style lang="scss">
@import '~styles/base';

.product-slideshow {
  position: relative;
  margin-bottom: auto;

  &__button {
    position: absolute;
    right: calc(#{columns(1, -1)} + 20px);
    top: 20px;
    height: auto;
    background: transparent;
    z-index: 100;
    display: flex;
    opacity: 0;
    pointer-events: none;
    cursor: pointer;
    transition: opacity 0.3s ease;
    > * {
      width: 32px;
    }
  }

  &:focus &,
  &:hover & {
    &__button {
      @include breakpoint('l+') {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__container {
    display: grid;
    position: relative;
    margin-bottom: auto;
    grid-template-columns: columns(2) columns(7, 0);
    gap: 0 var(--gutter);

    @include breakpoint('l') {
      grid-template-columns: 1fr;
    }
  }

  &__thumbnails {
    position: relative;
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    height: 100%;

    @include breakpoint('l') {
      display: none;
    }
  }

  &__controls {
    position: absolute;
    left: columns(2, 0);
    right: columns(1);
    height: 100%;
    top: 0;

    @include breakpoint('l') {
      left: 0;
      right: 0;
    }
  }

  .common-slideshow {
    &__slider {
      grid-column: 2 / 3;

      @include breakpoint('l') {
        grid-column: 1 / -1;
      }
    }
  }

  .common-slideshow__after {
    display: contents;
  }
}
</style>
