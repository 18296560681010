<template>
  <FormWrap class="form-login" title="Account Login" :error="error" @submit="onSubmit">
    <div v-if="$slots" class="form-login__slot">
      <slot />
    </div>
    <label class="form-login__label" for="email">Email</label>
    <InputEmail
      class="form-login__input"
      name="email"
      placeholder="Your Email Address"
      required="true"
    />
    <label class="form-login__label" for="password">Password</label>
    <InputPassword
      class="form-login__input"
      name="password"
      placeholder="Your Password"
      required="true"
    />
    <InputButton type="submit" class="form-login__button" title="Submit" />
  </FormWrap>
</template>

<script lang="ts" setup>
import InputEmail from '../input/InputEmail.vue'
import InputPassword from '../input/InputPassword.vue'
import InputButton from '../input/InputButton.vue'
import FormWrap from './FormWrap.vue'
import { submitLogin } from '../../services/shopify/account'
import { ref } from 'vue'

const error = ref('')

const onSubmit = async (e: SubmitEvent) => {
  const formData = new FormData(e.target as HTMLFormElement)

  try {
    const response = await submitLogin({
      'customer[email]': formData.get('email') as string,
      'customer[password]': formData.get('password') as string,
    })
  } catch (e) {
    console.log(e)
    // if (e.code == 'ERR_NETWORK') window.location = '/account/login'
    error.value = 'Incorrect username or password, please try again'
  }
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.form-login {
  &__button {
  }

  &__slot {
    margin-bottom: 20px;
  }

  &__label {
    /* margin-bottom: 20px; */
  }

  &__input {
    margin-bottom: 20px;
  }
}
</style>
