<template>
  <h1>“{{ searchTerms }}”</h1>
</template>

<script lang="ts" setup>
import { computed } from 'vue';


const props = defineProps<{
  terms: string
}>();

const searchTerms = computed(() => props.terms.split('-tag:')[0].trim())
</script>
