<template>
  <div class="instock-group-heading">
    <ul class="instock-group-heading__items">
      <li class="instock-group-heading__item instock-group-heading__item--title">Product</li>
      <li
        v-for="option in group.groupOptions"
        class="instock-group-heading__item"
        :key="generateKey(option)"
      >
        {{ option }}
      </li>
      <li class="instock-group-heading__item instock-group-heading__item--price">Price</li>
      <div class="instock-group-heading__item instock-group-heading__item--view">&nbsp;</div>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { CollectionInStockGroupType } from './CollectionInStock.vue'

defineProps<{
  group: CollectionInStockGroupType
}>()

const generateKey = (obj: any) => {
  const str = JSON.stringify(obj)
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash).toString(36).substring(0, 6)
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.instock-group-heading {
  border-bottom: 1px solid var(--color-dark);
  border-top: 1px solid var(--color-dark);
  background: var(--color-background);
  margin-top: -1px;

  align-items: center;
  position: sticky;
  display: flex;
  z-index: 15;

  padding: 0 0 0 columns(3, 0);
  height: 35px;
  top: 0;

  @include breakpoint(l) {
    display: none;
  }

  @include breakpoint(s) {
    margin: 40px 20px 0;
    border-left: none;
    border-right: none;
  }

  &__items {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: var(--gutter);
  }

  &__item {
    @include uppercase;
    flex: 1;
    justify-content: space-around;
    display: flex;
    flex-direction: column;

    &--title,
    &--price,
    &--view {
      flex: none;
      width: columns(3);
    }
  }
}
</style>
