<template>
  <button class="overlay-close-button" aria-label="Close" @click="close"><i></i><i></i></button>
</template>

<script lang="ts" setup>
import { useOverlayInject } from '../../context/useOverlayContext'

const { close } = useOverlayInject()
</script>

<style lang="scss">
@import '~styles/base';

.overlay-close-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
  i {
    width: 100%;
    height: 1px;
    background: #000;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    &:last-child {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}
</style>
