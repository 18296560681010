<template>
  <a
    :href="link.url"
    class="shopify-link-default"
    :class="`shopify-link-default--${isActive ? 'active' : 'inactive'}`"
  >
    <span class="shopify-link__text">{{ link.title }}</span>
  </a>
</template>

<script lang="ts" setup>
import { LiquidLink } from '../../../types/liquid'

const props = withDefaults(
  defineProps<{
    link: LiquidLink
    depth: number
  }>(),
  {
    depth: 0,
  }
)

const isActive = props.link.url == window.location.pathname
</script>

<style lang="scss"></style>
