<template>
  <transition name="height" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <slot />
  </transition>
</template>

<script lang="ts" setup>
const enter = (element: HTMLElement) => {
  const width = getComputedStyle(element).width

  element.style.width = width
  // element.style.position = 'absolute'
  // element.style.visibility = 'hidden'
  element.style.height = 'auto'

  const { height } = getComputedStyle(element)

  element.style.width = ''
  element.style.position = ''
  element.style.visibility = ''
  element.style.height = '0px'

  // Force repaint to make sure the
  // animation is triggered correctly.
  // eslint-disable-next-line
  getComputedStyle(element).height

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = height
  })
}

const afterEnter = (element: HTMLElement) => {
  element.style.height = 'auto'
}

const leave = (element: HTMLElement) => {
  const height = getComputedStyle(element).height

  element.style.height = height

  // Force repaint to make sure the
  // animation is triggered correctly.
  // eslint-disable-next-line
  getComputedStyle(element).height

  requestAnimationFrame(() => {
    element.style.height = '0px'
  })
}
</script>

<style lang="scss">
.height-enter-active,
.height-leave-active {
  transition: height 0.2s ease;
  overflow: hidden;
}

.height-enter,
.height-leave-to {
  height: 0px;
}
</style>
