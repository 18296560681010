<template>
  <div class="overlay-limit" v-show="current == 'LIMIT'">
    <OverlayCloseButton class="overlay-limit__close" />
    <div class="overlay-limit__inner">
      <h3>Bulk Purchases</h3>
      <p>Online store purchases of our nailpolishes are limited to 30 per order.</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useOverlayInject } from '../../context/useOverlayContext'
import OverlayCloseButton from './OverlayCloseButton.vue'

const { current, close } = useOverlayInject()
</script>

<style lang="scss">
@import '~styles/base';

.overlay-limit {
  border: 1px solid var(--color-dark);
  background: var(--color-background);
  color: var(--color-dark);
  padding: 20px 40px 40px;
  position: fixed;
  transition: opacity 1s ease-out;
  @include text-xsmall;
  z-index: 950;
  /* transform: translateX(-50%) translateY(-50%); */
  /* max-width: 100%; */
  /* width: 340px; */
  /* height: auto; */
  /* top: 50%; */
  /* left: 50%; */
  /* opacity: 1; */

  @include breakpoint('l+') {
    top: 190px;
    right: columns(5);
    width: columns(6.5);
  }

  @include breakpoint(l) {
    top: 120px;
    right: 30px;
    left: 30px;
    width: auto;
  }

  &__inner {
    h3 {
      @include serif;
      @include text-xlarge;
      text-align: center;
      padding-bottom: 20px;
    }
  }

  &__close {
    top: 10px;
    right: 10px;
  }
}
</style>
