<template>
  <button aria-label="close" class="common-close"></button>
</template>

<script lang="ts" setup></script>

<style lang="scss">
@import '~styles/base.scss';

.common-close {
  z-index: 10;
  width: 10px;
  height: 10px;

  &:before,
  &:after {
    content: ' ';
    width: 100%;
    height: 1px;
    background: #000;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }

  &:after {
    transform: translateY(-50%) rotate(-45deg);
  }
}
</style>
