<template>
  <div class="product-card-empty"></div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
@import '~styles/base';

.product-card-empty {
  position: relative;
  aspect-ratio: 450 / 615;
  background: #eee;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}
</style>
