<template>
  <div class="product-stock" v-if="!isPolish">
    <div v-if="forSale && inStock" class="product-stock__note">
      * Ready to Ship (<button class="product-stock__button" @click="openInfo">Info</button>)
    </div>

    <div v-if="forSale && !inStock" class="product-stock__note">
      <span v-html="content.madeToOrderNote"></span> (<button class="in-stock-button" @click="openInfo">
        Info</button
      >)
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useOverlayInject } from '../../context/useOverlayContext'
import { useProductInject } from '../../context/useProductContext'

const { setOverlay } = useOverlayInject()
const { isPolish, selection, content } = useProductInject()

const forSale = computed(() => selection.selectedVariant.value.availableForSale)
const inStock = computed(() => (selection.selectedVariant.value.quantityAvailable || 0) > 0)

const openInfo = () => {
  setOverlay('INSTOCK')
}
</script>

<style lang="scss">
@import '~styles/base';

.product-stock {
  margin-top: 20px;

  @include breakpoint('l') {
    margin-top: 0;
  }

  &__note {
    padding: 0 var(--gutter) 10px 0;
    color: var(--color-midgrey);

    @include breakpoint('l') {
      padding: 10px 0 10px;
    }

    p {
      display: inline;
    }
    br {
      display: none;
    }
  }

  &__button {
    @include border-underline(var(--color-midgrey));
    color: currentColor;
    cursor: pointer;
  }
}
</style>
