<template>
  <header class="header-desktop">
    <HeaderBanner />
    <div class="header-desktop__inner">
      <ShopifyLinks
        class="header-desktop__main"
        aria-label="Main site navigation"
        :links="linklists['header-main'].links"
      />

      <a aria-label="Home" href="/" class="header-desktop__logo"><SvgLogo /></a>

      <ShopifyLinks
        class="header-desktop__accounts"
        aria-label="Account navigation"
        :links="linklists['header-account'].links"
      />
    </div>
  </header>
</template>

<script lang="ts" setup>
import { useAppInject } from '../../context/useAppContext'
import ShopifyLinks from '../shopify/link/ShopifyLinks.vue'
import SvgLogo from '../svg/SvgLogo.vue'
import HeaderBanner from './HeaderBanner.vue'

const { linklists } = useAppInject()
</script>

<style lang="scss">
@import '~styles/base.scss';

.header-desktop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: none;

  @include breakpoint('touch') {
    display: none;
  }

  &__inner {
    padding: var(--inset);
    display: grid;
    grid-template-columns: 1fr 150px 1fr;
  }

  &__logo {
    pointer-events: auto;
    margin-bottom: auto;
    svg {
      width: 100%;
    }
  }

  &__main,
  &__accounts {
    .shopify-link {
      pointer-events: auto;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    counter-reset: menu;
    gap: 2px;
    margin-right: auto;

    > * {
      counter-increment: menu;
    }

    .shopify-links {
      &--depth-2 {
        display: flex;
        flex-direction: column;
        // padding-bottom: 1em;
        margin-bottom: 1em;
      }

      &--depth-3 {
        display: none;
      }
    }

    .shopify-link {
      position: relative;
      margin-right: auto;

      &--depth-0 {
        + * {
          margin-top: 2px;
        }
        &:after {
          content: '0' counter(menu);
          position: absolute;
          right: calc(100% + 2px);
          top: -1px;
          opacity: 0;
          transform: translateY(5px);
          transition: opacity 0.2s ease-out, transform 0.1s ease-out;
        }

        &.shopify-link--active {
          &:after {
            opacity: 1;
            transform: translateY(0);
          }
        }
      }

      &--depth-1 {
        @include hover {
          text-decoration: underline;
        }
        + * {
          margin-top: 2px;
        }
      }

      &--depth-2 {
        color: var(--color-midgrey);

        @include hover {
          color: var(--color-dark);
          text-decoration: underline;
        }
      }

      &__text {
        @include uppercase;
      }

      @include hover {
        &:after {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }
  }

  &__accounts {
    @include uppercase;
    text-align: right;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: auto;
    margin-left: auto;
    gap: 2px;
  }
}
</style>
