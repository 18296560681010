<template>
  <div class="header-mobile" :class="{ 'header-mobile--invert': invertHeader }">
    <div class="header-mobile__wrap">
      <HeaderBanner />

      <div class="header-mobile__inner">
        <div class="header-mobile__content">
          <button aria-label="menu" class="header-mobile__hamburger" @click="setOverlay('MOBILE')">
            <i></i>
            <i></i>
            <i></i>
          </button>
          <nav
            class="header-mobile__logo"
            :class="{
              logo_hide: current === 'mmenu' || current === 'cart',
            }"
          >
            <div class="header-mobile__image">
              <a aria-label="Home" href="/"><SvgLogo /></a>
            </div>
          </nav>
          <nav class="header-mobile__cart">
            <button aria-label="cart" class="a" @click.prevent="setOverlay('CART')">
              ({{ itemCount }})
            </button>
          </nav>
        </div>
      </div>
    </div>
    <div class="header-mobile__spacer"></div>
  </div>
</template>

<script lang="ts" setup>
import SvgLogo from '../svg/SvgLogo.vue'
import { useOverlayInject } from '../../context/useOverlayContext'
import HeaderBanner from './HeaderBanner.vue'
import { useCartInject } from '../../context/useCartContext'
import { useHeaderInject } from '../../context/useHeaderContext'

const { setOverlay, current } = useOverlayInject()
const { itemCount } = useCartInject()
const { invertHeader } = useHeaderInject()
</script>

<style lang="scss">
@import '~styles/base.scss';

.header-mobile {
  pointer-events: none;

  &--invert {
    color: var(--color-background);
  }

  @include breakpoint('nontouch') {
    display: none;
  }

  &__content {
    position: relative;
  }

  &__hamburger,
  &__logo,
  &__cart {
    pointer-events: auto;
  }

  &__spacer {
    height: 0;
  }

  &__wrap {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;
    height: 50px;
    background: transparent;
    line-height: 30px;
  }

  &__inner {
    padding: 10px 20px;
    transition: transform 0.3s ease;
  }

  &__cart {
    /* @include text-large; */

    position: absolute;
    right: 0;
    top: 0;
    line-height: 30px;
    color: currentColor;
    transition: color 0.05s ease;

    span.a {
      color: currentColor;
      transition: color 0.05s ease;

      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__logo {
    text-align: center;
    transition: all 0.15s ease;

    &--hide {
      opacity: 0;
      pointer-events: none;
    }

    svg {
      width: 120px;
      margin-top: 5px;
      height: auto;
      fill: currentColor;
      transition: fill 0.05s ease;
    }
  }

  &__hamburger {
    position: absolute;
    width: 15px;
    height: 26px;
    left: 0;
    top: 4px;
    cursor: pointer;
    i {
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      width: 100%;
      height: 1px;
      background: currentColor;
      transition: background-color 0.05s ease;

      + i {
        top: 50%;
        transform: translateY(-50%);
        + i {
          top: auto;
          bottom: 5px;
        }
      }
    }
  }
  @include breakpoint(m) {
    &__image {
      svg {
        width: 100px;
        margin-top: 10px;
      }
    }
  }
}
</style>
