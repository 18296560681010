<template>
  <div class="collection-instock">
    <div class="collection-instock__groups" v-if="optionGroups.length">
      <CollectionInStockGroup
        v-for="(group, index) in optionGroups"
        class="collection-instock__group"
        :key="`option-group_${index}`"
        :group="group"
      />
    </div>
    <div class="collection-instock__loading" v-else>Loading In Stock Products...</div>
  </div>
</template>

<script lang="ts" setup>
import _, { Dictionary } from 'lodash'
import { computed, onMounted, ref, unref } from 'vue'
import CollectionInStockGroup from './CollectionInStockGroup.vue'

import {
  DEFAULT_COUNTRY_CODE,
  fetchCollectionWithProducts,
  ProductFieldsFragment,
  VariantFieldsFragment,
} from '_library'

export type CollectionInStockGroupType = {
  groupOptions: string[]
  products: (ProductFieldsFragment & {
    imageSrc: string
    groupedOptions: {
      options: string[][]
      prices: number[]
    }[]
  })[]
}

const mapEdges = (object: any) => {
  return object.edges.map((edge: any) => edge.node)
}

const products = ref<ProductFieldsFragment[]>([])

onMounted(async () => {
  const collection = await fetchCollectionWithProducts({
    handle: 'in-stock',
    countryCode: DEFAULT_COUNTRY_CODE,
  })

  products.value = collection.products
})

const productsWithInStockVariants = computed(() => {
  return unref(products.value)
    .map((product: ProductFieldsFragment) => {
      const variants = mapEdges(product.variants)

      return {
        ...product,
        variants: variants.filter((variant: VariantFieldsFragment) => {
          return variant.availableForSale && (variant?.quantityAvailable || 0) > 0
        }),
      }
    })
    .filter((product) => product.variants.length > 0)
})

const variantGroupedProducts = computed(() => {
  const _products = productsWithInStockVariants.value

  return _products.map((product) => {
    const variants = unref(product.variants) as VariantFieldsFragment[]
    const media = unref(mapEdges(product.media))

    const variantOptions: Dictionary<VariantFieldsFragment[]> = _.groupBy(
      variants,
      (variant) => variant?.selectedOptions[0].name
    )
    const variantOptionKeys = Object.keys(variantOptions)

    const groupedOptions = variantOptionKeys.reduce((c: any, key) => {
      const group = variantOptions[key]

      // Get prices
      const prices = group
        .map((variant) => variant.price)
        .filter((price, i, self) => self.indexOf(price) === i)
        .map((price) => parseFloat(price.amount) * 100)

      // Format Options
      const options = group
        .map((variant) => variant.selectedOptions)
        .map((options) => options.map((option) => [option.value]))
        .reduce((acc, curr) => acc.map((o, i) => o.concat(curr[i]), []))
        .map((a) => a.filter((a, i, self) => self.indexOf(a) === i))

      return [
        ...c,
        {
          options: options,
          prices: prices,
        },
      ]
    }, [])

    return {
      ...product,
      imageSrc: media?.[0]?.previewImage?.thumbUrl,
      groupedOptions,
    }
  })
})

const optionGroups = computed(() => {
  const productOptionsToString = (product: ProductFieldsFragment) => {
    const options = product.options.map((option) => option?.name)
    return options.join(', ')
  }

  const groups = _.groupBy(variantGroupedProducts.value, productOptionsToString)

  return Object.keys(groups).map((key) => {
    return {
      groupOptions: key.split(','),
      products: groups[key],
    } as CollectionInStockGroupType
  })
})
</script>

<style lang="scss">
@import '~styles/base.scss';

.collection-instock {
  &__groups {
    border: 1px solid var(--color-dark);
    border-top: none;

    @include breakpoint('l') {
      border: none;
    }
  }

  &__loading {
    @include display-m;
    text-align: center;
    padding: 100px;
  }
}
</style>
