import { ProductFieldsFragment } from '_library/src/storefront/generated'

const getDescriptions = (product: ProductFieldsFragment) => {
  const descriptionHeadings = product?.descriptionHeading
    ? JSON.parse(`${product?.descriptionHeading.value}`)
    : []
  const descriptionBodies = product?.descriptionBody
    ? JSON.parse(`${product?.descriptionBody.value}`)
    : []
  return descriptionHeadings.map((heading: string, index: number) => ({
    heading,
    body: descriptionBodies[index],
  }))
}

type ProductContentProps = {
  product: ProductFieldsFragment
  isPolish: boolean
  isPolishSet: boolean
  isNoSale: boolean
}

export type ProductContentType = {
  description: string[]
  descriptions: { heading: string; body: string }[]
  polishIngredients: string
  polishFreeFrom: string
  madeToOrderNote: string
  customShippingInfo: string | null
  defaultShippingInfo: string | null
  polishShippingInfo: string | null
}

export const useProductContent = ({
  isPolishSet,
  isNoSale,
  isPolish,
  product,
}: ProductContentProps) => {
  const shopMeta = window._data.shop.metafields
  const description = product.descriptionHtml.split('<!--split-->')
  const descriptions = getDescriptions(product)

  const madeToOrderNote = shopMeta.made_to_order_note
  const polishFreeFrom = isPolish && shopMeta.polish.free_from
  const polishIngredients = isPolish && shopMeta.polish.ingredients
  const customShippingInfo = product.contentShippingNote?.value || null
  const defaultShippingInfo =
    (!customShippingInfo && !isPolish && shopMeta.shipping.shipping_info) || null
  const polishShippingInfo =
    (!customShippingInfo && isPolish && shopMeta.shipping.shipping_info_polish) || null

  const content: ProductContentType = {
    description,
    descriptions,
    polishFreeFrom,
    polishIngredients,
    customShippingInfo,
    defaultShippingInfo,
    polishShippingInfo,
    madeToOrderNote,
  }

  return content
}
