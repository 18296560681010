<template>
  <div class="account-order">
    <div class="account-order__number">
      <dt>Order #</dt>
      <dd>
        <a :href="order.order_status_url">#{{ order.order_number }}</a>
      </dd>
    </div>
    <div class="account-order__date">
      <dt>Date</dt>
      <dd>{{ date }}</dd>
    </div>
    <div class="account-order__total">
      <dt>Total</dt>
      <dd><ShopifyPrice :amount="order.total_price / 100" /></dd>
    </div>
    <div class="account-order__status">
      <dt>Status</dt>
      <dd>{{ order.fulfillment_status.toUpperCase() }}</dd>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import moment from 'moment'
import { LiquidOrder } from '../../types/liquid'
import ShopifyPrice from '../shopify/ShopifyPrice.vue'

const props = defineProps<{ order: LiquidOrder }>()

const date = computed(() => {
  return moment(props.order.created_at, 'DD-MM-YYYY').format('DD/MM/YYYY')
})
</script>

<style lang="scss">
@import '~styles/base.scss';

.account-order {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid var(--color-dark);
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: -1px;
  gap: 5px;

  dt {
    color: var(--color-midgrey);
  }

  a {
    text-decoration: underline;
  }
}
</style>
