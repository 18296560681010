<template>
  <div
    role="complementary"
    class="overlay-mobile"
    :class="`${isActive ? 'overlay-mobile--active' : ''}`"
  >
    <div class="overlay-mobile__container">
      <div class="overlay-mobile__menu">
        <SearchForm class="overlay-mobile__search" mode="MOBILE" />
        <Accordion class="overlay-mobile__links">
          <NavigationItem
            class="overlay-mobile__link"
            v-for="(link, i) in linklists['mobile-navigation'].links"
            :key="`${i}${link.url}`"
            :link="link"
          />
        </Accordion>
      </div>
      <div class="overlay-mobile__footer">
        <button class="overlay-mobile__account" @click.prevent="() => setOverlay('ACCOUNT')">
          Account
        </button>
      </div>
    </div>
    <OverlayBorderRight />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useOverlayInject } from '../../context/useOverlayContext'
import { useAppInject } from '../../context/useAppContext'

import NavigationItem from '../NavigationItem.vue'
import Accordion from '../accordion/Accordion.vue'
import SearchForm from '../form/SearchForm.vue'
import OverlayBorderRight from './OverlayBorderRight.vue'

const { current, setOverlay } = useOverlayInject()
const { linklists } = useAppInject()
const isActive = computed(() => current.value == 'MOBILE')

// onMounted(() => {
// document.body.style.overflow = 'hidden'
// })

// onUnmounted(() => {
// document.body.style.overflow = 'initial'
// })
</script>

<style lang="scss">
@import '~styles/base';

.overlay-mobile {
  --digit-inset: calc(var(--nav-inset) - 20px);
  --nav-inset: 50px;
  --nav-pad: 10px;
  --width: 280px;

  background: var(--color-lightbackground);
  color: var(--color-dark);
  overflow-y: auto;
  position: fixed;
  padding: 0;
  z-index: 1000;
  width: var(--drawer-width);
  height: var(--100vh);
  min-height: auto;
  max-width: 100vw;
  left: 0;
  top: 0;

  &:not(.overlay-mobile--active) {
    pointer-events: none;
    opacity: 0;
  }

  &__container {
    flex-direction: column;
    display: flex;
    height: 100%;
    padding: 0;
  }

  &__menu {
    color: var(--color-dark);
    counter-reset: accordion;
    max-width: 100vw;
    width: 100%;
    height: 100%;

    @include breakpoint('xl') {
      padding-top: 100px;
    }
  }

  &__footer {
    padding: var(--nav-pad);
    padding-left: var(--nav-inset);
    border-top: 1px solid;
  }

  &__account {
    // text-decoration: underline;
    // color: var(--color-midgrey);
    text-transform: uppercase;
  }

  &__links {
    padding-bottom: calc(var(--nav-pad) * 2);
    line-height: 1.6;
  }

  &__link {
    counter-increment: accordion;
    border-bottom: 1px solid;
    padding: var(--nav-pad);
    padding-left: var(--nav-inset);

    &:before {
      content: counter(accordion);
      position: absolute;
      left: var(--digit-inset);
      color: #010101;
    }

    .AccordionItem > a,
    .AccordionItem > button {
      text-decoration: none;
      position: relative;
    }

    .content {
      a,
      button {
        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
