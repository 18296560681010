<template>
  <input
    class="input-email"
    type="email"
    :value="value"
    :required="required"
    :placeholder="placeholder"
    :name="name"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
    required: String,
    name: String,
    placeholder: String,
  },
}
</script>

<style lang="scss">
.input-email {
  padding: 10px 0;
  border-bottom: 1px solid var(--color-dark);
}
</style>
