<template>
  <div class="product-chain-heading">
    <h3 class="product-chain-heading__title">{{ title }}:</h3>
    <div class="product-chain-heading__toggle">
      <button
        class="product-chain-heading__button"
        :class="{
          'product-chain-heading__button--active': !isActive,
        }"
        @click.prevent="emits('setIsActive', false)"
      >
        None
      </button>
      <span class="product-chain-heading__divider">/</span>
      <button
        type="button"
        class="product-chain-heading__button"
        :class="{
          'product-chain-heading__button--active': isActive,
        }"
        @click.prevent="emits('setIsActive', true)"
      >
        Select
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  title: string
  isActive: boolean
}>()

const emits = defineEmits(['setIsActive'])
</script>

<style lang="scss" scoped>
@import '~styles/base';

.product-chain-heading {
  --input-spacing-y: 10px;
  align-items: baseline;
  line-height: 1;

  &__title {
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-right: 5px;
  }

  &__toggle {
    display: flex;
    display: flex;
    flex-wrap: wrap;
  }

  @include breakpoint('xl') {
    flex-direction: column;
  }

  &__button {
    position: relative;
    cursor: pointer;
    color: var(--color-muted);
    line-height: 1;
    @include underline;
  }

  &__divider {
    color: var(--color-muted);
    margin: 0 0.5ch;
  }

  &__hidden {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  @include breakpoint('l') {
    &__title {
      margin-bottom: var(--input-spacing-y);
    }

    &__divider {
      display: none;
    }

    &__toggle {
      display: grid;
      grid-auto-flow: column;
      justify-content: flex-start;
      grid-gap: var(--product-button-gap);
    }

    &__button {
      @include button-outline;

      color: var(--color-muted);
      border-color: var(--color-light);
      text-transform: none;

      &--active {
        color: var(--color-dark);
        border-color: var(--color-dark);
      }
    }
  }
}
</style>
