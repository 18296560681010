<template>
  <FormWrap class="form-forgot" title="Reset Password" :error="error" @submit="onSubmit">
    <div class="form-forgot__label">We will send you an email to reset your password.</div>

    <InputEmail
      class="form-forgot__input form-forgot__input--email"
      name="email"
      placeholder="Your Email Address"
      required="true"
    />
    <InputButton type="submit" class="form-forgot__button" title="Submit" />
  </FormWrap>
</template>

<script lang="ts" setup>
import FormWrap from './FormWrap.vue'
import InputEmail from '../input/InputEmail.vue'
import InputButton from '../input/InputButton.vue'
import { submitForgot } from '../../services/shopify/account'
import { ref } from 'vue'

const error = ref('')

const onSubmit = async (e: SubmitEvent) => {
  const formData = new FormData(e.target as HTMLFormElement)
  error.value = ''
  try {
    const response = await submitForgot({
      email: formData.get('email') as string,
    })

    alert('Please check your email to finish resetting your password')
    // window.location.href = '/account/login'
  } catch (e: any) {
    if (e.code == 'ERR_NETWORK') window.location.href = '/account/login'
    error.value = 'There was an error, please check your details and try again'
  }
}
</script>

<style lang="scss">
@import '~styles/base.scss';
.form-forgot {
  &__button {
    /* margin-top: 20px; */
  }

  &__label {
    /* margin-bottom: 20px; */
  }

  &__input {
    margin-bottom: 20px;
  }
}
</style>
