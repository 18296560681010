<template>
  <div class="togglable" :class="{ active }">
    <button class="toggle-button" @click="toggleActive">
      <slot name="button"></slot>
    </button>

    <div class="toggle-wrapper" :style="{ height: contentHeight + 'px' }">
      <transition name="togglable" @enter="setContentHeight" @leave="setContentHeight">
        <div class="toggle-content" v-show="active">
          <slot></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    name: string,
    activeSibling: string,
  }>(), 
  {
    name: '',
    activeSibling: '',
  }
)

const active = ref(false)
const contentHeight = ref(0)

const toggleActive = () => {
  active.value = !active.value
}
const setContentHeight = (el: any) => {
  contentHeight.value = active.value && el?.offsetHeight ? el.offsetHeight : 0
}

watch(active, (isActive) => {
  emit('toggle', {
    name: props.name,
    active: isActive,
  })
})

watch(() => props.activeSibling, (sibling) => {
  active.value = sibling === props.name
})

const emit = defineEmits(['toggle'])
</script>

<style lang="scss">
.togglable {
  .toggle-wrapper {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.5s ease-out;
  }
  .toggle-button {
    width: 100%;
  }
  .toggle-content {
    position: absolute;
    top: 0;
    left: 0;
    &.togglable-enter-active,
    &.togglable-leave-active {
      transition: opacity 0.6s ease;
    }
    &.togglable-enter,
    &.togglable-leave-to {
      opacity: 0;
    }
  }
}
</style>