import { Ref, computed, inject, provide, toValue, unref } from 'vue'
import {
  MediaFieldsFragment,
  MediaImage,
  ProductFieldsFragment,
  VariantFieldsFragment,
} from '_library/src/storefront/generated'
import { AddLineItemProps, findProductTag } from '_library'
import { ProductChainType, useProductChain } from '../composables/useProductChain'
import { useProductPrice } from '../composables/useProductPrice'
import { ProductRelatedType, useProductRelated } from '../composables/useProductRelated'
import { ProductInfluencesType, useProductInfluences } from '../composables/useProductInfluences'
import { useProductRedirection } from '../composables/useProductRedirection'
import { ProductSelectionType, useProductSelection } from '../composables/useProductSelection'
import { ProductContentType, useProductContent } from '../composables/useProductContent'
import { ProductEngravingType, useProductEngraving } from '../composables/useProductEngraving'
import { useCartInject } from './useCartContext'
import { gidToNumber } from '../utils/shopify'

export type ProductContextProps = {
  product: ProductFieldsFragment
}

export type ProductContextType = {
  product: ProductFieldsFragment

  media: (MediaFieldsFragment & MediaImage)[]
  variants: VariantFieldsFragment[]
  content: ProductContentType

  priceMin: Ref<number>
  priceMax: Ref<number>
  priceCompare: Ref<number | undefined>
  hasPriceRange: Ref<boolean>
  priceTotal: Ref<number>

  selection: ProductSelectionType

  isNoSale: boolean
  isPolish: boolean
  isPolishSet: boolean
  isPreorderable: boolean
  isNotifiable: Ref<boolean>

  chain: ProductChainType

  insideEngraving: ProductEngravingType
  outsideEngraving: ProductEngravingType

  related: ProductRelatedType
  influences: ProductInfluencesType

  addToCart: () => Promise<void>
}

export const PRODUCT_INJECTION_KEY = Symbol('PRODUCT')

// TODO: Types util
const NOT_JEWELRY = [
  'Admin',
  'Apparel',
  'Insurance',
  'Nailpolish',
  'Polish Set',
  'Polish Set Bundle',
  'Ring Sizing Tool',
]

export const useProductContext = ({ product }: ProductContextProps) => {
  const { addLineItems } = useCartInject()

  const media = product.media?.edges.map((edge) => edge.node) || []
  const variants = product.variants?.edges.map((edge) => edge.node) || []

  // Variant
  const selection = useProductSelection(product)

  // Booleans
  const isNoSale = !!findProductTag(product, 'no-sale')
  const isPolish = product.productType == 'Nailpolish'
  const isPolishSet = !!findProductTag(product, 'polish-set')
  const isPreorderable = !!findProductTag(product, 'preorder')
  const isNotifiable = computed(
    () => !!findProductTag(product, 'notify') && !selection.selectedVariant.value.availableForSale
  )

  // Content
  const content = useProductContent({ product, isNoSale, isPolish, isPolishSet })

  // Engraving
  const insideEngraving = useProductEngraving(product, 'INSIDE')
  const outsideEngraving = useProductEngraving(product, 'OUTSIDE')

  // Chain
  const chain = useProductChain(product)

  // Pricing
  const { priceMin, priceMax, priceCompare, hasPriceRange, priceTotal } = useProductPrice({
    product,
    selection,
    chain,
    insideEngraving,
    outsideEngraving,
  })

  // Related Products
  const related = useProductRelated(product)
  const influences = useProductInfluences(product)

  // Redirection
  useProductRedirection(product)

  const cartItem = computed(() => {
    const selectedVariant = toValue(selection.selectedVariant)
    const variantId = gidToNumber(selectedVariant.id)

    return {
      variantId,
      quantity: 1,
    }
  })

  const addToCart = async () => {
    const item = unref(cartItem)
    const chainItem = unref(chain.cartItem)
    const insideEngravingItem = unref(insideEngraving.cartItem)
    const outsideEngravingItem = unref(outsideEngraving.cartItem)

    const items = [outsideEngravingItem, insideEngravingItem, chainItem, item].filter(
      Boolean
    ) as AddLineItemProps[]

    await addLineItems(items)
  }

  const provideVars: ProductContextType = {
    product,
    variants,

    // Content
    media,
    content,

    // Prices
    priceMin,
    priceMax,
    priceCompare,
    hasPriceRange,
    priceTotal,

    // Selection
    selection,

    // Sellable
    isNoSale,
    isPreorderable,
    isNotifiable,

    // Polish
    isPolish,
    isPolishSet,

    // Product engraving
    outsideEngraving,
    insideEngraving,

    // Product Chain
    chain,

    // Related products
    related,
    influences,

    // Add to cart
    addToCart,
  }

  provide(PRODUCT_INJECTION_KEY, provideVars)

  return provideVars
}

export const useProductInject = () => {
  const productContext = inject<ProductContextType>(PRODUCT_INJECTION_KEY)
  if (!productContext) throw new Error()
  return productContext
}
