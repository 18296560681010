<template>
  <section v-if="active" ref="grid" class="overlay-grid">
    <div class="overlay-grid__columns">
      <div v-for="(v, k) in getColumns()" :key="`grid_column_${k}`" class="overlay-grid__column"></div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      columns: 0,
      inset: 0,
      gutter: 0,
    }
  },
  mounted() {
    // Persistence
    this.active = localStorage.getItem('showGrid') == 'true'

    // Listen
    window.document.addEventListener('keydown', (e) => this.onKeyDown(e))

    // Get CSS vars
    this.$nextTick(() => {
      const computedStyle = window.getComputedStyle(this.$parent.$el)
      this.columns = parseInt(computedStyle.getPropertyValue('--columns') - 1 || 0)
      this.inset = parseInt(computedStyle.getPropertyValue('--inset') || 0)
      this.gutter = parseInt(computedStyle.getPropertyValue('--gutter') / 2 || 0)
    })
  },
  methods: {
    toggleActive() {
      this.active = !this.active
      localStorage.setItem('showGrid', this.active)
    },
    onKeyDown(event) {
      if (
        event.altKey &&
        event.ctrlKey &&
        event.shiftKey &&
        event.keyCode === 71 // 'G'
      )
        this.toggleActive()
    },
    getColumns() {
      const count = this.columns + 1
      return Array(count).fill(count)
    },
  },
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-grid {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 1;
  z-index: 100000;

  &__columns {
    @include wrapper;
    white-space: nowrap;
    display: grid;
    height: 100vh;
    gap: var(--gutter);
    grid-template-columns: repeat(var(--columns), 1fr);
  }

  &__column {
    background: rgba(255, 0, 0, 0.1);
  }

  &:after {
    content: 'BP / ' var(--breakpoint);
    position: fixed;
    left: 10px;
    bottom: 10px;
    padding: 5px 10px 3px;
    background: #111;
    color: #fff;
    text-transform: uppercase;
    border-radius: 10px;
    pointer-events: none;
  }
}
</style>
