<template>
  <div class="product-options" v-if="product.variants.edges.length > 1">
    <ProductOption
      :product="product"
      :selection="selection"
      v-for="option in options"
      :option="option"
      :prefix="prefix"
      :key="option.name"
    />
  </div>
</template>

<script lang="ts" setup>
import { MaybeRef, computed, unref } from 'vue'
import ProductOption from './ProductOption.vue'
import { ProductFieldsFragment } from '_library/src/storefront/generated'
import { ProductSelectionType } from '../../composables/useProductSelection'

const props = defineProps<{
  product: ProductFieldsFragment
  selection: MaybeRef<ProductSelectionType>
  prefix?: string
}>()

const selection = computed(() => unref(props.selection))
const options = computed(() => selection.value.options)
</script>

<style lang="scss">
@import '~styles/base.scss';

.product-options {
  > * + * {
    margin-top: 20px;
  }
}
</style>
