<template>
  <div class="collection-container">
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss">
@import '~styles/base.scss';
@import '~styles/legacy.scss';

.collection-container {
  min-height: 100vh;
  width: 100%;
  /* padding-top: 160px; */
  /* position: relative; */
  /* padding-bottom: 200px; */
}
</style>
