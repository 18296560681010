<template>
  <div class="instock-product">
    <a :href="'/products/' + product.handle" class="instock-product__cover"></a>

    <div class="instock-product__image">
      <img :src="product.imageSrc" />
    </div>

    <div class="instock-product__title">
      {{ product.title }}
    </div>

    <div class="instock-product__groups">
      <CollectionInStockGroupVariant
        v-for="variantOptions in product.groupedOptions"
        :key="generateKey(variantOptions)"
        :variant-options="variantOptions"
        class="instock-product__group"
      />
    </div>

    <div class="instock-product__link">
      <span class="instock-product__cta">Shop Product</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import CollectionInStockGroupVariant from './CollectionInStockGroupVariant.vue'
import { CollectionInStockGroupType } from './CollectionInStock.vue'
import { ElementOf } from '../../types/utils'

defineProps<{
  product: ElementOf<CollectionInStockGroupType['products']>
}>()

const generateKey = (obj: any) => {
  const str = JSON.stringify(obj)
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash).toString(36).substring(0, 6)
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.instock-product {
  position: relative;
  display: flex;
  padding: 15px 0 20px;
  border-bottom: 1px solid var(--color-dark);

  &:last-child {
    border-bottom: none;
  }

  &:focus &,
  &:hover & {
    &__link {
      opacity: 1;
    }
  }

  @include breakpoint(m) {
    flex-wrap: wrap;
    &:last-child {
      border-bottom: 1px solid var(--color-dark);
    }
  }

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  &__image {
    width: columns(3);
    padding: 0 0 0 var(--gutter);
    img {
      max-width: 100px;
    }
    @include breakpoint(m) {
      width: columns(2);
      order: 1;
      margin-left: auto;
      img {
        width: 100%;
        max-width: none;
      }
    }
  }

  &__title {
    @include uppercase;
    width: columns(3);
    padding-right: var(--gutter);
  }

  &__groups {
    flex: 1;

    @include breakpoint(m) {
      flex: none;
      order: 2;
      width: 100%;
      margin-top: 20px;
      padding: 0 10px;
    }
  }

  &__link {
    width: columns(3, 0);
    opacity: 0;
    transition: opacity 0.2s ease;
    /* padding-right: 20px; */

    @include breakpoint(m) {
      order: 3;
      width: 100%;
      opacity: 1;
      margin-top: 20px;
      padding: 0 10px;
    }
  }

  &__cta {
    border-bottom: 1px solid var(--color-dark);
  }
}
</style>
