import { fetcher } from "./client";

export const fetchCart = async () => {
  return fetcher("/cart.json");
};

export type UpdateCartProps = {
  note?: string;
  properties?: { [key: string]: string };
  attributes?: { [key: string]: string };
};

export const updateCart = async (props: UpdateCartProps) => {
  return fetcher("/cart/update.json", {
    method: "POST",
    body: {
      ...(props.properties && { properties: props.properties }),
      ...(props.attributes && { attributes: props.attributes }),
      ...(props.note && { note: props.note }),
    },
  });
};

export type AddLineItemProps = {
  variantId: number;
  quantity: number;
  properties?: { [key: string]: string };
};

export const addLineItem = async (props: AddLineItemProps) => {
  return fetcher("/cart/add.json", {
    method: "POST",
    body: {
      id: `${props.variantId}`,
      quantity: props.quantity || 1,
      properties: props.properties,
    },
  });
};

export const addLineItems = async (props: AddLineItemProps[]) => {
  return fetcher("/cart/add.json", {
    method: "POST",
    body: {
      items: props.map((item) => ({
        id: `${item.variantId}`,
        quantity: item.quantity || 1,
        properties: item.properties,
      })),
    },
  });
};

export type UpdateLineItemProps = {
  variantId: number;
  quantity: number;
  properties?: { [key: string]: string };
};

export const updateLineItem = async (props: UpdateLineItemProps) => {
  return fetcher("/cart/change.json", {
    method: "POST",
    body: {
      id: `${props.variantId}`,
      quantity: props.quantity || 1,
      properties: props.properties,
    },
  });
};

export type RemoveLineItemProps = {
  variantId: number;
};

export const removeLineItem = async (props: RemoveLineItemProps) => {
  return fetcher("/cart/change.json", {
    method: "POST",
    body: {
      id: `${props.variantId}`,
      quantity: 0,
    },
  });
};

export type RemoveLineItemsProps = {
  id: string;
}[];

export const removeLineItems = async (items: RemoveLineItemsProps) => {
  const updates = items.reduce((acc, item) => {
    acc[item.id] = 0;
    return acc;
  }, {} as { [key: string]: number });

  console.log("updates", updates);

  const response = await fetcher("/cart/update.json", {
    method: "POST",
    body: { updates },
  });

  console.log("response", response);

  return response;
};
