import { ref, Ref, onMounted, onUnmounted, watchEffect } from 'vue'

export function useGalleryIndex(slidesContainer: Ref<HTMLElement | null>) {
  const currentSlide = ref<number>(0)

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const slideIndex = entry.target.getAttribute('data-slide-index')
          if (slideIndex !== null) {
            currentSlide.value = parseInt(slideIndex, 10) + 1 // +1 to adjust for 0-based index
          }
        }
      })
    },
    {
      root: null,
      threshold: 0.5,
    }
  )

  onMounted(() => {
    watchEffect(() => {
      if (!slidesContainer.value) return
      const slides = slidesContainer.value.querySelectorAll('.overlay-gallery__slide')
      slides.forEach((slide, index) => {
        slide.setAttribute('data-slide-index', index.toString())
        observer.observe(slide)
      })
    })
  })

  onUnmounted(() => {
    observer.disconnect()
  })

  return {
    currentSlide,
  }
}
