import { isObjectLiteral } from './objects'

export function mapGraphObject(obj) {
  const mappedObj = {}
  for (const key in obj) {
    mappedObj[key] = mapGraph(obj[key])
  }
  return mappedObj
}

export function mapGraph(value) {
  if (!value) {
    return value
  }
  if (Array.isArray(value)) {
    return value.map(mapGraph)
  }
  if (isObjectLiteral(value)) {
    if (Object.prototype.hasOwnProperty.call(value, 'edges')) {
      return value.edges.map(mapGraph)
    }
    if (Object.prototype.hasOwnProperty.call(value, 'node')) {
      return mapGraphObject(value.node)
    }
    return mapGraphObject(value)
  }
  return value
}
