import { getCookie, setCookie } from '_library'

export type CurrencyCode = string
export type CurrencySymbol = string

export type CurrencyRates = {
  [key: string]: number
}

export type MoneyStateType = {
  rate: number
  frontendCode: CurrencyCode
  frontendSymbol: CurrencySymbol
}

export const SHOPIFY_DEFAULT_CURRENCIES = ['USD', 'GBP', 'EUR', 'CAD']
export const SHOPIFY_DEFAULT_CURRENCY = 'USD'
export const SHOPIFY_DEFAULT_SYMBOL = '$'
export const SHOPIFY_COOKIE_CURRENCY = 'CURRENCY_COOKIE'

export const getCurrencySymbol = (code: CurrencyCode) => {
  if (code == 'EUR') return '€'
  if (code == 'GBP') return '₤'
  if (code == 'JPY') return '¥'
  if (code == 'KRW') return '₩'
  return '$'
}

export const fetchCurrency = () => {
  return getCookie(SHOPIFY_COOKIE_CURRENCY) || SHOPIFY_DEFAULT_CURRENCY
}

export const setCurrency = (currency: string) => {
  return setCookie(SHOPIFY_COOKIE_CURRENCY, currency)
}
