<template>
  <div
    ref="rowEl"
    class="subregion-row"
    :class="{ active: rowStockistsHeight > 0 }"
    :style="{ paddingBottom: rowStockistsHeight + 'px' }"
  >
    <div
      v-for="subregion in chunk"
      :key="subregion.name"
      class="subregion"
      :style="{ width: 'calc(100% /' + chunkSize + ')' }"
    >
      <div
        class="subregion-heading"
        :class="{
          active: isActiveRegion && rowSubregion === subregion.name,
          inactive: isActiveRegion && rowSubregion !== subregion.name,
        }"
        @click.prevent="toggleSubregion(subregion.name)"
      >
        <h3 class="subregion-name">
          {{ subregion.name }}
        </h3>
        <span class="subregion-count"> ({{ subregion.sublocations.length }}) </span>
      </div>
      <div
        ref="stockistsEl"
        class="stockists"
        :class="{
          active: rowSubregion === subregion.name,
          [`stockists--${slugify(subregion.name)}`]: true,
        }"
      >
        <div
          v-for="(stockistChunk, stockistChunkIndex) in chunkStockists(subregion.sublocations)"
          class="stockists-row"
          :key="`${subregion.name}_${stockistChunkIndex}`"
        >
          <Stockist
            v-for="stockist in stockistChunk"
            :key="stockist.title"
            :stockist="stockist"
            :chunk-size="chunkSize"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import _ from 'lodash'
import Stockist from './Stockist.vue'
import { ref, watchEffect, defineProps, defineEmits } from 'vue'
import slugify from '@sindresorhus/slugify'

const props = defineProps<{
  isActiveRegion: boolean
  activeRegion: string
  activeSubregion: string
  chunk: Array<any>
  chunkSize: number
}>()

const emit = defineEmits(['toggle-subregion'])

const rowEl = ref<null | HTMLElement>(null)
const rowSubregion = ref('')
const rowStockistsHeight = ref(0)

function resetRow() {
  rowSubregion.value = ''
  rowStockistsHeight.value = 0
}

// watchEffect(() => {
// if (!props.isActiveRegion) resetRow()
// if (rowSubregion.value !== props.activeSubregion) resetRow()
// })

function toggleSubregion(subregionName: string) {
  const stockistElement = rowEl.value?.querySelector(`.stockists--${slugify(subregionName)}`)
  if (rowSubregion.value === subregionName) {
    resetRow()
    emit('toggle-subregion', '')
    return
  }
  rowSubregion.value = subregionName
  rowStockistsHeight.value = stockistElement?.offsetHeight || 0
  emit('toggle-subregion', subregionName)
}

function chunkStockists(stockists: any) {
  return _.chunk(stockists, props.chunkSize)
}
</script>
