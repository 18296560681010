<template>
  <form class="polish-set-selection" @submit.prevent="onSubmit">
    <div class="polish-set-selection__heading">
      <legend>Colors&nbsp;</legend>
      <span v-show="remaining > 0">(Select {{ remaining }})</span>
    </div>
    <div class="polish-set-selection__polishes">
      <PolishSetProduct v-for="product in products" :product="product" :key="product.id" />
    </div>

    <div class="polish-set-selection__controls">
      <PolishSetPurchase :products="products" class="polish-set-selection__selected" />
      <div class="polish-set-selection__purchase">
        <span class="polish-set-selection__error" v-show="error">{{ error }}</span>
        <button class="polish-set-selection__button">Add to Cart</button>
      </div>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { useCartInject } from '../../context/useCartContext'
import { useOverlayInject } from '../../context/useOverlayContext'
import { usePolishSetInject } from '../../context/usePolishSetContext'
import { LiquidProduct } from '../../types/liquid'
import PolishSetProduct from './PolishSetProduct.vue'
import PolishSetPurchase from './PolishSetPurchase.vue'

defineProps<{
  products: LiquidProduct[]
}>()

const { fetchCart } = useCartInject()
const { setOverlay } = useOverlayInject()
const { error, remaining, onPurchase } = usePolishSetInject()

const onSubmit = async () => {
  await onPurchase()
  if (error.value) return
  await fetchCart()
  setOverlay('CART')
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.polish-set-selection {
  display: grid;
  grid-template: repeat(3, auto) / repeat(2, auto);

  @include breakpoint('l') {
    --p-column-max: 100px;

    display: flex;
    flex-direction: column;
    width: 100%;
  }

  legend {
    flex: 0;
    text-transform: uppercase;
  }

  &__polishes {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 40px;
    gap: 20px;

    @include breakpoint('l') {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__heading {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    font-size: 14px;
  }

  &__controls {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: 1fr 1fr;

    @include breakpoint('l') {
      grid-template-columns: 1fr;
    }
  }

  &__purchase {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: 25px;
    justify-content: flex-end;

    @include breakpoint('l') {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__error {
    padding-bottom: 15px;
    color: var(--color-error);
  }

  &__button {
    padding: 10px 50px;
    border: 1px solid var(--color-dark);
    text-transform: uppercase;
    width: auto;

    @include breakpoint('l') {
      width: 100%;
      margin-top: 100px;
    }
  }
}
</style>
