import { DEFAULT_COUNTRY_CODE, fetchCollectionWithProducts, findProductTagStartsWith } from '_library'
import { CollectionFieldsFragment, ProductFieldsFragment } from '_library/src/storefront/generated'
import { Ref, onMounted, ref } from 'vue'

export type ProductInfluencesType = {
  handle: string
  section: any
  collection: Ref<CollectionFieldsFragment>
}

export const useProductInfluences = (product: ProductFieldsFragment) => {
  const handle = `${findProductTagStartsWith(product, 'influences-')}`.replace('influences-', '')
  const collection: Ref<any | null> = ref(null)
  const section: Ref<any | null> = ref(null)

  onMounted(async () => {
    if (!handle) return

    collection.value = await fetchCollectionWithProducts({
      handle,
      countryCode: DEFAULT_COUNTRY_CODE as any,
    })

    section.value = window._data?.product_influences?.find(
      (section: any) => section.influences_handle === handle
    )
  })

  return {
    handle,
    section,
    collection: collection,
  } as ProductInfluencesType
}
