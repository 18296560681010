export async function klaviyoBackInStockSubscribe(input: { [key: string]: any }) {
  const options = {
    subscribe_for_newsletter: false,
    g: null,
    platform: 'shopify',
    ...input,
  }
  const body = Object.entries(options).reduce((params, [key, value]) => {
    params.append(key, String(value))
    return params
  }, new URLSearchParams())

  try {
    const response = await fetch('https://a.klaviyo.com/onsite/components/back-in-stock/subscribe?', {
      method: 'POST',
      mode: 'cors',
      headers: {
        // 'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
      body,
    })
    const { success, errors, message } = await response.json()
    if (!success && !!message) throw new Error(message)
    if (!success) throw new Error(errors.join(', '))

    return {
      success,
      message,
    }
  } catch (e) {
    console.log(e)
    throw e
  }
}
