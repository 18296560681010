<template>
  <svg width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.80556 4.58889C3.80556 4.32222 3.80556 4.23333 3.18333 4.23333V3.78889C3.62778 3.78889 4.07222 3.61111 4.42778 3.34444L4.69444 3.52222V6.72222C4.69444 7.07778 4.69444 7.07778 5.22778 7.07778V7.61111H3.18333V7.07778C3.80556 7.07778 3.80556 7.07778 3.80556 6.72222V4.58889ZM4.87222 2.36667C4.87222 2.81111 4.60556 3.07778 4.16111 3.07778C3.71667 3.07778 3.53889 2.81111 3.53889 2.36667C3.53889 1.92222 3.89444 1.65556 4.25 1.65556C4.51667 1.65556 4.87222 1.83333 4.87222 2.36667ZM8.25 1.38889C8.25 0.855556 7.80556 0.5 7.36111 0.5H1.13889C0.694444 0.5 0.25 0.944444 0.25 1.38889V7.61111C0.25 8.14444 0.694444 8.5 1.13889 8.5H7.36111C7.89444 8.5 8.25 8.05556 8.25 7.61111V1.38889Z"
    />
  </svg>
</template>

<script lang="ts" setup></script>
<style scoped>
path {
  fill: currentColor;
}
</style>
