<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 17.5 17.5"
    style="enable-background: new 0 0 17.5 17.5"
    xml:space="preserve"
  >
    <g>
      <rect x="8.1" y="0.6" width="1.2" height="16.4" />
      <rect x="0.6" y="8.1" width="16.4" height="1.2" />
    </g>
  </svg>
</template>

<script lang="ts" setup></script>