<template>
  <div class="overlay-cart-items">
    <CartItem :line="line" v-for="line in items" :key="line.id" />
  </div>
</template>

<script lang="ts" setup>
import { computed, unref } from 'vue'
import { useCartInject } from '../../../context/useCartContext'
import CartItem from '../../cart/CartItem.vue'

const { cart } = useCartInject()

const items = computed(() => {
  return [
    ...(unref(cart)?.items || []).filter((item) => !item.properties.Product),
    ...(unref(cart)?.items || []).filter((item) => item.properties.Product),
  ]
})
</script>

<style lang="scss">
.overlay-cart-items {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
}
</style>
