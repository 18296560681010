import { formRequest, formatParams, getRequest } from './client'

export async function getOrders() {
  const { orders } = await getRequest(`/account`)
  return orders
}

export async function getAddresses() {
  return getRequest(`/account/addresses`)
}

export async function submitLogin(input: any) {
  const url = `/account/login`
  const params = await formatParams(url, { ...input }, 'customer_login')
  return formRequest(url, params)
}

export async function submitForgot(input: any) {
  const url = `/account/recover`
  const params = await formatParams(url, { ...input }, 'recover_customer_password')

  return formRequest(url, params)
}

export async function submitRegister(input: any) {
  const url = `/account`
  const params = await formatParams(url, { ...input }, 'create_customer')

  return formRequest(url, params)
}

export async function submitActivate(input: any) {
  const url = `/account/activate`
  const params = await formatParams(url, { ...input }, 'activate_customer_password')

  return formRequest(url, params)
}

export async function submitResetPassword(input: any) {
  const url = `/account/reset`
  const params = await formatParams(url, { ...input }, 'reset_customer_password')

  return formRequest(url, params)
}

export async function submitAddressDelete(input: any) {
  const url = `/account/addresses/${input.id}`
  const params = await formatParams(url, { ...input }, 'customer_address')
  params._method = 'delete'
  return formRequest(url, params)
}

export async function submitAddressCreate(input: any) {
  return submitAddress(input)
}

export async function submitAddressUpdate(input: any) {
  return submitAddress(input)
}

export async function submitAddress(address: any) {
  const url = address.id ? `/account/addresses/${address.id}` : '/account/addresses'

  const _method = address.id && 'put'
  const params = await formatParams(url, { ...address }, 'customer_address', _method)

  return await formRequest(url, params)
}

export async function submitContact(input: any) {
  const url = `/contact`
  const params = await formatParams(url, { ...input }, 'contact')
  return formRequest(url, params)
}
