<template>
  <div class="layout-default" :class="classNames" :style="`--scroll-top:${scrollTop}px;`">
    <div class="app">
      <div class="app__inner">
        <HeaderDesktop />
        <HeaderMobile />
        <LayoutContainer class="layout-default__container">
          <slot />
        </LayoutContainer>

        <FooterDisplay />

        <OverlayClose />
      </div>

      <OverlayMobile />
      <OverlayCart />

      <OverlayDialogue
        :hide-days="7"
        name="popup"
        v-if="settings.popup_text && settings.popup_show == 'true'"
      >
        <div v-html="settings.popup_text"></div>
      </OverlayDialogue>

      <OverlayLimit />
      <OverlayInstock />
      <OverlayAccount />
      <OverlayGrid />
    </div>

    <OverlayNewsletter />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'

import { useAppContext } from '../../context/useAppContext'
import { useOverlayContext } from '../../context/useOverlayContext'
import { useHeaderContext } from '../../context/useHeaderContext'
import { useCartContext } from '../../context/useCartContext'
import { useCurrencyContext } from '../../context/useCurrencyContext'
import { LiquidCart } from '../../types/liquid'

import HeaderDesktop from '../header/HeaderDesktop.vue'
import FooterDisplay from '../footer/FooterDisplay.vue'
import HeaderMobile from '../header/HeaderMobile.vue'

import OverlayCart from '../overlay/OverlayCart.vue'
import OverlayClose from '../overlay/OverlayClose.vue'
import OverlayDialogue from '../overlay/OverlayDialogue.vue'
import OverlayNewsletter from '../overlay/OverlayNewsletter.vue'
import OverlayInstock from '../overlay/OverlayInstock.vue'
import OverlayAccount from '../overlay/OverlayAccount.vue'
import OverlayGrid from '../overlay/OverlayGrid.vue'
import OverlayLimit from '../overlay/OverlayLimit.vue'
import OverlayMobile from '../overlay/OverlayMobile.vue'
import LayoutContainer from './LayoutContainer.vue'

const props = defineProps<{
  cart: LiquidCart
  assetUrl: string
}>()

const { template, settings } = useAppContext()
const { current } = useOverlayContext()
const scrollTop = ref(0)
const focusActive = ref(false)
useCartContext(props.cart)
useCurrencyContext()
useHeaderContext()

onMounted(() => {
  window.addEventListener('scroll', () => {
    scrollTop.value = window.scrollY
  })

  window.addEventListener('keyup', (event) => {
    if (event.key === 'Tab') {
      focusActive.value = true
    }
  })
})

const classNames = computed(() => {
  let classes = `layout-default--template-${template.name?.toUpperCase() || 'DEFAULT'}`
  if (current.value) classes = `${classes} layout-default--overlay-${current.value}`
  if (focusActive.value) classes = `${classes} layout-default--focus-active`
  return classes
})

watch(current, (value) => {
  if (value == 'PRODUCT_GALLERY') {
    document.body.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
  }
})
</script>

<style lang="scss">
@import '~styles/base.scss';

:root {
  --1vh: 1vh;
  --100vh: 100vh;
  --drawer-width: 350px;
  --overlay-dialogue-offset: 25px;

  @include breakpoint('l') {
    --index-section-spacing: 55px;
  }
}

.layout-default {
  &--template-PRODUCT {
    .layout-default__container {
      @include breakpoint('l') {
        padding-top: 0;
      }
    }
  }

  /* &--overlay-ACTIVE {
    height: 100vh;
    overflow: hidden;

    .app__inner {
      margin-top: calc(-1 * var(--scroll-top));
    }
  } */

  &--overlay-MENU {
    .app__inner {
      transform: translateX(var(--drawer-width));
    }
  }

  &--overlay-CART,
  &--overlay-ACCOUNT {
    .app__inner {
      transform: translateX(calc(-1 * var(--drawer-width)));
    }
  }

  &--focus-active {
    :focus {
      outline: 1px solid #000;
    }
  }
}
</style>
