<template>
  <div class="tab-feature">
    <div class="tab-feature__buttons">
      <TabFeatureButton
        v-for="tab in tabs"
        :key="tab.id"
        :active="tab.id === activeTab"
        @onClick="($event) => selectTab(tab.id)"
      >
        <slot name="button">{{ tab.title }}</slot>
      </TabFeatureButton>
      <slot name="buttons"> </slot>
    </div>
    <div class="tab-feature__inner">
      <slot name="tabs" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, provide, ref } from 'vue'
import TabFeatureButton from './TabFeatureButton.vue'

const props = defineProps<{
  title?: TabFeatureType['title']
  tabs: TabFeatureType['tabs']
}>()

const selectedTab = ref('')

const activeTab = computed(() => selectedTab.value || props.tabs?.[0]?.id)

const selectTab = (key: string) => {
  selectedTab.value = key
}

provide('activeTab', activeTab)
provide('selectTab', selectTab)
</script>

<style lang="scss">
@import '~styles/base.scss';

.tab-feature {
  --tab-padding: 30px;

  position: relative;

  &__inner {
    display: grid;
  }

  &__buttons {
    position: relative;
    z-index: 20;
    display: flex;
    grid-auto-flow: column;
    justify-content: flex-start;
    column-gap: 4px;
    margin-bottom: -1px;
  }
}
</style>
