<template>
  <div class="item-remove">
    <button type="button" class="item-remove__button" @click="remove">Remove</button>
  </div>
</template>

<script lang="ts" setup>
import { unref } from 'vue';
import { useCartInject } from '../../context/useCartContext';
import { LiquidLineItem } from '../../types/liquid';

const { cart, removeLineItems } = useCartInject()
const props = defineProps<{
  line: LiquidLineItem
}>()

const remove = async () => {
  // Remove all related items
  const items = unref(cart).items.filter(
    (item) =>
      props.line.product_title === item.properties.Product ||
      props.line.product_title === item.product_title
  )
  const updates = items.map((item) => ({ id: item.key }))
  await removeLineItems(updates)
}
</script>

<style lang="scss">
@import '~styles/base';

.item-remove {
  padding-top: 15px;
  cursor: pointer;

  > * {
    /* @include animate-underline; */
    text-decoration: underline;
    @include hover {
      text-decoration: none;
    }
  }
}
</style>
