import { DEFAULT_COUNTRY_CODE, fetchCollectionWithProducts, findProductTagStartsWith } from '_library'
import { CollectionFieldsFragment, ProductFieldsFragment } from '_library/src/storefront/generated'
import { Ref, onMounted, ref } from 'vue'

export type ProductRelatedType = {
  collection: Ref<CollectionFieldsFragment>
}

export const useProductRelated = (product: ProductFieldsFragment) => {
  const relatedCollectionHandle = `${findProductTagStartsWith(product, 'related-')}`.replace(
    'related-',
    ''
  )
  const relatedCollection: Ref<any | null> = ref(null)

  onMounted(async () => {
    if (!relatedCollectionHandle) return
    relatedCollection.value = await fetchCollectionWithProducts({
      handle: relatedCollectionHandle,
      countryCode: DEFAULT_COUNTRY_CODE as any,
    })
  })

  return {
    collection: relatedCollection,
  } as ProductRelatedType
}
