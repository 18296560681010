<template>
  <ShopifyLinkDefault
    :class="constructLinkClass('default', depth)"
    v-if="linkType == 'default'"
    :link="link"
    :depth="depth"
  />

  <ShopifyLinkShop
    :class="constructLinkClass('shop', depth)"
    v-else-if="linkType == 'shop'"
    :depth="depth"
  />

  <ShopifyLinkSubmenu
    title="Info"
    v-else-if="linkType == 'info'"
    :class="constructLinkClass('submenu', depth)"
    :links="linklists['submenu-info'].links"
    :depth="depth"
  />

  <ShopifyLinkSubmenu
    title="lookbook"
    v-else-if="linkType == 'lookbook'"
    :class="constructLinkClass('submenu', depth)"
    :links="linklists['submenu-lookbook'].links"
    :depth="depth"
  />

  <ShopifyLinkSearch
    :class="constructLinkClass('search', depth)"
    v-else-if="linkType == 'search'"
    :depth="depth"
  />

  <ShopifyLinkCurrencies
    :class="constructLinkClass('currencies', depth)"
    v-else-if="linkType == 'currency'"
    :depth="depth"
  />

  <ShopifyLinkLogin
    :class="constructLinkClass('login', depth)"
    v-else-if="linkType == 'login'"
    :depth="depth"
  />

  <ShopifyLinkCart
    :class="constructLinkClass('cart', depth)"
    v-else-if="linkType == 'cart'"
    :depth="depth"
  />

  <nav v-if="link.links?.length" :class="constructLinksClass('links', depth + 1)">
    <ShopifyLink
      :link="_link"
      v-for="(_link, index) in link.links"
      :key="_link.url"
      :index="index"
      :depth="depth + 1"
    />
  </nav>
</template>

<script lang="ts" setup>
import { LiquidLink } from '../../../types/liquid'
import ShopifyLinkDefault from './ShopifyLinkDefault.vue'
import ShopifyLinkCurrencies from './ShopifyLinkCurrencies.vue'
import ShopifyLinkShop from './ShopifyLinkShop.vue'
import ShopifyLinkSearch from './ShopifyLinkSearch.vue'
import ShopifyLinkLogin from './ShopifyLinkLogin.vue'
import ShopifyLinkCart from './ShopifyLinkCart.vue'
import { computed } from 'vue'
import ShopifyLinkSubmenu from './ShopifyLinkSubmenu.vue'
import { useAppInject } from '../../../context/useAppContext'
import ShopifyLinks from './ShopifyLinks.vue'

const props = withDefaults(
  defineProps<{
    link: LiquidLink
    depth: number
    index?: number
  }>(),
  {
    depth: 0,
  }
)

const { linklists } = useAppInject()

const constructLinksClass = (name: string, depth: number) => {
  return [
    'shopify-links',
    `shopify-links--${name}`,
    `shopify-links--depth-${depth}`,
    `shopify-links--index-${props.index || 0}`,
  ]
}

const constructLinkClass = (name: string, depth: number) => {
  return [
    'shopify-link',
    `shopify-link--${name}`,
    `shopify-link--depth-${depth}`,
    `shopify-link--index-${props.index || 0}`,
  ]
}

const linkType = computed(() => {
  const url = props.link.url
  if (!url.startsWith('#')) return 'default'
  if (url == '#shop') return 'shop'
  if (url == '#info') return 'info'
  if (url == '#lookbook') return 'lookbook'
  if (url == '#search') return 'search'
  if (url == '#currency') return 'currency'
  if (url == '#login') return 'login'
  if (url == '#cart') return 'cart'

  // console.error(`Link type unhandled: ${url}`);
  return ''
})
</script>

<style lang="scss"></style>
