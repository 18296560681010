<template>
  <SanityBlocks
    :blocks="blocks"
    :dataset="dataset"
    :project-id="projectId"
    :render-container-on-single-child="true"
    class-name="SanityBlocks"
  />
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { SANITY_DATASET, SANITY_PROJECT_ID } from '_library/src/utils/sanity'

const props = withDefaults(
  defineProps<{
    blocks: Array<any>
  }>(),
  {
    blocks: () => [],
  }
)

const serializers = ref<Record<string, any>>({})

const dataset = computed(() => SANITY_DATASET)
const projectId = computed(() => SANITY_PROJECT_ID)
</script>