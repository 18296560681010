<template>
  <div class="polish-set-purchase">
    <div class="polish-set-purchase__heading">
      <h2 class="polish-set-purchase__title" v-show="products.length > 0">
        <span>Your Set ({{ count }})</span>
        <button v-if="count > 0" class="polish-set-purchase__clear" @click.prevent="clearSelection">
          Clear
        </button>
      </h2>
    </div>
    <div class="polish-set-purchase__products">
      <PolishSetItem
        v-for="(item, index) in items"
        :item="item"
        :products="products"
        :key="`${index}_${item.productId}`"
      />
      <div class="polish-set-purchase__pad" v-for="padItem in padItems" :key="padItem.key">
        <div class="polish-set-purchase__box"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { usePolishSetInject } from '../../context/usePolishSetContext'
import { LiquidProduct } from '../../types/liquid'
import PolishSetItem from './PolishSetItem.vue'

defineProps<{
  products: LiquidProduct[]
}>()

const { max, count, items, clearSelection } = usePolishSetInject()

const padItems = computed(() => {
  const remaining = max - items.value.length

  return Array.from({ length: remaining }, (_, index) => ({
    key: `pad_${index}`,
  }))
})
</script>

<style lang="scss">
.polish-set-purchase {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__clear {
    margin-left: 20px;
    color: var(--color-midgrey);
    text-decoration: underline;
  }

  &__products {
    display: flex;
    gap: 20px;

    > * {
      width: 60px;
    }
  }

  &__pad {
    display: flex;
    padding-bottom: 28px;
  }
  &__box {
    width: 60px;
    height: 60px;
    position: relative;

    &:before {
      position: absolute;
      content: ' ';
      left: -3px;
      top: -3px;
      right: -3px;
      bottom: -3px;
      border: 1px solid var(--color-dark);
    }
  }
}
</style>
