import { SelectedOption, VariantFieldsFragment } from "./generated";

export function constructShopifyImageUrl({
  src,
  width,
  height,
}: // quality,
{
  src: string;
  width: number;
  height?: number;
  // quality?: string;
}) {
  const url = `${src}?`;
  const dotIndex = url.lastIndexOf(".");
  const format = `${width}x${height && `${Math.ceil(height)}_crop_center`}`;

  return [url.slice(0, dotIndex), `_${format}`, url.slice(dotIndex)].join("");
}

export const shopifyCommonImage =
  ({ ratio }: any) =>
  ({ src, width, quality }: any) => {
    const finalWidth = Math.min(width, 1600);
    const height = Math.ceil(finalWidth * ratio);

    return constructShopifyImageUrl({
      src,
      width: finalWidth,
      height,
      // quality,
    });
  };

export const mergeProductOptions = (
  selectedOptions: SelectedOption[],
  name: string,
  value: string
) => {
  return [
    ...selectedOptions.filter((option) => name != option.name),
    {
      name,
      value,
    },
  ];
};

export function findSelectedVariant(
  variants: VariantFieldsFragment[],
  selectedOptions: SelectedOption[]
) {
  const variant = variants.find((variant) =>
    variant.selectedOptions.every((variantOption) =>
      selectedOptions.some((option) => {
        return (
          option.name === variantOption.name &&
          option.value === variantOption.value
        );
      })
    )
  );
  return variant || null;
}

export function isOptionSelected(
  options: SelectedOption[],
  name: string,
  value: string
) {
  const option = options.find(
    (option) => option.name == name && option.value == value
  );

  return option;
}
