<template>
  <video ref="video" :autoplay="autoplay" loop muted playsinline class="MovingImage">
    <source :src="url" :type="mimeType" />
  </video>
</template>

<script>
import { defineComponent } from 'vue'
import Hls from 'hls.js'
import intersection from '../../mixins/intersection'

export default defineComponent({
  mixins: [intersection],

  props: {
    autoplay: {
      type: Boolean,
      default: true,
    },
    mimeType: {
      type: String,
      default: 'video/mp4',
    },
    url: {
      type: String,
      default: '',
    },
  },

  computed: {
    video() {
      return this.$refs.video
    },
  },

  watch: {
    intersected(intersected) {
      intersected ? this.video.play() : this.video.pause()
    },
  },

  mounted() {
    if (/.m3u8/.test(this.url)) {
      this.mountStream()
    }
  },

  methods: {
    mountStream() {
      const video = this.$el

      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = this.url
      } else if (Hls.isSupported()) {
        const hls = new Hls()

        if (!this.autoplay) {
          video.autoplay = false
        }

        hls.loadSource(this.url)
        hls.attachMedia(video)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.MovingImage {
  width: 100%;
  height: 100%;
  z-index: 10;
}
</style>
