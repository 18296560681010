<template>
  <img
    ref="imageElement"
    :alt="alt"
    :src="src || placeholderSrc"
    :width="width"
    :height="height"
    class="image-loader"
    :class="{ loaded: loaded }"
    :style="{ opacity: loaded ? 1 : 0 }"
  />
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'
import useIntersection from '../../composables/useIntersection'

const props = withDefaults(
  defineProps<{
    alt: string
    src: string
    deferVisibility?: boolean
    width?: number | string
    height?: number | string
  }>(),
  {
    deferVisibility: true,
    width: '100%',
  }
)

// const intersectionRootMargin = '50% 0px';
// const intersectionThreshold = [0, 1];

const imageElement = ref(null)
const loaded = ref(false)
const imgSrc = ref('')
const visible = ref(false)

const { isIntersecting } = useIntersection(imageElement)
const placeholderSrc = computed(() =>
  props.width && props.height
    ? `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${props.width} ${props.height}'%3E%3C/svg%3E`
    : ''
)

watch(
  () => visible.value,
  () => {
    if (!visible.value) return

    const img = new Image()
    img.onload = () => {
      loaded.value = true
    }
    img.src = props.src
  }
)

watch(
  () => isIntersecting.value,
  async (_isIntersecting) => {
    if (!props.deferVisibility || _isIntersecting) {
      visible.value = true
    }
  }
)
</script>

<style scoped>
.image-loader {
  transition: opacity 800ms ease-out;
}
</style>
