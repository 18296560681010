<template>
  <input
    type="password"
    :value="value"
    class="input-password"
    :placeholder="placeholder"
    :required="required"
    :maxlength="limit"
    :name="name"
    @keyup="$emit('keyup', $event.target.value)"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    title: String,
    placeholder: String,
    value: String,
    name: String,
    required: {
      default: false,
    },
    limit: {
      default: '',
    },
  },
}
</script>

<style lang="scss">
.input-password {
  padding: 10px 0;
  border-bottom: 1px solid var(--color-dark);
}
</style>
