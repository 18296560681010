<template>
  <div class="product-notify" v-if="isNotifiable">
    <button class="product-notify__button" v-if="!isFormActive" @click.prevent="onActivate">
      {{ message }}
    </button>
    <form v-if="isFormActive" @submit.prevent="onSubmit">
      <input v-model="email" type="email" placeholder="Email" required />
      <button type="submit" class="product-notify__button">Notify me</button>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useProductInject } from '../../context/useProductContext'
import { klaviyoBackInStockSubscribe } from '../../services/klaviyo'

const DEFAULT_MESSAGE = 'Notify me when available'
const SUBMITTED_MESSAGE = 'Thank you - we will notify you when the product is available'
const message = ref(DEFAULT_MESSAGE)

const { product, selection, isNotifiable } = useProductInject()
const isFormActive = ref(false)
const email = ref('')

const onActivate = () => {
  isFormActive.value = true
  setTimeout(() => {
    const input: any = document.querySelector('.product-notify form input')
    input?.focus()
  }, 0)
}

const onSubmit = async () => {
  await klaviyoBackInStockSubscribe({
    a: 'JhZw6c',
    email: email.value,
    product: product.id,
    variant: selection.selectedVariant.value?.id?.replace('gid://shopify/ProductVariant/', ''),
  })

  message.value = SUBMITTED_MESSAGE
  isFormActive.value = false
  email.value = ''
}
</script>

<style lang="scss">
@import '~styles/base';

.product-notify {
  padding-top: 10px;

  &__button {
    color: var(--color-midgrey);
    text-decoration: none;
    @include hover {
      text-decoration: underline;
    }
  }
}
</style>
