import { Ref, computed, ref } from 'vue'
import { ProductFieldsFragment, VariantFieldsFragment } from '_library/src/storefront/generated'

export type ProductSelectionType = {
  hasOptions: boolean
  options: ProductFieldsFragment['options']
  selectedVariant: Ref<VariantFieldsFragment>
  selectedOptions: Ref<VariantFieldsFragment['selectedOptions']>
  selectedAmount: Ref<number>
  selectVariant: (variant: VariantFieldsFragment) => void
}

export const useProductSelection = (product: ProductFieldsFragment) => {
  const variants = product?.variants?.edges.map((edge) => edge.node)
  const firstVariant = variants.find((variant) => variant.availableForSale) || variants[0]
  const hasOptions = variants.length > 1
  const options = product.options

  const selectedVariant: Ref<VariantFieldsFragment | undefined> = ref(firstVariant)
  const selectedOptions: Ref<VariantFieldsFragment['selectedOptions'] | undefined> = ref(
    selectedVariant.value?.selectedOptions
  )

  const selectVariant: ProductSelectionType['selectVariant'] = (variant) => {
    selectedVariant.value = variant
    selectedOptions.value = variant.selectedOptions
  }

  const selectedAmount: Ref<number> = computed(() => {
    const amount = selectedVariant.value?.price.amount || 0
    return Number(amount)
  })

  return {
    hasOptions,
    selectedVariant,
    selectedOptions,
    options,
    selectVariant,
    selectedAmount,
  } as ProductSelectionType
}
