<template>
  <button class="input-button" :class="{ disabled }" @click="$emit('input', value)">
    {{ title }}
  </button>
</template>

<script>
export default {
  props: ['title', 'value', 'disabled'],
}
</script>

<style lang="scss">
/* @import '~styles/base.scss';
@import '~styles/frontend/helper-forms.scss';

.input-button {
  @include appearance-none;
  @include sans;
  @include text-medium;
  @include uppercase;
  width: auto;
  padding: 10px 25px;
  color: var(--color-dark);
  // background:var(--color-light);
  display: inline-block;
  width: auto;
  border: 1px solid var(--color-dark);
  text-align: center;
  transition: all 0.2s ease-out;

  &:hover,
  &:active {
    text-decoration: none;
    background: transparentize($accent, 0.8);
    color: var(--color-dark);
    border-color: var(--color-dark);
  }
  &:active {
    background: var(--color-lightgrey);
  }
} */
</style>
