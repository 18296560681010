import { provide, inject, Ref, ref } from 'vue'

export type AccordionContextProps = {
  initial?: string
}

export type AccordionContextType = {
  current?: Ref<string>
  toggle: any
  set: any
}

export const ACCORDION_INJECTION_KEY = Symbol('ACCORDION')

export const useAccordionContext = (options?: AccordionContextProps) => {
  const current = ref(options?.initial || '')

  const set = (index: string) => {
    current.value = index
  }

  const toggle = (index: string) => {
    if (index == current.value) {
      current.value = ''
    } else {
      current.value = index
    }
  }

  const values = {
    set,
    toggle,
    current,
  }

  provide<AccordionContextType>(ACCORDION_INJECTION_KEY, values)
  return values
}

export const useAccordionInject = () => {
  const context = inject<AccordionContextType>(ACCORDION_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
