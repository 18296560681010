<template>
  <button class="product-question" @click="setOverlay('QUESTION')">Ask a question</button>
</template>

<script lang="ts" setup>
import { useOverlayInject } from '../../context/useOverlayContext'

const { setOverlay } = useOverlayInject()
</script>

<style lang="scss">
@import '~styles/base';

.product-question {
  cursor: pointer;
  padding-top: 20px;
  color: var(--color-midgrey);
  @include border-underline(var(--color-midgrey));
}
</style>
