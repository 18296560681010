<template>
  <form class="form-wrap" @submit.prevent="onSubmit">
    <h3 class="form-wrap__title">{{ title }}</h3>
    <div v-if="error" class="form-wrap__error" v-text="error"></div>
    <div class="form-wrap__form">
      <slot />
    </div>

    <slot name="note" />
  </form>
</template>

<script lang="ts" setup>
defineProps<{
  title: string
  error: string
}>()

const emits = defineEmits(['submit'])

const onSubmit = (e: any) => {
  emits('submit', e)
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.form-wrap {
  &__title {
    @include sans;
    @include uppercase;
    min-height: 3em;
    padding-bottom: 1em;
  }

  &__error {
    color: var(--color-error);
    margin-bottom: 1em;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }
}
</style>
