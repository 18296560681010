<template>
  <li class="collection-index-item" @mouseenter="onMouseEnter">
    <a :href="url">
      <div>({{ count }})</div>
      <div>{{ title }}</div>
    </a>
  </li>
</template>

<script setup>
import { inject } from 'vue'

const props = defineProps({
  count: {
    type: [Number, String],
    required: true,
  },
  index: {
    type: [Number, String],
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  url: {
    type: String,
    required: true,
  },
})

const setActive = inject('setActive')

function onMouseEnter() {
  setActive(props.index)
}
</script>

<style lang="scss" scoped>
@import '~styles/base.scss';

.collection-index-item {
  position: relative;
}

a {
  display: grid;
  grid-template-columns: max(4ch, #{grid-columns(1, -1)}) 1fr;
  column-gap: var(--gutter);

  > *:last-child {
    @include display-m-caps;
  }
}

.collection-index-item {
  &:focus,
  &:hover {
    .thumb {
      opacity: 1;
    }
  }
}
</style>
