export default {
  data() {
    return {
      intersected: false,
      intersectionDisconnect: false,
      intersectionObserver: null,
      intersectionRootMargin: '0px 0px 0px 0px',
      intersectionThreshold: 0,
    }
  },

  created() {
    if (typeof window !== 'undefined') {
      this.intersectionObserver = new IntersectionObserver(
        (entries) => {
          const entry = entries[0]
          if (entry.isIntersecting) {
            this.intersected = true
            this.$emit('intersect', entry)
            if (this.onIntersection) {
              this.onIntersection(entry)
            }
            if (this.intersectionDisconnect) {
              this.intersectionObserver.disconnect()
            }
          } else {
            this.onDisjoin(entry)
          }
        },
        {
          rootMargin: this.intersectionRootMargin,
          threshold: this.intersectionThreshold,
        }
      )
    }
  },

  methods: {
    onDisjoin(entry) {
      this.intersected = false
      this.$emit('disjoin', entry)
      if (this.onIntersection) {
        this.onIntersection(entry)
      }
    },
  },

  mounted() {
    if ('IntersectionObserver' in window) {
      this.intersectionObserver.observe(this.$el)
    }
  },

  destroyed() {
    if ('IntersectionObserver' in window) {
      this.intersectionObserver.disconnect()
    }
  },
}
