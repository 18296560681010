<template>
  <div class="overlay-border-right"></div>
</template>

<style lang="scss">
.overlay-border-right {
  position: absolute;
  right: 0;
  width: 1px;
  top: 0;
  bottom: 0;
  background: var(--color-dark);
}
</style>
