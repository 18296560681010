import { provide, inject, Ref, ref, computed } from 'vue'
import { LiquidProduct, LiquidVariant } from '../types/liquid'
import { addLineItem } from '_library'

export type PolishSetContextProps = {
  setProduct: LiquidProduct
}

export type PolishSetContextType = {
  product: LiquidProduct
  max: number
  count: Ref<number>
  remaining: Ref<number>
  price: Ref<number>
  selection: Ref<
    {
      productId: string
      title: string
      variant: LiquidVariant
      count: number
    }[]
  >
  items: Ref<
    {
      title: string
      productId: string
      variant: LiquidVariant
    }[]
  >
  clearSelection: () => void
  updateVariant: (variant: LiquidVariant, adjust?: number) => void
  onPurchase: () => Promise<void>
  error: Ref<string>
}

export const POLISH_SET_INJECTION_KEY = Symbol('POLISH_SET')

export const usePolishSet = ({ setProduct }: PolishSetContextProps) => {
  const max = 3

  const error = ref<string>('')
  const price = ref<number>()
  const selection = ref<PolishSetContextType['selection']['value']>([])
  const items = ref<PolishSetContextType['items']['value']>([])

  const count = computed(() => selection.value.reduce((acc, curr) => acc + curr.count, 0))

  const remaining = computed(() => {
    return max - count.value
  })

  const updateVariant = (variant: LiquidVariant, adjust = 1) => {
    if (count.value + adjust > max) return
    error.value = ''

    const selectionIndex = selection.value.findIndex((item) => item.variant.id === variant.id)

    if (selectionIndex > -1) {
      selection.value[selectionIndex].count += adjust
      if (selection.value[selectionIndex].count === 0) {
        selection.value.splice(selectionIndex, 1)
      }
    } else {
      selection.value = [
        ...selection.value,
        {
          productId: `${variant.product_id}`,
          title: variant.product_title,
          variant,
          count: adjust,
        },
      ]
    }

    items.value = selection.value
      .map((item) =>
        item.count - 1 >= 0
          ? [
              ...Array(item.count - 1).fill(item),
              {
                title: item.title,
                productId: item.productId,
                variant: item.variant,
              },
            ]
          : []
      )
      .flat()

    price.value = selection.value.reduce(
      (acc, curr) => acc + (parseFloat(curr.variant.price) / 100) * curr.count,
      0
    )
  }

  const clearSelection = () => {
    error.value = ''
    selection.value = []
    items.value = []
  }

  const onPurchase = async () => {
    if (remaining.value > 0) {
      error.value = `Please select ${remaining.value} more ${
        remaining.value > 1 ? 'polishes' : 'polish'
      }`
      return
    }

    // Product ID of the set
    // const productId = setProduct.id

    const selectionText = selection.value
      .map((item) => `${item.title.replace(' - Unboxed', '')} x ${item.count}`)
      .join(', ')

    const selectionIds = selection.value.map((item) => item.variant.id).join(', ')

    await addLineItem({
      // variantId: productId,
      variantId: setProduct.variants[0].id,
      quantity: 1,
      properties: {
        Selection: selectionText,
        _selection_variant_ids: selectionIds,
      },
    })
  }

  const values = {
    product: setProduct,
    max,
    count,
    remaining,
    selection,
    price,
    items,
    clearSelection,
    updateVariant,
    onPurchase,
    error,
  } as PolishSetContextType

  provide<PolishSetContextType>(POLISH_SET_INJECTION_KEY, values)
  return values
}

export const usePolishSetInject = () => {
  const context = inject<PolishSetContextType>(POLISH_SET_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
