<template>
  <div class="scrolling-banner">
    <div
      class="scrolling-banner__container"
      v-for="(banner, key) in banners"
      :key="key"
      v-html="banner"
    ></div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  content: string
}>()
const banners = [props.content, props.content, props.content]
</script>

<style scoped lang="scss">
@import '~styles/base.scss';

.scrolling-banner {
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  @keyframes marquee {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
  &__container {
    display: inline-block;
    width: 50%;
    text-align: center;
    animation: marquee 15s linear infinite;
    @include breakpoint(l) {
      width: 100%;
      min-width: 500px;
    }
  }
}
</style>
