<template>
  <span class="shopify-link-shop">
    <ShopifyLinkSubmenu
      v-if="mode == 'DEFAULT'"
      class="shopify-link-shop__submenu"
      title="Shop"
      :depth="depth + 1"
      :links="linklists['submenu-shop'].links"
    />

    <div v-else class="shopify-link-shop__menus">
      <a class="shopify-link__title shopify-link-shop__title" href="/collections/all">SHOP</a>

      <ShopifyLinksCollection
        class="shopify-link-shop__menu"
        :depth="depth + 1"
        :links="linklists['header-categories'].links"
        :is-grey="true"
      />

      <ShopifyLinksCollection
        class="shopify-link-shop__menu"
        :depth="depth + 1"
        :links="linklists['header-types'].links"
        :is-grey="true"
      />

      <ShopifyLinksCollection
        class="shopify-link-shop__menu"
        :depth="depth + 1"
        :links="linklists['header-vendors'].links"
        :is-grey="true"
      />

      <ShopifyLinksCollection
        class="shopify-link-shop__menu"
        :depth="depth + 1"
        :links="linklists['header-accessories'].links"
        :is-grey="false"
      />
    </div>
  </span>
</template>

<script lang="ts" setup>
import { useAppContext } from '../../../context/useAppContext'
import ShopifyLinksCollection from './ShopifyLinksCollection.vue'
import ShopifyLinkSubmenu from './ShopifyLinkSubmenu.vue'

const { linklists, templateHandle } = useAppContext()
const mode = (() => {
  let mode = 'DEFAULT'
  if (templateHandle.indexOf('collection') > -1) mode = 'PRODUCT'
  if (templateHandle.indexOf('search') > -1) mode = 'PRODUCT'
  if (templateHandle.indexOf('product') > -1) mode = 'PRODUCT'
  if (templateHandle.indexOf('page-shop') > -1) mode = 'PRODUCT'
  return mode
})()

withDefaults(
  defineProps<{
    depth: number
  }>(),
  {
    depth: 0,
  }
)
</script>

<style lang="scss">
@import '~styles/base.scss';

.shopify-link-shop {
  &__title {
    display: inline-flex;
    margin-bottom: 1em;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;

    @mixin active {
      color: var(--color-dark);
      text-decoration: none;
      &:before {
        opacity: 1;
        transform: none;
      }
    }

    .shopify-link {
      position: relative;

      // prettier-ignore
      &:before {
        content: ' ';
        position: absolute;
        bottom: 2px;
        right: calc(100% + 5px);
        width: 8px;
        height: 16px;
        background: url("#{svgEncode('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 4.2 4.2" style="enable-background:new 0 0 4.2 4.2;" xml:space="preserve"> <polygon points="1.7,0.4 0.5,1.7 0.5,2.5 1.7,3.7 2.5,3.7 3.7,2.5 3.7,1.7 2.5,0.4 "/></svg>')}") 50% no-repeat;
        background-size: 6px;
        opacity: 0;
        transform: translateY(5px);
        transition: opacity 0.2s ease-out, transform 0.1s ease-out;
      }

      .shopify-link__text {
        text-transform: none;
      }

      @include hover {
        @include active;
      }
    }

    .shopify-link-default--active {
      @include active;
    }
  }
}
</style>
