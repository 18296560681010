<template>
  <div class="image">
    <a v-if="!isEngraving" :href="line.url">
      <ShopifyImage
        :src="line.image"
        :alt="line.title"
        :width="300"
        :height="400"
        crop="center"
        class="image"
      ></ShopifyImage>
    </a>
    <div v-else>
      <ShopifyImage
        :src="line.image"
        :alt="line.title"
        :width="300"
        :height="400"
        crop="center"
        class="image"
      ></ShopifyImage>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed} from "vue";
import { LiquidLineItem } from '../../types/liquid';
import ShopifyImage from "../shopify/ShopifyImage.vue"

const props = defineProps<{
  line: LiquidLineItem
}>();

const isEngraving = computed(() => props.line.product?.handle?.includes('engraving'))


</script>

<style lang="scss">

</style>