<template>
  <div class="home-tiles">
    <a v-for="block in blocks" :href="block.url" :aria-label="block.title" :key="block.title">
      <figure class="home-tiles__tile">
        <CommonImage
          :alt="block.image_alt || block.title || ''"
          :src="block.image_url"
          width="600"
          height="450"
        />
        <figcaption class="home-tiles__caption">
          <h2>{{ block.title }}</h2>
          <span>{{ block.caption }}</span>
        </figcaption>
      </figure>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import CommonImage from '../common/CommonImage.vue'

const props = defineProps<{
  section: {
    blocks: {
      image_alt: string
      image_url: string
      title: string
      caption: string
      url: string
    }[]
  }
}>()

const blocks = computed(() => props.section.blocks)
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-tiles {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin: var(--index-section-spacing) var(--inset);
  gap: 60px columns(2, 1);
  display: grid;

  @include breakpoint('l+') {
    padding: 0 columns(2);
  }

  @include breakpoint('l') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 60px 40px;
  }

  @include breakpoint('s') {
    grid-template-columns: 1fr;
  }

  &__caption {
    margin-top: 9px;

    > h2 {
      @include heading-m;

      font-weight: 200;

      @media (min-width: 750px) {
        display: inline-block;
        margin-right: 0.5ch;
      }
    }

    > span {
      @include display-s;
    }

    @include breakpoint('l') {
      text-align: center;
    }
  }
}
</style>
