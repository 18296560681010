import { computed, ref, onMounted } from 'vue'

export type DialogueProps = {
  name?: string
  hideDays?: number
  showDelayMs?: number
  pathBlacklist?: string[]
  pathWhitelist?: string[]
  hideAfterFirstView?: boolean
}

export default function useDialogue({
  hideDays = 28,
  showDelayMs = 1000,
  hideAfterFirstView = true,
  name = 'dialogue',
  pathBlacklist,
  pathWhitelist,
}: DialogueProps) {
  const DAY_MS = 8.64e7
  const hideTime = JSON.parse(localStorage.getItem(name) || '0')
  const hiddenRecently = Date.now() - hideTime < hideDays * DAY_MS

  const canShow = computed(() => {
    const isPermittedPath = pathWhitelist?.includes(window.location.pathname) || true
    const isForbiddenPath = pathBlacklist?.includes(window.location.pathname) || false

    return isPermittedPath && !isForbiddenPath && !hiddenRecently
  })

  const show = computed(() => canShow.value && opened.value)
  const opened = ref(showDelayMs === 0 && !hiddenRecently)

  function close() {
    opened.value = false
    setStorage()
  }

  function open() {
    // console.log('show popup', canShow.value, opened.value, hiddenRecently)

    if (canShow.value) {
      setTimeout(() => {
        opened.value = true
        if (hideAfterFirstView) setStorage()
      }, showDelayMs)
    }
  }

  function setStorage() {
    localStorage.setItem(name, String(Date.now()))
  }

  onMounted(open)

  return {
    show,
    close,
    open,
  }
}
