<template>
  <div class="shopify-money">
    <div class="shopify-money__symbol">{{ frontendSymbol }}</div>
    <div class="shopify-money__amount">{{ number }}</div>
    <div class="shopify-money__code" v-if="codeDisplay">
      {{ codeDisplay }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { formatNumber } from '_library/src/utils/price'
import { SHOPIFY_DEFAULT_CURRENCY } from '../../services/currency'
import { useCurrencyInject } from '../../context/useCurrencyContext'

const props = defineProps<{
  showDefaultCurrencyCode: boolean
  showCurrencyCode: boolean
  hideDoubleZeros: boolean
  amount: number
}>()

const { frontendCode, frontendSymbol, rate } = useCurrencyInject()

const codeDisplay = computed(() => {
  if (!props.showDefaultCurrencyCode && frontendCode.value == SHOPIFY_DEFAULT_CURRENCY) {
    return ''
  }

  if (!props.showCurrencyCode) return ''

  return frontendCode.value
})

const number = computed(() => {
  const formatted = formatNumber({
    amount: props.amount,
    rate: rate.value,
    code: frontendCode.value,
    showZeroCents: !props.hideDoubleZeros,
  })

  return formatted.number
})
</script>

<style lang="scss">
.shopify-money {
  display: inline-flex;

  &__code {
    margin-left: 0.2em;
  }

  &__amount {
    // margin-left: 0.1em;
  }
}
</style>
