<template>
  <div v-show="show" class="overlay-dialogue">
    <div class="overlay-dialogue__inner">
      <div v-if="title" class="overlay-dialogue__title">{{ title }}</div>
      <OverlayCloseButton class="overlay-dialogue__close" @click="close" />
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue'
import useDialogue, { DialogueProps } from '../../composables/useDialogue'
import OverlayCloseButton from './OverlayCloseButton.vue'

const props = defineProps<DialogueProps & { title?: string }>()
const { show, close } = useDialogue(props as DialogueProps)

const slots = defineSlots()
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-dialogue {
  position: fixed;
  width: 280px;
  bottom: auto;
  top: var(--overlay-dialogue-offset);
  right: var(--overlay-dialogue-offset);
  z-index: 950;
  display: flex;
  flex-direction: column;
  justify-items: flex-end;

  @include media('s') {
    left: var(--overlay-dialogue-offset);
    width: auto;
  }

  &__inner {
    position: relative;
    color: var(--color-dark);
    background-color: white;
    border: 1px solid;
    overflow: hidden;
    padding: 10px;
  }

  &--newsletter {
    width: 400px;
    padding: 10px;

    @include media('s') {
      width: auto;
    }
  }

  &__title {
    @include uppercase;
  }

  &__close {
    right: 10px;
    top: 10px;
  }
}
</style>
