<template>
  <div
    role="complementary"
    aria-label="Cart sidebar"
    class="overlay-cart"
    ref="container"
    :class="`${isActive ? 'overlay-cart--active' : ''}`"
  >
    <div class="overlay-cart__container">
      <OverlayCartHeading />
      <OverlayCartItems />
      <OverlayCartEmpty />
      <OverlayCartRoute />
      <OverlayCartGift />
      <OverlayCartNote />
      <OverlayCartDuties />
      <!-- <OverlayCartDisclosure /> -->
      <OverlayCartControls />
    </div>
    <OverlayBorderLeft />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue'

import OverlayCartHeading from './cart/OverlayCartHeading.vue'
import OverlayCartEmpty from './cart/OverlayCartEmpty.vue'
import OverlayCartRoute from './cart/OverlayCartRoute.vue'
import OverlayCartGift from './cart/OverlayCartGift.vue'
import OverlayCartDuties from './cart/OverlayCartDuties.vue'

import { useOverlayInject } from '../../context/useOverlayContext'
import OverlayCartItems from './cart/OverlayCartItems.vue'
import OverlayCartNote from './cart/OverlayCartNote.vue'
import OverlayCartControls from './cart/OverlayCartControls.vue'
import OverlayBorderLeft from './OverlayBorderLeft.vue'

const { current } = useOverlayInject()
const isActive = computed(() => current.value == 'CART')
const container = ref<HTMLElement | null>(null)

watchEffect(() => {
  if (isActive.value) {
    const firstInput = container?.value?.querySelector('button')
    if (firstInput) firstInput.focus()
  }
})
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-cart {
  @include text-xsmall;
  background: var(--color-lightbackground);
  color: var(--color-dark);
  overflow-y: auto;
  position: fixed;
  padding: 0;

  width: var(--drawer-width);
  height: var(--100vh);
  min-height: auto;
  max-width: 100vw;
  right: 0;
  top: 0;

  &:not(.overlay-cart--active) {
    pointer-events: none;
    opacity: 0;
  }

  &__container {
    flex-direction: column;
    display: flex;
    height: 100%;
    padding: 0 10px;
  }
}
</style>
