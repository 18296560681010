<template>
  <section class="collection-index" @mouseenter="mouseInside = true" @mouseleave="mouseInside = false">
    <ul class="collection-index__inner">
      <slot />
      <transition name="fade">
        <CollectionIndexItemThumb v-show="mouseInside">
          <slot name="thumbnail" :active-index="activeIndex" />
        </CollectionIndexItemThumb>
      </transition>
    </ul>
  </section>
</template>

<script lang="ts" setup>
import { provide, ref } from 'vue'
import CollectionIndexItemThumb from './CollectionIndexItemThumb.vue'

const mouseInside = ref(false)
const activeIndex = ref(0)

function setActive(newActiveItem: any) {
  activeIndex.value = newActiveItem
}

provide('setActive', setActive)
</script>

<style lang="scss">
@import '~styles/base.scss';

.collection-index {
  margin: var(--index-section-spacing) 0;
  position: relative;
  width: 100%;
  z-index: 50;

  &__inner {
    grid-template-columns: repeat(2, #{columns(7)}) 1fr;
    /* padding-left: columns(1); */
    padding-left: var(--inset);
    justify-content: flex-start;
    display: grid;
    row-gap: 8px;

    @include breakpoint('xxl') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint('m') {
      grid-template-columns: 1fr;
      padding-left: 0;
    }

    @media (hover: hover) {
      .collection-index:not(:hover) .collection-index-item-thumb {
        opacity: 0;
      }
    }
  }
}
</style>
