<template>
  <div class="collection-index-item-thumb" :style="{ transform: `translate(${x}px, ${top}px)` }">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useMouse } from '@vueuse/core'

const { x, y } = useMouse()

const top = computed(() => y.value - window.scrollY)
</script>

<style lang="scss" scoped>
.collection-index-item-thumb {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  pointer-events: none;
  z-index: -1;
  display: grid;
  backface-visibility: hidden;

  & ::v-deep .image {
    grid-area: 1 / 1;
    display: flex;
    transition: opacity 300ms ease 0;

    &:not(.active) {
      opacity: 0;
      transition-delay: 100ms;
    }
  }
}
</style>
