<template>
  <div class="Accordion">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useAccordionContext } from '../../context/useAccordionContext'

useAccordionContext()
</script>
