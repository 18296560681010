import Cookie from 'js-cookie'

export const getJSONCookie = (name: string) => {
  const cookie = Cookie.get(name)
  // console.log("get cookie", name, cookie);
  return cookie && JSON.parse(decodeURI(cookie))
}

export const setJSONCookie = (name: string, data: any) => {
  const jsonData = encodeURI(JSON.stringify(data))
  // console.log("set cookie", name, jsonData);
  return Cookie.set(name, jsonData)
}

export const getCookie = (name: string) => {
  const cookie = Cookie.get(name)
  return cookie
}

export const setCookie = (name: string, data: string) => {
  return Cookie.set(name, data)
}
