<template>
  <div class="toggle-content">
    <button
      @click.prevent="toggle(title)"
      class="toggle-content__heading"
      :class="`toggle-content__heading--${isActive ? 'active' : 'disabled'}`"
    >
      <h2 class="toggle-content__title">
        {{ title }}
      </h2>
    </button>
    <div class="toggle-content__body" v-if="isActive">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useAccordionInject } from '../../context/useAccordionContext'

const props = defineProps<{
  title: string
}>()

const { current, toggle } = useAccordionInject()
const isActive = computed(() => current?.value == props.title)
</script>

<style lang="scss">
@import '~styles/base';

.toggle-content {
  &__heading {
    /* justify-content: space-between; */
    @include uppercase;
    cursor: pointer;
    display: flex;
    width: 100%;

    &:after {
      display: inline-flex;
      padding-left: 20px;
      content: '+';
    }

    &--active {
      &:after {
        content: '−';
      }
    }
  }

  &__body {
    padding-top: 10px;
    p + p {
      margin-top: 1em;
    }

    a {
      color: var(--color-midgrey);
      text-decoration: underline;
      @include hover {
        text-decoration: none;
      }
    }
  }
}
</style>
