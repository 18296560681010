<template>
  <div v-for="block in blocks" :key="block.caption" class="home-captioned-images">
    <a :href="block.url">
      <CaptionedImage :caption="block.caption">
        <CommonImage :src="block.image_url" :alt="block.caption || ''" />
      </CaptionedImage>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import CommonImage from '../common/CommonImage.vue'
import CaptionedImage from '../common/CommonImageWithCaption.vue'

const props = defineProps<{
  section: {
    blocks: {
      image_url: string
      caption: string
      url: string
    }[]
  }
}>()

const blocks = computed(() => props.section.blocks)
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-captioned-images {
  width: columns(10, -1);
  margin: var(--index-section-spacing) var(--inset) var(--index-section-spacing)
    calc(var(--inset) + columns(1, 0));
}
</style>
