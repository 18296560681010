<template>
  <section class="product-influences" v-if="section">
    <div class="product-influences__card">
      <div class="product-influences__subtitles">
        <div class="product-influences__subtitle" v-html="section.subtitle_left"></div>
        <div class="product-influences__subtitle" v-html="section.subtitle_right"></div>
      </div>
    </div>

    <div class="product-influences__inner">
      <div class="product-influences__title" v-html="section.title"></div>
      <div class="product-influences__images">
        <figure class="product-influences__image">
          <img :src="section.influence_image_1" alt="Influence 1" />
          <figcaption v-html="section.influence_caption_1"></figcaption>
        </figure>
        <figure class="product-influences__image">
          <img :src="section.influence_image_2" alt="Influence 2" />
          <figcaption v-html="section.influence_caption_2"></figcaption>
        </figure>
      </div>
      <div class="product-influences__description">
        <div class="product-influences__description-text" v-html="section.influence_description"></div>
        <div class="product-influences__cta">
          <a :href="section.link">
            <span v-html="section.link_text"></span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useProductInject } from '../../context/useProductContext'

const { influences } = useProductInject()
const { section } = influences
</script>

<style lang="scss">
@import '~styles/base';

.product-influences {
  position: relative;
  margin: var(--product-section-spacing) 0;
  padding-left: calc(var(--inset) + #{columns(5, 0)});
  width: columns(16, -1);

  @include breakpoint('l') {
    display: none;
  }

  &__subtitles {
    width: 100%;
    bottom: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  &__title {
    @include heading-large-serif;
    margin-bottom: 60px;
    grid-area: influencestitle;
  }

  &__inner {
    display: grid;
    padding: 50px;
    padding: columns(1);
    border: 1px solid var(--color-dark);
    grid-template-columns: columns(9, -1) columns(6, -1);
    gap: 0 columns(1);
    grid-template-rows: auto;
    grid-template-areas:
      'influencesimages influencestitle'
      'influencesimages influencesdescription';
  }

  &__images {
    counter-reset: influence;
    grid-area: influencesimages;
    display: flex;
    justify-content: space-between;
  }

  &__image {
    position: relative;
    margin-bottom: 40px;
    width: calcPercentage(9, 4);
    padding: 0 var(--gutter);

    figcaption {
      @include caption-xsmall-sans;

      padding-top: 10px;
      margin-bottom: 40px;

      &:before {
        counter-increment: influence;
        content: counter(influence) '.';
        margin-right: 15px;
      }
    }
  }

  &__description {
    @include content;
    max-width: 260px;
    padding: 40px 0 0;
    grid-area: influencesdescription;
  }

  &__description-text {
    margin-bottom: 40px;
  }

  &__cta {
    @include text-medium;
    @include uppercase;
    @include helper-link-underline;
    line-height: 1.3;
  }

  @include breakpoint(m) {
    padding-bottom: 100px;
  }
}
</style>
