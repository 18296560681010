import { parseGid } from '@shopify/admin-graphql-api-utilities'
import { getCode as getCountryCode } from 'country-list'

export const gidToNumber = (value: string) => parseInt(parseGid(value))

export type CountryProvince = {
  name: string
  code: string
  provinces: Array<{
    name: string
    code: string
  }>
}

export const parseCountryOptions = (html: string): CountryProvince[] => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  const options = doc.querySelectorAll('option')

  const countries = Array.from(options).map((option) => {
    const name = option.value
    const code = getCountryCode(name)
    const dataProvinces = option.getAttribute('data-provinces')

    if (!dataProvinces) {
      return { name, code, provinces: [] }
    }

    let provinces = []
    try {
      provinces = JSON.parse(dataProvinces.replace(/&quot;/g, '"').replace(/&amp;/g, '&'))
        .filter((provinceArray: any[]) => Array.isArray(provinceArray) && provinceArray.length >= 2)
        .map((provinceArray: [any, string]) => ({
          name: provinceArray[1],
          code: getCountryCode(provinceArray[1]),
        }))
    } catch (error) {
      console.error('Error parsing provinces for country:', name, error)
    }

    return { name, code, provinces }
  }) as CountryProvince[]

  return countries
}
