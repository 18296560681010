<template>
  <fieldset class="checkbox">
    <input
      :id="labelId"
      type="checkbox"
      :checked="value"
      @input="$emit('input', $event.target?.checked)"
    />
    <label :for="labelId">
      <slot />
    </label>
  </fieldset>
</template>

<script lang="ts" setup>
defineProps<{
  value: boolean
}>()

const labelId = `checkbox-label-${Math.random().toString(36).substr(2, 9)}`
</script>

<style lang="scss" scoped>
.checkbox {
  display: inline-flex;
  align-items: flex-start;

  :first-child:not(:only-child) {
    margin-right: 5px;
    width: 13px;
  }
}

input {
  $width: 13px;
  $inner-width: 13px - 6px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $width;
  border: 1px solid;
  border-radius: 50%;
  background: transparent;
  transition: background 0.1s ease;
  cursor: pointer;
}

input:checked {
  background: currentColor;
}
</style>
