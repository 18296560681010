<template>
  <ShopifyMoney
    :show-default-currency-code="showDefaultCurrencyCode"
    :show-currency-code="showCurrencyCode"
    :hide-double-zeros="hideDoubleZeros"
    :amount="amount"
    class="price-display"
  />
</template>

<script lang="ts" setup>
import ShopifyMoney from './ShopifyMoney.vue'

defineProps<{
  amount: number
}>()

const showDefaultCurrencyCode = true
const showCurrencyCode = false
const hideDoubleZeros = true
</script>

<style lang="scss"></style>
 