<template>
  <label class="input-checkbox">
    <input
      class="input-checkbox__input"
      type="checkbox"
      :checked="!!value"
      :value="!!value"
      @input.prevent="onInput"
    />
    {{ title }}
  </label>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

const props = defineProps<{
  title: string
  value?: boolean
}>()

const value = ref(props.value)
const emits = defineEmits(['input'])

const onInput = (event: Event) => {
  emits('input', (event.target as HTMLInputElement).checked)
}
</script>

<style lang="scss">
.input-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  &__input {
    margin-right: 0.5rem;
    transform: translateY(-2px);

    &:before {
      content: '';
      display: inline-flex;
      width: 10px;
      height: 10px;
      border: 1px solid var(--color-dark);
      vertical-align: baseline;
      cursor: pointer;
    }

    &:checked:before {
      background-color: var(--color-dark);
    }
  }
}
</style>
