<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1200 256.8"
    style="enable-background: new 0 0 1200 256.8"
    xml:space="preserve"
  >
    <g>
      <path
        d="M1175.3,84.3c-6.6-6.8-15.2-10.1-26-10.1c-7.8,0-15.2,1.3-22.4,3.7c-7.2,2.5-13,5.5-17.5,9c-4.5,3.5-8.1,6.5-10.7,9.2
		c-2.6,2.6-4.2,4.6-4.9,5.9L1092.5,3h-5.4l-32.5,15.4v4.2l1.8,0.6c1.2,0.4,2.9,1.6,5.1,3.7c2.2,2.1,4.2-2.6,6.1,0.8v148.9
		c-2.1,3.3-4.3,13.4-6.6,15.4c-2.3,2-3.9,3.2-4.7,3.6c-0.8,0.4-1.4,0.6-1.9,0.7v4.3h52.4v-4.3l-1.8-0.6c-1.2-0.4-2.9-1.6-5-3.7
		c-2.1-2-4.2-4.7-6-8v-68.2c1-1.5,2-2.9,2.9-4.1c0.9-1.2,2.8-3.3,5.7-6c2.8-2.8,5.7-5.1,8.6-6.9c2.9-1.8,6.5-3.4,10.9-4.8
		c4.4-1.3,8.9-1.8,13.4-1.4c5.8,0,11,1.7,15.8,5.1c4.8,3.4,7.2,8.3,7.2,14.7v71.3c-1.9,3.4-4,6.1-6.3,8.2c-2.3,2.1-3.8,3.3-4.6,3.7
		c-0.8,0.4-1.4,0.6-1.9,0.7v4.3h52.5v-4.3l-1.8-0.6c-1.2-0.4-2.9-1.6-5-3.7c-2.1-2.1-4.2-4.8-6-8.2v-69.2
		C1185.2,101.1,1181.9,91.1,1175.3,84.3 M919.8,163.4c-2.9-8.6-4.4-10.3-4.4-20.1c0-15,3.4-27.9,10.1-38.6
		c6.7-10.7,15.4-16.1,26-16.1c10.9,0,19.6,5.3,26.2,16c6.6,10.7,9.9,23.6,9.9,38.7c0,9.8-1.5,11.6-4.4,20.1
		c-2.9,8.6-7.2,15.5-12.8,20.9c-5.6,5.4-11.9,8.1-19,8.1c-7.1,0-13.4-2.7-19-8.1C927,178.9,922.7,172,919.8,163.4 M1007.2,76.6
		c-2.4,3.6-4.9,6.7-7.5,9.4c-2.6,2.7-4.7,4.6-6.2,5.6c-1.5,1-2.2,1.5-2.2,1.3c-5.5-6.5-11.1-10.8-16.9-13.1
		c-5.8-2.3-13.4-3.4-22.8-3.4c-19.5,0-34.9,5.9-46.1,17.8c-11.2,11.8-16.9,28.2-16.9,49.2c0,21.4,5.6,30.7,16.9,43
		c11.3,12.2,26.6,18.4,46,18.4c8.1,0,15.2-1.7,21.5-5.2c6.3-3.5,11.2-8.5,14.6-15.1l5.7,16.3h32.8v-4.3l-1.8-0.6
		c-1.2-0.4-2.9-1.6-5-3.7c-2.1-2.1-4.2-4.8-6-8.2V76.6H1007.2z M843.2,84.3c-6.6-6.8-15.2-10.1-26-10.1c-7.8,0-15.2,1.3-22.4,3.7
		c-7.2,2.5-13,5.5-17.5,9c-4.5,3.5-8.1,6.5-10.7,9.2c-2.6,2.6-4.2,4.6-4.9,5.9l-9.6-25.4h-4.2c-3.8,3.5-8,6.6-12.6,9.2
		c-4.6,2.7-7.8,4.3-9.5,5c-1.7,0.7-3,1.1-4,1.4v4.2l1.8,0.6c1.2,0.4,2.9,1.6,5,3.7c2.1,2.1,4.2,4.8,6,8.2v74.8
		c-1.9,3.4-4,6.1-6.3,8.2c-2.3,2.1-3.8,3.3-4.6,3.7c-0.8,0.4-1.4,0.6-1.9,0.7v4.3h52.7v-4.3l-1.8-0.6c-1.2-0.4-2.9-1.6-5-3.7
		c-2.1-2.1-4.2-4.8-6-8.2v-67.9c0.7-1.4,2-3.2,4-5.5c2-2.3,4.7-5,8.1-8.1c3.4-3.1,7.8-5.6,13.1-7.5c5.3-1.9,10.7-2.7,16.3-2.2
		c5.8,0,11,1.7,15.8,5.1c4.8,3.4,7.2,8.3,7.2,14.7v71.3c-1.9,3.4-4,6.1-6.3,8.2c-2.3,2.1-3.8,3.3-4.6,3.7c-0.8,0.4-1.4,0.6-1.9,0.7
		v4.3h52.5v-4.3l-1.8-0.6c-1.2-0.4-2.9-1.6-5-3.7c-2.1-2.1-4.2-4.8-6-8.2v-69.2C853,101.1,849.7,91.1,843.2,84.3 M671.1,84.3
		c-6.6-6.8-15.2-10.1-26-10.1c-7.8,0-15.2,1.3-22.4,3.7c-7.2,2.5-13,5.5-17.5,9c-4.5,3.5-8.1,6.5-10.7,9.2c-2.6,2.6-4.2,4.6-4.9,5.9
		L580,76.6h-4.2c-3.8,3.5-8,6.6-12.6,9.2c-4.6,2.7-7.8,4.3-9.5,5c-1.7,0.7-3,1.1-4,1.4v4.2l1.8,0.6c1.2,0.4,2.9,1.6,5,3.7
		c2.1,2.1,4.2,4.8,6,8.2v74.8c-1.9,3.4-4,6.1-6.3,8.2c-2.3,2.1-3.8,3.3-4.6,3.7c-0.8,0.4-1.4,0.6-1.9,0.7v4.3h52.7v-4.3l-1.8-0.6
		c-1.2-0.4-2.9-1.6-5-3.7c-2.1-2.1-4.2-4.8-6-8.2v-67.9c0.7-1.4,2-3.2,4-5.5c2-2.3,4.7-5,8.1-8.1c3.4-3.1,7.8-5.6,13.1-7.5
		c5.3-1.9,10.7-2.7,16.3-2.2c5.8,0,11,1.7,15.8,5.1c4.8,3.4,7.2,8.3,7.2,14.7v71.3c-1.9,3.4-4,6.1-6.3,8.2c-2.3,2.1-3.8,3.3-4.6,3.7
		c-0.8,0.4-1.4,0.6-1.9,0.7v4.3h52.5v-4.3l-1.8-0.6c-1.2-0.4-2.9-1.6-5-3.7c-2.1-2.1-4.2-4.8-6-8.2v-69.2
		C681,101.1,677.7,91.1,671.1,84.3 M415.3,163.4c-2.9-8.6-4.4-10.3-4.4-20.1c0-15,3.4-27.9,10.1-38.6c6.7-10.7,15.4-16.1,26-16.1
		c10.8,0,19.6,5.3,26.2,16c6.6,10.7,9.9,23.6,9.9,38.7c0,9.8-1.5,11.6-4.4,20.1c-2.9,8.6-7.2,15.5-12.8,20.9
		c-5.6,5.4-11.9,8.1-19,8.1c-7.1,0-13.4-2.7-19-8.1C422.5,178.9,418.2,172,415.3,163.4 M502.7,76.6c-2.4,3.6-4.9,6.7-7.5,9.4
		c-2.6,2.7-4.7,4.6-6.2,5.6c-1.5,1-2.2,1.5-2.2,1.3c-5.5-6.5-11.1-10.8-16.9-13.1c-5.8-2.3-13.4-3.4-22.8-3.4
		c-19.5,0-34.9,5.9-46.1,17.8c-11.3,11.8-16.9,28.2-16.9,49.2c0,21.4,5.6,30.7,16.9,43c11.3,12.2,26.7,18.4,46.1,18.4
		c8.1,0,15.2-1.7,21.5-5.2c6.3-3.5,11.1-8.5,14.6-15.1l5.7,16.3h32.8v-4.3l-1.8-0.6c-1.2-0.4-2.9-1.6-5-3.7c-2.1-2.1-4.2-4.8-6-8.2
		V76.6H502.7z M304,16.4v4.3l1.8,0.6c1.2,0.4,2.9,1.6,5,3.7c2.1,2.1,4.2,4.8,6,8.2v53.9c-2.5,3.7-4.8,6-7,7h-78.2l-0.9-0.4
		c-0.6-0.4-1.5-1.2-2.7-2.4c-1.2-1.2-2.3-2.6-3.4-4.2V33.3c1.9-3.4,4-6.1,6.3-8.2c2.3-2.1,3.8-3.3,4.6-3.7c0.8-0.4,1.4-0.6,1.9-0.7
		v-4.3H183v4.3l1.8,0.6c1.2,0.4,2.9,1.6,5,3.7c2.1,2.1,4.2,4.8,6,8.2v150.4c-1.9,3.4-4,6.1-6.3,8.2c-2.3,2.1-3.8,3.3-4.6,3.7
		c-0.8,0.4-1.4,0.6-1.9,0.7v4.3h54.5v-4.3l-1.8-0.6c-1.2-0.4-2.9-1.6-5-3.7c-2.1-2.1-4.2-4.8-6-8.2v-67.3c2.5-3.7,4.8-6,7-7h78.2
		l0.9,0.4c0.6,0.4,1.5,1.2,2.7,2.4c1.2,1.2,2.3,2.6,3.4,4.2v67.3c-1.9,3.4-4,6.1-6.3,8.2c-2.3,2.1-3.8,3.3-4.6,3.7
		c-0.8,0.4-1.4,0.6-1.9,0.7v4.3h54.5v-4.3l-1.8-0.6c-1.2-0.4-2.9-1.6-5-3.7c-2.1-2.1-4.2-4.8-6-8.2V33.3c1.9-3.4,4-6.1,6.3-8.2
		c2.3-2.1,3.8-3.3,4.6-3.7c0.8-0.4,1.4-0.6,1.9-0.7v-4.3H304z M124.8,205.9H135l16-16v-10.4l-16-16h-10.2l-16,16v10.4L124.8,205.9z
		 M24.8,20.8l1.8,0.6c1.2,0.4,2.9,1.6,5,3.7c2.1,2.1,4.2,4.8,6,8.2v165.5c0,19.3-2.6,32.2-7.8,38.8c-5.2,6.6-13.7,7.1-25.3,1.5v6.1
		c5.2,3.5,10.6,5.7,16.3,6.6c5.7,0.9,11.2,0.4,16.6-1.6c5.4-2,10.2-5.2,14.6-9.8c4.3-4.5,7.8-10.9,10.5-19.2c2.6-8.3,4-17.8,4-28.6
		V33.3c1.9-3.4,4-6.1,6.3-8.2c2.3-2.1,3.8-3.3,4.6-3.7c0.8-0.4,1.4-0.6,1.9-0.7v-4.3H24.8V20.8z"
      />
    </g>
  </svg>
</template>

<script lang="ts" setup></script>