<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 7.6 8.3"
    style="enable-background: new 0 0 7.6 8.3"
    xml:space="preserve"
  >
    <g>
      <path
        d="M4.2,0H3.5v6.8c-0.8-0.9-2-2.3-3-3.3L0,4l3.8,4.2h0L7.6,4L7.1,3.5C6.2,4.5,5,6,4.2,6.8V0z"
      />
    </g>
  </svg>
</template>

<script lang="ts" setup></script>