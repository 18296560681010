import fx from 'money'

import {
  setCurrency as _setCurrency,
  fetchCurrency as _fetchCurrency,
  SHOPIFY_DEFAULT_CURRENCY,
} from '../services/currency'

import { provide, inject, ref, computed, Ref } from 'vue'
import { getCurrencySymbol } from '../services/currency'

export type CurrencyContextType = {
  rate: Ref<number>
  frontendCode: Ref<string>
  frontendSymbol: Ref<string>
  setCurrency: (code: string) => void
}

export const CURRENCY_INJECTION_KEY = Symbol('CURRENCY')

export const useCurrencyContext = () => {
  const code = _fetchCurrency()
  const frontendCode = ref(code)
  const rates = window?.Currency?.rates || [{ USD: 1 }]
  fx.rates = rates

  const getRate = (currencyCode: string) =>
    parseFloat(
      fx.convert(1, {
        from: currencyCode,
        to: SHOPIFY_DEFAULT_CURRENCY,
      })
    )

  const rate = computed(() => getRate(frontendCode.value))
  const frontendSymbol = computed(() => getCurrencySymbol(frontendCode.value))

  const setCurrency = (code: string) => {
    _setCurrency(code)
    frontendCode.value = code
  }

  const values = {
    rate,
    frontendCode,
    frontendSymbol,
    setCurrency,
  }

  provide<CurrencyContextType>(CURRENCY_INJECTION_KEY, values)
  return values
}

export const useCurrencyInject = () => {
  const context = inject<CurrencyContextType>(CURRENCY_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
