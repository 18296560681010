import { provide, inject, Ref, ref } from 'vue'

export type HeaderContextType = {
  bannerHeight: Ref<number>
  invertHeader: Ref<boolean>
  setBannerHeight: any
  setInvertHeader: any
}

export const HEADER_INJECTION_KEY = Symbol('HEADER')

export const useHeaderContext = () => {
  const bannerHeight = ref(0)
  const invertHeader = ref(false)

  const values = {
    bannerHeight,
    invertHeader,
    setBannerHeight: (height: number) => (bannerHeight.value = height),
    setInvertHeader: (toggle: boolean) => (invertHeader.value = toggle),
  }

  provide<HeaderContextType>(HEADER_INJECTION_KEY, values)
  return values
}

export const useHeaderInject = () => {
  const context = inject<HeaderContextType>(HEADER_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
