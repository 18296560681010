<template>
  <TabFeature :tabs="tabs" class="collection-thumbnails">
    <template #buttons>
      <TabFeatureButton class="collection-thumbnails__button" tag="a" href="/collections/all"
        >Shop all</TabFeatureButton
      >
    </template>
    <template #tabs>
      <TabFeatureTab
        v-for="collection in collectionsWithProducts"
        :key="collection.title"
        :title="collection.title"
        :id="collection.id"
        class="collection-thumbnails__tab"
      >
        <CollectionThumbnailsCarousel
          :collection="collection"
          class="collection-thumbnails__carousel"
        />
      </TabFeatureTab>
    </template>
  </TabFeature>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { simpleProductsByGids } from '_library/src'
import { ProductFieldsFragment } from '_library/src/storefront/generated'
import TabFeature from '../../tab-feature/TabFeature.vue'
import TabFeatureButton from '../../tab-feature/TabFeatureButton.vue'
import TabFeatureTab from '../../tab-feature/TabFeatureTab.vue'
import CollectionThumbnailsCarousel from './CollectionThumbnailsCarousel.vue'
import { DEFAULT_COUNTRY_CODE } from '_library'

type SlideType = {
  id: string
  alt: string
  src: string
  number: string
  product: ProductFieldsFragment
}

type SlideWithProductType = SlideType & {
  product: ProductFieldsFragment
}

export type CollectionPropType = {
  type: string
  id: string
  title: string
  slides: SlideType[]
}

type CollectionThumbnailsType = {
  type: string
  id: string
  title: string
  slides: SlideWithProductType[]
}

const collectionsWithProducts = ref<CollectionThumbnailsType[]>([])

const props = defineProps<{
  collections: CollectionPropType[]
}>()

const tabs: TabFeatureType['tabs'] = props.collections.map((collection) => {
  return {
    title: collection.title,
    id: collection.id,
  }
})

onMounted(async () => {
  const productIds = props.collections.reduce((acc: string[], collection: CollectionThumbnailsType) => {
    const newIds = collection.slides.map((slide) => slide.id)
    return [...acc, ...newIds.filter((id) => !acc.includes(id))]
  }, [])

  const products = (
    await simpleProductsByGids(
      productIds.map((id) => `gid://shopify/Product/${id}`),
      { countryCode: DEFAULT_COUNTRY_CODE }
    )
  ).filter((v) => v)

  collectionsWithProducts.value = props.collections.map((collection) => {
    return {
      ...collection,
      slides: collection.slides
        .map((slide) => {
          const product = products.find(
            (product: ProductFieldsFragment) => product.id === `gid://shopify/Product/${slide.id}`
          )

          if (!product || !slide) return null

          return {
            ...slide,
            product,
          } as SlideType
        })
        .filter((v) => !!v) as SlideType[],
    }
  })
})
</script>

<style lang="scss">
@import '~styles/base';

.collection-thumbnails {
  width: 100%;
  .tab-feature {
    &__inner {
      border-top: 1px solid;
      border-bottom: 1px solid;
    }

    &__buttons {
      column-gap: 14px;
      padding: 0 0 9px;

      > :last-child {
        margin-left: auto;

        &:before {
          content: '•';
          margin-right: 1ch;
        }

        &:focus,
        &:hover {
          color: var(--color-light);
        }
      }
    }
  }

  .tab-feature-tab {
    padding-left: 0;
    padding-right: 0;
    border: none;
  }

  .tab-feature-button {
    padding: 0;

    &:not(.tab-feature-button--active):not(:hover):not(:last-child) {
      color: var(--color-lightgrey);
    }

    & > span {
      background: none;
      border: none;
    }
  }

  .pill-button {
    margin-left: 0;
  }
}
</style>
