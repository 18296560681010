import { GraphQLClient } from "graphql-request";
import { getSdk } from "./generated";
import { SHOPIFY_ACCESS_TOKEN, SHOPIFY_API_ENDPOINT, wait } from "../utils";

export const client = new GraphQLClient(SHOPIFY_API_ENDPOINT, {
  headers: { "X-Shopify-Storefront-Access-Token": SHOPIFY_ACCESS_TOKEN },
});

export const ShopifySdk = getSdk(client);

// Rate limit function
// const rateLimit = 1;
// const limit = pLimit(rateLimit);

export const rateLimitRequests = async (promises: any) => {
  let results: any[] = [];

  for (let i = 0; i < promises.length; i += 1) {
    const promise = promises[i];
    const result = await promise;
    results = [...results, result];

    // rate limiter
    if (i != promises.length) await wait(50);
  }

  return results;
};
