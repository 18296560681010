<template>
  <button @click.prevent="setOverlay('CART')">
    <span class="shopify-link__text"> Cart ({{ itemCount }}) </span>
  </button>
</template>

<script lang="ts" setup>
import { useCartInject } from '../../../context/useCartContext'
import { useOverlayInject } from '../../../context/useOverlayContext'

withDefaults(
  defineProps<{
    depth: number
  }>(),
  {
    depth: 0,
  }
)

const { setOverlay } = useOverlayInject()
const { itemCount } = useCartInject()
</script>

<style lang="scss"></style>
