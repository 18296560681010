<template>
  <div
    class="product-thumbnails"
    :class="`${isBottom ? 'product-thumbnails--top' : ''} ${isTop ? 'product-thumbnails--bottom' : ''}`"
  >
    <div class="product-thumbnails__inner" ref="el">
      <button
        aria-label="Select Thumbnail"
        v-for="(media, mediaIndex) in medias"
        :key="`media_${mediaIndex}`"
        @click.prevent="() => onSelectThumbnail(mediaIndex)"
        class="product-thumbnails__thumbnail"
        :class="`product-thumbnails__thumbnail--${mediaIndex == index ? 'active' : 'inactive'}`"
      >
        <ShopifyMedia :media="media" ratio="5x7" />
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useProductInject } from '../../context/useProductContext'
import { useSlideshowInject } from '../../context/useSlideshowContext'
import ShopifyMedia from '../shopify/ShopifyMedia.vue'

const { media: medias } = useProductInject()
const { emblaApi, emblaPaginationApi, index } = useSlideshowInject()
const el = ref<HTMLElement | null>(null)
const isTop = ref(true)
const isBottom = ref(false)

onMounted(() => {
  el.value?.addEventListener('scroll', () => {
    const scrollTop = el.value?.scrollTop || 0
    isTop.value = scrollTop === 0
    isBottom.value = scrollTop + (el.value?.clientHeight || 0) === el.value?.scrollHeight
  })
})

const onSelectThumbnail = (index: number) => {
  if (emblaApi?.value) {
    emblaApi.value.scrollTo(index)

    if (emblaPaginationApi?.value) {
      emblaPaginationApi.value.scrollTo(index)
    }
  }
}
</script>

<style lang="scss">
@import '~styles/base';

.product-thumbnails {
  position: absolute;
  top: 0;
  left: var(--offset);
  width: calc(columns(2, 0) - var(--offset) - var(--gutter));
  height: calc(100%);
  --offset: 5px;

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    left: 0px;
    right: 0px;
    height: 50px;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  &:before {
    background: linear-gradient(transparent, var(--color-background));
    bottom: 0px;
  }

  &:after {
    background: linear-gradient(var(--color-background), transparent);
    top: 0px;
  }

  &--top {
    &:before {
      opacity: 0;
    }
  }

  &--bottom {
    &:after {
      opacity: 0;
    }
  }

  &__inner {
    gap: 6px;
    padding-right: 6px;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    height: 100%;

    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-dark);
    }
  }

  &__thumbnail {
    display: flex;
    padding: 2px;
    border: 1px solid transparent;

    &--active,
    &:focus,
    &:hover {
      border-color: var(--color-dark);
    }

    > * {
      display: block;
      width: 100%;
    }
  }
}
</style>
