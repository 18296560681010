

<template>
<section class="heading">
</section>
</template>

<script lang="ts" setup>

</script>

<style lang="scss">
@import "~styles/base.scss";
</style>