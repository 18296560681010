<template>
  <div class="product-show">
    <div class="product-show__main">
      <div>&nbsp;</div>
      <ProductSlideshow class="product-show__slideshow" />

      <div class="product-show__content">
        <ProductTitle class="product-show__heading" />
        <ProductAccordion class="product-show__accordion" />
        <ProductQuestion class="product-show__question" />
      </div>

      <div class="product-show__purchase">
        <div class="product-show__heading product-show__heading--prices">
          <div class="product-show__prices">
            <ProductPrices />
          </div>
        </div>

        <ProductOptions :product="product" :selection="selection" class="product-show__options" />

        <ProductChain v-if="chain.enabled && chain.chainProduct.value" class="product-show__options" />

        <ProductEngravingOutside
          v-if="outsideEngraving?.enabled && outsideEngraving?.engravingProduct?.value"
        />

        <ProductEngravingInside
          v-if="insideEngraving?.enabled && insideEngraving?.engravingProduct?.value"
        />

        <ProductStockNote />

        <ProductBuy />

        <ProductNotify />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useProductInject } from '../../context/useProductContext'

import ProductEngravingOutside from './engraving/ProductEngravingOutside.vue'
import ProductEngravingInside from './engraving/ProductEngravingInside.vue'
import ProductTitle from './ProductTitle.vue'
import ProductAccordion from './ProductAccordion.vue'
import ProductSlideshow from './ProductSlideshow.vue'
import ProductPrices from './ProductPrices.vue'
import ProductQuestion from './ProductQuestion.vue'
import ProductOptions from './ProductOptions.vue'
import ProductStockNote from './ProductStockNote.vue'
import ProductChain from './chain/ProductChain.vue'
import ProductNotify from './ProductNotify.vue'
import ProductBuy from './ProductBuy.vue'
import ProductInfluences from './ProductInfluences.vue'

const { product, chain, insideEngraving, outsideEngraving, selection } = useProductInject()
</script>

<style lang="scss">
@import '~styles/base';

.product-show {
  @include breakpoint('l+') {
    @include wrapper;
  }

  @include breakpoint('l') {
    margin-bottom: 100px;
  }

  &__main {
    display: grid;
    grid-template-columns: columns(3) columns(10) columns(6) columns(5);
    gap: 0 var(--gutter);

    @include breakpoint('l') {
      grid-template-columns: 1fr;
    }
  }

  &__heading {
    @include breakpoint('l+') {
      min-height: 120px;
      padding-bottom: 20px;
    }
    @include breakpoint('l') {
      @include wrapper;
      padding: 20px 0 30px;
    }

    &--prices {
      @include breakpoint('l') {
        display: none;
      }
    }
  }

  &__prices {
    @include breakpoint('l+') {
      display: flex;
      height: 30px;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  &__content {
    padding-right: 20px;

    @include breakpoint('l') {
      display: contents;
    }
  }

  &__accordion {
    @include breakpoint('l') {
      @include wrapper;
      order: 4;
      margin-top: 40px;
    }
  }

  &__question {
    @include breakpoint('l') {
      @include wrapper;
      order: 5;
      margin-top: 40px;
      margin-right: auto;
    }
  }

  &__options {
    + * {
      border-top: 1px solid var(--color-lightgrey);
      padding-top: 15px;
      margin-top: 15px;
    }
  }

  &__purchase {
    @include breakpoint('l') {
      @include wrapper;
    }
  }
}
</style>
