<template>
  <CartItemProduct :line="line" v-if="isProduct" />
  <CartItemAddition :line="line" v-else />
</template>

<script lang="ts" setup>
import { LiquidLineItem } from '../../types/liquid'
import CartItemProduct from './CartItemProduct.vue'
import CartItemAddition from './CartItemAddition.vue'
import { computed } from 'vue'

const props = defineProps<{
  line: LiquidLineItem
}>()

const isProduct = computed(() => !props.line.properties.Product)
</script>

<style lang="scss">
@import '~styles/base.scss';

.cart-line {
}
</style>
