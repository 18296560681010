<template>
  <form class="cart-gift" @submit.prevent="onSubmit">
    <div class="cart-gift__heading">
      <CheckboxInput
        @change="toggleShowMessage"
        :value="showMessage"
        id="cart-gift"
        name="cart-gift"
        class="cart-gift__checkbox"
      >
        This is a gift
      </CheckboxInput>

      <transition name="fade" mode="out-in">
        <span :key="status" class="cart-gift__status">
          {{ status }}
        </span>
      </transition>

      <transition name="fade">
        <button
          v-show="showMessage"
          type="button"
          class="cart-gift__show"
          @click="showMessage = !showMessage"
        >
          <transition name="fade" mode="out-in">
            <span v-text="showMessage ? 'Hide' : 'Show'" />
          </transition>
          message
        </button>
      </transition>
    </div>

    <div v-if="showMessage" class="cart-gift__message">
      <textarea v-model="message" placeholder="Message" class="cart-gift__textarea" />
      <button type="submit" class="cart-gift__button">Save</button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { watch, ref } from 'vue'
import { useCartInject } from '../../../context/useCartContext'
import CheckboxInput from '../../CheckboxInput.vue'

const { cart, updateCart } = useCartInject()

const showMessage = ref(!!cart.value.attributes?.['Gift Message'])
const status = ref('')

const isGift = ref(cart.value.attributes?.['Gift'] == 'Yes')
const message = ref(cart.value.attributes?.['Gift Message'])

const toggleShowMessage = () => {
  if (message.value) return
  showMessage.value = !showMessage.value
}

const onSubmit = async () => {
  await updateCart({
    attributes: {
      Gift: `${isGift.value ? 'Yes' : 'No'}`,
      'Gift Message': message.value,
    },
  })

  status.value = 'Saved'
  setTimeout(() => (status.value = ''), 2000)
}

watch([cart], () => {
  isGift.value = cart.value.attributes['Gift'] == 'Yes'
  message.value = cart.value.attributes['Gift Message']
})
</script>

<style lang="scss">
.cart-gift {
  overflow: hidden;
  padding: 10px;
  border-top: 1px solid;

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.175s;
  }

  &__heading {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__message {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }

  &__textarea {
    margin-bottom: 5px;
    padding: 5px;
    font-family: inherit;
    resize: none;
  }

  &__button {
    font-size: inherit;
    margin-right: auto;
  }

  &__status {
    color: var(--color-muted);
    margin-left: 1ch;
  }

  &__show {
    margin-left: auto;
  }
}
</style>
