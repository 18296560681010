<template>
  <span>
    <a class="shopify-link__text" v-if="isLoggedIn" href="/account"> Account </a>
    <a class="shopify-link__text" v-else href="/account/login" @click.prevent="setOverlay('ACCOUNT')">
      Login
    </a>
  </span>
</template>

<script lang="ts" setup>
import { useAppInject } from '../../../context/useAppContext'
import { useOverlayInject } from '../../../context/useOverlayContext'

const { isLoggedIn } = useAppInject()
const { setOverlay } = useOverlayInject()

withDefaults(
  defineProps<{
    depth: number
  }>(),
  {
    depth: 0,
  }
)
</script>

<style lang="scss"></style>
