<template>
  <FormWrap class="form-reset" title="Update Password" :error="error" @submit="onSubmit">
    <InputPassword
      class="form-reset__input form-reset__input--password"
      name="password"
      placeholder="Password"
      required="true"
    />

    <InputPassword
      class="form-reset__input form-reset__input--password"
      name="confirmPassword"
      placeholder="ConfirmPassword"
      required="true"
    />
    <InputButton type="submit" class="form-reset__button" title="Submit" />
  </FormWrap>
</template>

<script lang="ts" setup>
import FormWrap from './FormWrap.vue'
import InputButton from '../input/InputButton.vue'
import { submitResetPassword } from '../../services/shopify/account'
import { ref } from 'vue'
import InputPassword from '../input/InputPassword.vue'

const error = ref('')

const onSubmit = async (e: SubmitEvent) => {
  const formData = new FormData(e.target as HTMLFormElement)
  error.value = ''
  try {
    const response = await submitResetPassword({
      'customer[password]': formData.get('password') as string,
      'customer[confirm_password]': formData.get('confirmPassword') as string,
    })

    // alert('Please check your email to finish resetting your password')
    window.location.href = '/account/login'
  } catch (e: any) {
    if (e.code == 'ERR_NETWORK') window.location.href = '/account/login'
    error.value = 'There was an error, please check your details and try again'
  }
}
</script>

<style lang="scss">
@import '~styles/base.scss';
.form-reset {
  &__button {
    /* margin-top: 20px; */
  }

  &__label {
    /* margin-bottom: 20px; */
  }

  &__input {
    margin-bottom: 20px;
  }
}
</style>
