<template>
  <div class="collection-thumbnails-slide" v-if="slide">
    <a
      :href="`/products/${slide.product.handle}`"
      :aria-label="slide.product.title"
      class="collection-thumbnails-slide__inner"
    >
      <span class="collection-thumbnails-slide__number">({{ slide.number }})</span>
      <CommonImage :alt="`${slide.alt}`" :src="slide.src" />
      <div>
        <h3>
          <ProductCaptionTitle :title="slide.product.title" />
        </h3>
        <ProductPrices class="collection-thumbnails-slide__prices" />
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import CommonImage from '../../common/CommonImage.vue'
import ProductCaptionTitle from '../../product/card/ProductCardTitle.vue'
import ProductPrices from '../../product/ProductPrices.vue'
// import { LiquidProduct } from '../../../types/liquid'
import { useProductContext } from '../../../context/useProductContext'
import { CollectionPropType } from './CollectionThumbnails.vue'
import { ElementOf } from '../../../types/utils'

const props = defineProps<{
  slide: ElementOf<CollectionPropType['slides']>
}>()

useProductContext({ product: props.slide.product })
</script>

<style lang="scss">
@import '~styles/base.scss';

.collection-thumbnails-slide {
  position: relative;
  // Protect the outline from overflow
  padding-top: 3px;

  &__inner {
    display: grid;
    row-gap: 8px;
  }

  &__number {
    position: absolute;
    top: 0;
    right: calc(100% + var(--slide-inset));
    letter-spacing: 0.06em;
  }

  img {
    outline: 1px solid transparent;
    outline-offset: 2px;
    transition: outline-color 150ms ease-out;
  }

  @media (hover: hover) {
    &__prices {
      opacity: 0;
      transition: opacity 150ms ease 100ms;
    }

    &:focus,
    &:hover {
      img {
        outline-color: currentColor;
      }

      .collection-thumbnails-slide__prices {
        opacity: 1;
      }
    }
  }
}
</style>
