<template>
  <main
    :class="`layout-container layout-container--overlay-${current ? 'active' : 'inactive'}`"
    :style="`--footer-height: ${footerHeight}px`"
  >
    <slot />
  </main>
</template>

<script lang="ts" setup>
import { useAppInject } from '../../context/useAppContext'
import { useOverlayInject } from '../../context/useOverlayContext'

const { footerHeight } = useAppInject()
const { current } = useOverlayInject()
</script>

<style lang="scss">
@import '~styles/base.scss';

.layout-container {
  --header-offset: 130px;
  min-height: calc(100vh - var(--footer-height) - var(--header-offset));
  position: relative;
  padding-top: var(--header-offset);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  @include breakpoint(l) {
    --header-offset: 100px;
  }

  .shopify-section {
    display: contents;
  }
}
</style>
