<template>
  <div class="collection-thumbnails-carousel">
    <div>
      <Swiper
        ref="carousel"
        @swiper="onSwiper"
        v-bind="{ ...swiperOptions }"
        :auto-update="false"
        :delete-instance-on-destroy="false"
        :cleanup-styles-on-destroy="false"
        class="collection-thumbnails-carousel__swiper"
      >
        <SwiperSlide v-for="(slide, k) in slides" :key="`global_carousel_${k}`">
          <CollectionThumbnailsSlide :slide="slide" class="collection-thumbnails-carousel__slide" />
        </SwiperSlide>
      </Swiper>
    </div>
    <slot name="button" />
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { LiquidCollection } from '../../../types/liquid'
import { CollectionPropType } from './CollectionThumbnails.vue'
import CollectionThumbnailsSlide from './CollectionThumbnailsSlide.vue'
import { computed, ref } from 'vue'
import 'swiper/css'

// SwiperClass.use([Mousewheel])

const props = defineProps<{
  collection: CollectionPropType
}>()

const swiperOptions = {
  grabCursor: true,
  breakpoints: {
    100: {
      slidesPerView: 'auto', //1.5,
      spaceBetween: 0,
      centeredSlides: false,
    },
    // when window width is >= 820
    820: {
      freeMode: true,
      freeModeMomentumRatio: 0.75,
      freeModeMomentumBounceRatio: 0.01,
      freeModeSticky: false,
      spaceBetween: 0,
      slidesPerView: 'auto',
      // centeredSlides: true,
      // slidesPerView: 'auto',
    },
  },
}

const slides = computed(() => props.collection?.slides || [])

const swiperRef = ref(null)

const onSwiper = (swiper: any) => {
  swiperRef.value = swiper
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.collection-thumbnails-carousel {
  --slide-inset: max(#{grid-columns(0.5, -1)}, 20px);

  position: relative;
  height: 100%;

  /* Styles have to be in here, seems the slide can't take a class name */
  .swiper-slide {
    --column-gap: max(#{grid-columns(1, 1)}, 55px);
    --carousel-inset: max(#{grid-columns(1)}, 75px);

    width: max(grid-columns(2, -1), 130px);
    margin: 0 calc(var(--column-gap) / 2);

    &:first-child {
      margin-left: var(--carousel-inset);
    }

    &:last-child {
      margin-right: var(--carousel-inset);
    }
  }

  .pill-button {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-left: var(--slide-inset);
  }
}
</style>
