<template>
  <button @click="buy" class="product-buy" :class="{ disabled }">
    <transition name="fade" mode="out-in">
      <span v-if="isUpdating" class="product-buy__adding-message"> Adding </span>
      <span v-else class="product-buy__buy-text">
        {{ text }}
        <ShopifyPrice key="text" :amount="priceTotal" class="product-buy__price" />
      </span>
    </transition>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useProductInject } from '../../context/useProductContext'
import ShopifyPrice from '../shopify/ShopifyPrice.vue'
import { useCartInject } from '../../context/useCartContext'
import { useOverlayInject } from '../../context/useOverlayContext'

const { isPolish, selection, priceTotal, isPreorderable, addToCart } = useProductInject()
const { setOverlay } = useOverlayInject()
const { cart, cartStatus } = useCartInject()

const text = computed(() => {
  if (cartStatus.value == 'ADDING') return 'Addng'
  if (!selection.selectedVariant.value.availableForSale) return 'Sold Out'
  if (isPreorderable) return 'Pre-Order'
  return 'Add to Cart'
})

const isUpdating = false
const disabled = false

const buy = async () => {
  if (isPolish) {
    const cartItems = cart.value.items?.filter((item: any) => item.product_type == 'Nailpolish')
    const cartPolishSum = cartItems?.reduce((acc: number, item: any) => acc + item.quantity, 0)

    if (cartPolishSum + 1 > 30) {
      setOverlay('LIMIT')
      return
    }
  }

  await addToCart()
  setOverlay('CART')
}
</script>

<style lang="scss">
.product-buy {
  container-type: inline-size;
  align-items: center;
  border: 1px solid;
  color: inherit;
  display: flex;
  height: 35px;
  justify-content: center;
  letter-spacing: 0.01em;
  line-height: 1.1;
  padding: 1px 10px 0;
  text-transform: uppercase;
  transition: all 0.3s ease;
  width: 100%;

  &__price {
    margin-left: 8px;
    min-width: 5ch;
    padding-left: 8px;
    position: relative;
    &:before {
      border-right: 1px solid;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: -2px;
    }
  }

  @container (max-width: 160px) {
    &__price {
      padding-left: 0;
      margin-left: 0;
      &:before {
        display: none;
      }
    }
  }
}
</style>
