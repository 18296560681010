<template>
  <div class="collection-featured">
    <section class="collection-featured__introduction">
      <div>
        <TabFeatureButton :active="true" tag="span" class="collection-featured__subtitle">{{
          metafields?.subtitle || collection.title
        }}</TabFeatureButton>
        <DisplayContent class="collection-featured__title"
          ><h1>{{ collection.title }}</h1></DisplayContent
        >
        <div class="collection-featured__description" v-html="collection.body_html"></div>
        <PillButton tag="a" href="#products" v-scroll-to="'#products'">{{
          metafields?.call_to_action || 'SHOP the EDIT'
        }}</PillButton>
      </div>
      <CommonImage class="collection-featured__image" :src="image" alt="" />
    </section>
    <div id="collection-featured__products">
      <CollectionProducts :assets="metafields?.assets" :handle="collection.handle" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { LiquidCollection } from '../../types/liquid'

import CollectionProducts from '../collection/CollectionProducts.vue'
import TabFeatureButton from '../tab-feature/TabFeatureButton.vue'
import DisplayContent from '../sanity/SanityContentDisplay.vue'
import CommonImage from '../common/CommonImage.vue'
import PillButton from '../PillButton.vue'

const props = withDefaults(
  defineProps<{
    collection: LiquidCollection
    image: string
  }>(),
  {
    image: '',
  }
)

const metafields = computed(() => props.collection?.metafields?.featured)
</script>

<style lang="scss" scoped>
@import '~styles/base';

.collection-featured {
  &__introduction {
    grid-template-columns: 9fr 3fr 9fr;
    align-items: flex-start;
    display: grid;

    padding-left: columns(4, 0);
    padding-right: columns(3, 0);
    margin-left: var(--inset);
    margin-right: var(--inset);
    margin-bottom: 100px;

    @include breakpoint('m') {
      padding-left: 0;
      padding-right: 0;
      grid-template-columns: 1fr;
      row-gap: 50px;
    }
  }

  &__title {
    margin-bottom: auto;
  }

  &__subtitle {
    transform: translateY(-100%);
    position: absolute;
    z-index: 1;
    left: -1px;
    top: 0;
  }

  &__description {
    margin: 20px 0;
  }

  &__image {
    grid-column: 3;

    @include breakpoint('m') {
      display: none;
      grid-column: 1;
    }
  }

  #products {
    margin-top: 180px;

    @include breakpoint('m') {
      margin-top: 0;
    }
  }
}
</style>
