import { ProductFieldsFragment } from '_library/src/storefront/generated'

export const useProductRedirection = (product: ProductFieldsFragment) => {
  // redirection functionality
  const enabled = product.redirectionEnabled?.value
  const target = product.redirectionTarget?.value

  if (enabled && target) {
    // On product page
    window.location.replace(target)
  }
}
