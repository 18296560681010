<template>
  <div class="overlay-cart-empty" v-if="itemCount < 1">There are no items in your cart yet!</div>
</template>

<script lang="ts" setup>
import { useCartInject } from '../../../context/useCartContext'

const { itemCount } = useCartInject()
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-cart-empty {
  flex: 1;
  padding-top: 160px;
  text-align: center;
}
</style>
