<template>
  <div class="product-title">
    <h1 class="product-title__title" v-if="titleArray.length > 1">
      {{ titleArray[0] }}<br />
      <span>({{ titleArray[1] }}</span>
    </h1>

    <h1 v-else class="product-title__title">
      {{ product.title }}
    </h1>
    <ProductPrices class="product-title__prices" />
  </div>
</template>

<script lang="ts" setup>
import { useProductInject } from '../../context/useProductContext'
import ProductPrices from './ProductPrices.vue'

const { product } = useProductInject()

const titleArray = product.title.split('(')
</script>

<style lang="scss">
@import '~styles/base';

.product-title {
  @include breakpoint('l') {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gutter);
  }

  &__title {
    @include serif;
    font-size: 28px;
    line-height: 32px;
  }

  &__prices {
    @include breakpoint('l+') {
      display: none;
    }
  }
}
</style>
