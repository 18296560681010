<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 14.2 13.9"
    style="enable-background: new 0 0 14.2 13.9"
    xml:space="preserve"
  >
    <path
      d="M13.6,12.5l-0.2-0.2l0,0l-3.1-3.1c0.9-1.1,1.3-2.5,1.2-3.9c-0.1-1.5-0.8-2.8-2-3.8C8.4,0.7,7,0.2,5.5,0.4
    C4,0.5,2.7,1.2,1.7,2.3c-1,1.1-1.4,2.6-1.3,4.1c0.1,1.5,0.8,2.8,2,3.8c1,0.8,2.3,1.3,3.6,1.3c0,0,0,0,0.1,0c1.2,0,2.4-0.4,3.4-1.2
    l3.2,3.1c0.1,0.1,0.3,0.2,0.4,0.2c0,0,0,0,0,0c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4C13.8,12.8,13.7,12.7,13.6,12.5z
    M10.5,5.9c0,2.5-2,4.5-4.5,4.5c-2.5,0-4.5-2-4.5-4.5v0c0-1.2,0.5-2.3,1.3-3.2c0.8-0.8,2-1.3,3.2-1.3c0,0,0,0,0,0
    C8.5,1.4,10.5,3.4,10.5,5.9z"
    />
  </svg>
</template>

<script lang="ts" setup></script>
<style scoped>
svg {
  width: 12px;
  transform: translateY(1px);
}
</style>
