<template>
  <div class="product-card-gallery">
    <ShopifyMedia class="media" :ratio="ratio" :media="image" />
    <ShopifyMedia class="media" v-if="hoverImage" :ratio="ratio" :media="hoverImage" />
  </div>
</template>

<script lang="ts" setup>
import { MediaImage } from '_library/src/storefront/generated'
import ShopifyMedia from '../../shopify/ShopifyMedia.vue'
import { computed } from 'vue'

const props = defineProps<{
  media: MediaImage[]
}>()

const image = computed(() => props.media?.[0])
const hoverImage = computed(() => props.media?.[1])

const ratio = '450x615'
</script>

<style lang="scss">
.product-card-gallery {
  justify-content: flex-start;
  display: flex;
  width: 100%;
  margin: 0;

  > * {
    flex: 0 0 100%;
    transition: var(--transition-opacity);

    &:last-child:not(:only-child) {
      transform: translateX(-100%);
      outline: 1px solid currentColor;
      outline-offset: 5px;
      object-fit: cover;
      opacity: 0;
    }
  }
}

@media (hover: hover) {
  *:hover,
  *:focus {
    > .product-card-gallery > :last-child {
      opacity: 1;
    }
  }
}
</style>
