<template>
  <FormWrap
    class="form-register"
    title="Create Account"
    :error="error"
    @submit="register"
    action="/account"
  >
    <input type="hidden" name="customer[tags]" value="newsletter" />
    <InputText
      class="form-register__input form-register__input--text"
      name="firstName"
      placeholder="First Name"
      required="true"
    />

    <InputText
      class="form-register__input form-register__input--text"
      name="lastName"
      placeholder="Last Name"
      required="true"
    />

    <InputEmail
      class="form-register__input form-register__input--email"
      name="email"
      placeholder="Email Address"
      required="true"
    />

    <InputPassword
      class="form-register__input form-register__input--password"
      name="password"
      placeholder="Password"
      required="true"
    />

    <InputCheckbox
      class="form-register__input form-register__input--checkbox"
      name="accepts_marketing"
      title="Sign up to our newsletter"
    />
    <InputButton type="submit" class="form-register__button" title="Submit" />
  </FormWrap>
</template>

<script lang="ts" setup>
import InputEmail from '../input/InputEmail.vue'
import InputPassword from '../input/InputPassword.vue'
import InputButton from '../input/InputButton.vue'
import FormWrap from './FormWrap.vue'
import InputCheckbox from '../input/InputCheckbox.vue'
import InputText from '../input/InputText.vue'
import { submitRegister } from '../../services/shopify/account'
import { computed, ref } from 'vue'
// import { RegisterSubscribe } from '~/lib/klaviyo.ts'

const error = ref('')

const register = async (e: SubmitEvent) => {
  error.value = ''
  // Klaviyo subscription
  // if (this.acceptsMarketing)
  // await RegisterSubscribe({
  //   firstName: this.firstName,
  //   lastName: this.lastName,
  //   email: this.email,
  // })

  const formData = new FormData(e.target as HTMLFormElement)

  const params = {
    'customer[first_name]': formData.get('firstName') as string,
    'customer[last_name]': formData.get('lastName') as string,
    'customer[email]': formData.get('email') as string,
    'customer[password]': formData.get('password') as string,
    'customer[accepts_marketing]': formData.get('accepts_marketing') === 'on',
  }

  try {
    await submitRegister(params)

    alert('Registration successful! Please check your email to finish activating your account')
    window.location.href = '/account/login'
  } catch (e: any) {
    // console.log(e)
    // this.error = 'There was an error, please check your details and try again'
    if (e.code == 'ERR_NETWORK') window.location.href = '/account/register'
  }
  return true
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.form-register {
  &__button {
    /* margin-top: 20px; */
  }

  &__label {
    /* margin-bottom: 20px; */
  }

  &__input {
    margin-bottom: 20px;
  }
}
</style>
