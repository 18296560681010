<template>
  <div class="ProductPrices">
    <template v-if="priceMin < priceMax">
      From <ShopifyPrice unit="dollars" :amount="priceMin" />
      <template v-if="showUpperRange">
        —
        <ShopifyPrice unit="dollars" :amount="priceMax" />
      </template>
    </template>
    <ShopifyPrice v-else unit="dollars" :amount="priceMin" />
  </div>
</template>

<script lang="ts" setup>
import { useProductInject } from '../../context/useProductContext'
import ShopifyPrice from '../shopify/ShopifyPrice.vue'
const { priceMin, priceMax } = useProductInject()

const props = withDefaults(
  defineProps<{
    available?: boolean
    showUpperRange?: boolean
  }>(),
  {
    available: true,
    showUpperRange: true,
  }
)
</script>
