<template>
  <div class="instock-variant">
    <div
      class="instock-variant__group"
      v-for="optionGroup in variantOptions.options"
      :key="generateKey(optionGroup)"
    >
      <ul class="instock-variant__options">
        <li v-for="option in optionGroup" class="instock-variant__option" :key="generateKey(option)">
          {{ option }}
        </li>
      </ul>
    </div>
    <ShopifyPrice class="instock-variant__price" :amount="variantOptions.prices[0] / 100" />
  </div>
</template>

<script lang="ts" setup>
import { ElementOf } from '../../types/utils'
import ShopifyPrice from '../shopify/ShopifyPrice.vue'
import { CollectionInStockGroupType } from './CollectionInStock.vue'

defineProps<{
  variantOptions: ElementOf<ElementOf<CollectionInStockGroupType['products']>['groupedOptions']>
}>()

const generateKey = (obj: any) => {
  const str = JSON.stringify(obj)
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash).toString(36).substring(0, 6)
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.instock-variant {
  display: flex;
  gap: var(--gutter);

  &__group {
    flex: 1;

    &:first-child {
      @include uppercase;
    }
  }

  &__options {
    display: flex;
    flex-wrap: wrap;

    @include breakpoint('l') {
      /* flex-direction: column; */
    }
  }

  &__option {
    &:after {
      content: ', ';
      white-space: pre;
    }

    &:last-child:after {
      content: none;
    }

    @include breakpoint(m) {
      text-align: left;
      white-space: pre;
      width: auto;
      /* flex: 0.15; */
    }
  }

  &__price {
    @include breakpoint('m+') {
      width: columns(3);
    }
    @include breakpoint('m') {
      width: columns(2);
      margin-right: 10px;
    }
  }
}
</style>
