<template>
<ProductEngraving 
  :engraving="insideEngraving"
/>
</template>

<script lang="ts" setup>
import { useProductInject } from "../../../context/useProductContext";
import ProductEngraving from "./ProductEngraving.vue"
const {insideEngraving} = useProductInject();
</script>