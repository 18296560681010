<template>
  <div class="input-select">
    <div class="input-select__arrow">
      <SvgArrowDown />
    </div>
    <select class="input-select__input" :name="name" :value="value" @change="onChange">
      <option
        v-for="(ov, k) in options"
        :key="`option_${k}`"
        :value="ov.value"
        :selected="ov.value === selected.value"
        v-text="ov.name"
      />
    </select>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import SvgArrowDown from '../svg/SvgArrowDown.vue'

const props = defineProps<{
  name: string
  value: string
  options: { name: string; value: string }[]
}>()

const emits = defineEmits(['input'])

const onChange = (event: Event) => {
  emits('input', (event.target as HTMLInputElement).value)
}

const selected = computed(() => {
  const selected = props.options.find((v) => v.value === props.value)
  return selected || props.options[0]
})
</script>

<style lang="scss">
@import '~styles/base.scss';

.input-select {
  position: relative;
  display: flex;

  &__input {
    width: 100%;
    appearance: none;
    border: 1px solid var(--color-dark);
    padding: 4px 6px;
    cursor: pointer;
    background: transparent;
  }

  &__arrow {
    position: absolute;
    right: 5px;
    top: 10px;
    width: 10px;
    pointer-events: none;
  }
}
</style>
