/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import stripJsonComments from 'strip-json-comments'

// Define a type for the formType parameter
type FormType = string

async function getRecaptchaToken(formType: FormType): Promise<string> {
  return new Promise((res, rej) => {
    try {
      window?.grecaptcha.ready(function () {
        window?.grecaptcha
          .execute(window.Shopify?.recaptchaV3?.siteKey, { action: formType })
          .then((token: string) => res(token))
      })
    } catch (e) {
      rej(e)
    }
  })
}

// Define types for axios settings
const axiosGetSettings: AxiosRequestConfig = {
  method: 'GET',
  responseType: 'json',
}

const axiosPostSettings: AxiosRequestConfig = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
}

export const getRequest = async (url: string, options: AxiosRequestConfig = {}): Promise<any> => {
  const response: AxiosResponse = await axios.request({
    ...axiosGetSettings,
    ...options,
    url,
  })

  if (options.responseType === 'stream') return response

  return typeof response.data == 'string' ? JSON.parse(stripJsonComments(response.data)) : response.data
}

export const postRequest = async (
  url: string,
  { data }: { data: any },
  options: AxiosRequestConfig = {}
): Promise<any> => {
  const response: AxiosResponse = await axios.request({
    ...axiosPostSettings,
    ...options,
    data,
    url,
  })

  return response.data
}

// Define a type for the data parameter
interface Data {
  [key: string]: any
}

export async function formatParams(
  url: string,
  data: Data,
  formType: FormType,
  _method = ''
): Promise<Data> {
  const params: Data = {
    utf8: '✓',
    form_type: formType,
  }

  if (_method) {
    params._method = _method
  }

  // Add attributes
  for (const key in data) {
    if ({}.hasOwnProperty.call(data, key)) {
      if (data[key] && data[key] !== '') {
        params[key] = data[key]
      }
    }
  }

  // Add token to submission
  // const token = await getRecaptchaToken(formType)
  // params['recaptcha-v3-token'] = token

  return params
}

export const formRequest = async function (path: string, params: Data, _method = ''): Promise<any> {
  // const response: AxiosResponse = await submitRequest(url, data)

  // if (response.request.responseURL.indexOf('/challenge') > -1) {
  //   window.location.href = '/challenge'
  // }

  // Create a form element
  const form = document.createElement('form')
  form.setAttribute('method', _method.toUpperCase() || 'POST')
  form.setAttribute('action', path)

  // Create hidden form inputs for each parameter and append them to the form
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      if (params[key] && params[key] !== '') {
        const input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', params[key])
        form.appendChild(input)
      }
    }
  }

  // Append the form to the body and submit it
  document.body.appendChild(form)
  form.submit()
}
