import { provide, inject, Ref, ref, computed, onMounted } from 'vue'
import { DEFAULT_COUNTRY_CODE, ProductFieldsFragment, fetchCollectionWithProducts } from '_library'
import { chunk } from 'lodash'

export type CollectionContextProps = {
  handle: string
  assets?: any
}

export type CollectionContextType = {
  filteredProducts: Ref<ProductFieldsFragment[]>
  collectionTiles: any
  loaded: Ref<boolean>
  isLoading: Ref<boolean>
  loadNextPage: () => Promise<void>
}

export const COLLECTION_INJECTION_KEY = Symbol('COLLECTION')

export const useCollectionContext = ({ handle, assets = [] }: CollectionContextProps) => {
  const loaded = ref(false)
  const products: Ref<ProductFieldsFragment[]> = ref([])
  const hasNextPage = ref(true)
  const currentCursor = ref<string | undefined>(undefined)
  const isLoading = ref(false)

  const filteredProducts = computed(() =>
    products.value.filter((product) => product.productType !== 'Admin')
  )

  const chunkedAssets = computed(() => chunk(assets, 2))
  const chunkedProducts = computed(() => [
    chunk(filteredProducts.value.slice(0, 2), 2),
    chunk(filteredProducts.value.slice(1, -1), 4),
  ])

  const collectionTiles = computed(() => {
    if (!chunkedAssets.value.length) return filteredProducts.value
    return chunkedAssets.value.reduce((items: any, assetChunk: any, i: number) => {
      return [
        ...items,
        ...(chunkedProducts.value[i].flat() || []),
        ...assetChunk,
        ...(i + 1 === chunkedAssets.value.length ? chunkedProducts.value.slice(i, -1).flat() : []),
      ]
    }, [])
  })

  const loadNextPage = async () => {
    if (!hasNextPage.value) return
    if (isLoading.value) return
    isLoading.value = true
    const collection = await fetchCollectionWithProducts({
      handle,
      countryCode: DEFAULT_COUNTRY_CODE as any,
      productCursor: currentCursor.value,
      productLimit: 24,
    })

    products.value = [...products.value, ...collection.products]
    hasNextPage.value = collection.hasNextPage || false
    currentCursor.value = collection.lastItemCursor || undefined
    isLoading.value = false
  }

  onMounted(async () => {
    await loadNextPage()
    loaded.value = true
  })

  const values = {
    loaded,
    isLoading,
    collectionTiles,
    filteredProducts,
    loadNextPage,
  }

  provide<CollectionContextType>(COLLECTION_INJECTION_KEY, values)
  return values as CollectionContextType
}

export const useCollectionInject = () => {
  const context = inject<CollectionContextType>(COLLECTION_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
