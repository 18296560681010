<template>
  <div class="product-chain">
    <ProductChainHeading
      :title="chain.title"
      :is-active="chain.isActive.value"
      @set-is-active="chain.setIsActive"
    />

    <div v-if="chain.isActive.value">
      <ProductOptions
        v-if="chain.chainProduct.value"
        :product="chain.chainProduct.value"
        :selection="chain.selection"
        prefix="Chain"
        class="product-chain__options"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useProductInject } from '../../../context/useProductContext'
import ProductChainHeading from '../chain/ProductChainHeading.vue'
import ProductOptions from '../ProductOptions.vue'

const { chain } = useProductInject()
</script>

<style lang="scss">
@import '~styles/base';

.product-chain {
  --input-spacing-y: 10px;
  margin-bottom: var(--product-option-spacing);

  @include breakpoint('l') {
    padding: 0;
  }

  &__options {
    padding-top: 20px;
  }
}
</style>
