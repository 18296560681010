import { fetchWithCacheClient } from "../../utils";
import { parseGid } from "@shopify/admin-graphql-api-utilities";
import { rateLimitRequests } from "../client";
import { ShopifySdk } from "..";

// Main product fetch
export async function fetchProductByGid(
  shopifyId: string,
  { countryCode }: any
) {
  const fetcher = () => ShopifySdk.productById({ id: shopifyId, countryCode });
  const response = await fetchWithCacheClient(
    `/${countryCode}/shopifyProducts/${parseGid(shopifyId)}`,
    fetcher,
    2
  );
  return response.product;
}

// Collection products
export async function simpleProductsByGids(
  shopifyIds: string[],
  { countryCode }: any
) {
  const promises = shopifyIds.map(async (shopifyId) => {
    const fetcher = () =>
      ShopifySdk.simpleProductById({ id: shopifyId, countryCode });
    const response = await fetchWithCacheClient(
      `/${countryCode}/simpleShopifyProducts/${shopifyId}`,
      fetcher,
      2
    );
    return response.product;
  });

  const products = await rateLimitRequests(promises);

  return products.filter((product) => !!product);
}

export async function fetchProductByHandle(
  handle: string,
  { countryCode }: any
) {
  const fetcher = () => ShopifySdk.productByHandle({ handle, countryCode });
  const response = await fetchWithCacheClient(
    `/${countryCode}/shopifyProducts/${parseGid(handle)}`,
    fetcher,
    2
  );

  return response.productByHandle;
}
