<template>
  <section class="page-product-no-sale">This product is hidden.</section>
</template>

<script lang="ts" setup></script>

<style lang="scss">
@import '~styles/base.scss';

.page-product-no-sale {
  text-align: center;
  margin-top: 200px;
}
</style>
