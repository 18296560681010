<template>
  <div :class="`product-engraving ${className}`">
    <ProductEngravingHeading
      :title="engraving.title"
      :is-active="engraving.isActive.value"
      :variant="engraving.selection.value.selectedVariant"
      @set-is-active="engraving.setIsActive"
    />

    <div v-if="engraving.isActive.value">
      <ProductOptions
        v-if="engraving.engravingProduct.value"
        :product="engraving.engravingProduct.value"
        :parent-selection="selection"
        :selection="engraving.selection"
        class="product-chain__options"
      />

      <ProductEngravingCharacters
        class="product-engraving__characters"
        :characters="engraving.characters"
        :add-character="engraving.addCharacter"
      />

      <div class="product-engraving__note">({{ engraving.characterLimit }} characters max)</div>

      <ProductEngravingText
        :text="engraving.selectedCharacters.value"
        class="product-engraving__text"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ProductEngravingType } from '../../../composables/useProductEngraving'
import ProductEngravingHeading from './ProductEngravingHeading.vue'
import ProductOptions from '../ProductOptions.vue'
import ProductEngravingCharacters from './ProductEngravingCharacters.vue'
import ProductEngravingText from './ProductEngravingText.vue'
import { useProductInject } from '../../../context/useProductContext'
import { computed } from 'vue'

const { selection } = useProductInject()

const props = defineProps<{
  engraving: ProductEngravingType
}>()

const className = computed(
  () => `product-engraving--font-${props.engraving.selectedFont.value?.toLowerCase()}`
)
</script>

<style lang="scss">
.product-engraving {
  // max-width: 240px;

  &__note {
    margin-top: 8px;
    color: var(--color-muted);
  }

  &--font-gothic & {
    &__characters,
    &__text {
      font-family: var(--font-gothic);
    }
  }

  &--font-script & {
    &__characters,
    &__text {
      font-family: var(--font-script);
    }
  }

  &--font-serif & {
    &__characters,
    &__text {
      font-family: var(--font-serif);
    }
  }

  + .product-engraving {
    border-top: 1px solid var(--color-lightgrey);
    padding-top: 15px;
    margin-top: 15px;
  }
}
</style>
