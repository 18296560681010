<template>
  <div class="collection-trigger" ref="el">&nbsp;</div>
</template>

<script lang="ts" setup>
import { ref, watch, watchEffect } from 'vue'
import useIntersection from '../../composables/useIntersection'
import { useCollectionInject } from '../../context/useCollectionContext'

const el = ref(null)
const { isIntersecting } = useIntersection(el)
const { loadNextPage } = useCollectionInject()

watchEffect(() => {
  if (isIntersecting.value) {
    console.log('intersecting')
    loadNextPage()
  }
})
</script>

<style lang="scss">
@import '~styles/base';

.collection-trigger {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  // grid-column: 1 / -1;
}
</style>
