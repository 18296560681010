<template>
  <div
    class="polish-set-product"
    :class="polishSet.remaining.value == 0 && quantity == 0 && 'polish-set-product--disabled'"
  >
    <div class="polish-set-product__heading">
      <div class="polish-set-product__image">
        <button
          class="polish-set-product__select"
          :class="`${quantity > 0 ? 'polish-set-product__select--active' : ''}`"
          @click.prevent="onToggle"
        >
          <CommonImage :src="swatchImage" :alt="product.featured_image.alt" ratio="1x1" />
        </button>
      </div>
      <div class="polish-set-product__adjust" v-show="quantity > 0">
        <button
          class="polish-set-product__button"
          :class="polishSet.remaining.value == 0 && 'polish-set-product__button--disabled'"
          @click.prevent="onAdd"
        >
          +</button
        ><button class="polish-set-product__button" @click.prevent="onRemove">–</button>
        <span>{{ quantity }}</span>
      </div>
    </div>
    <div class="polish-set-product__title">
      {{ product.title.replace(' - Unboxed', '') }}
    </div>
    <div class="polish-set-product__description">
      {{ description }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { usePolishSetInject } from '../../context/usePolishSetContext'
import { LiquidProduct } from '../../types/liquid'
import CommonImage from '../common/CommonImage.vue'

const props = defineProps<{
  product: LiquidProduct
}>()

const polishSet = usePolishSetInject()

const swatchImage = computed(() => {
  const src = props.product.metafields.polish_set.swatch[0].cloudinary_src
  // const image = props.product.images.find((image) => image.alt === 'Swatch')
  return src ? src : props.product.featured_image.src
})

const description = computed(() => {
  return props.product.metafields.polish_set.swatch_description
})

const quantity = computed(() => {
  const selection = polishSet.selection.value.find(
    (selection) => selection.productId === `${props.product.id}`
  )
  return selection ? selection.count : 0
})

const onToggle = () => {
  quantity.value === 0
    ? polishSet.updateVariant(props.product.variants[0], 1)
    : polishSet.updateVariant(props.product.variants[0], -1 * quantity.value)
}

const onAdd = () => {
  polishSet.updateVariant(props.product.variants[0], 1)
}

const onRemove = () => {
  if (quantity.value === 0) return
  polishSet.updateVariant(props.product.variants[0], -1)
}
</script>

<style lang="scss">
.polish-set-product {
  &--disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &__heading {
    display: grid;
    grid-template-columns: 70px 1fr;
    gap: 7px;
    padding-bottom: 5px;
  }

  &__title {
    text-transform: uppercase;
    margin: 5px 0;
  }

  &__description {
    font-size: 10px;
    padding-right: 20px;
  }

  &__select {
    position: relative;
    > * {
      display: flex;
    }

    &:before {
      position: absolute;
      content: ' ';
      left: -3px;
      top: -3px;
      right: -3px;
      bottom: -3px;
      border: 1px solid var(--color-dark);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:focus,
    &:hover,
    &--active {
      &:before {
        opacity: 1;
      }
    }
  }

  &__adjust {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: center;
    span {
      margin-top: auto;
      padding-bottom: 5px;
    }
  }

  &__button {
    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
</style>
