<template>
  <div class="product-caption-title" v-html="splitTitle"></div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },

  computed: {
    splitTitle() {
      return this.title.replace(/(\(.*$)/, '<span class="variant">$1</span>')
    },
  },
}
</script>

<style lang="scss" scoped>
.product-caption-title {
  text-transform: uppercase;
  white-space: pre-line;
}

.product-caption-title ::v-deep .variant {
  display: block;
  text-transform: none;
}
</style>
