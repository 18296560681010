import { ProductFieldsFragment } from "../storefront/generated";
import { ratioAsNumber } from "./images";

type ShopifyImageOptions = {
  modifiers: {
    width: number;
    ratio: string;
  };
};

function constructShopifyImageUrl({
  src,
  width,
  height,
  cropping = "_crop_center",
}: // quality,
{
  src: string;
  width: number;
  height?: number;
  cropping?: "_crop_center";
  // quality?: string;
}) {
  const url = `${src}?`;
  const dotIndex = url.lastIndexOf(".");
  const format = width
    ? `_${Math.ceil(width)}x${height && `${Math.ceil(height)}${cropping}`}`
    : "";

  return [url.slice(0, dotIndex), `${format}`, url.slice(dotIndex)].join("");
}

export const getShopifyImage = (
  src: string,
  { modifiers }: ShopifyImageOptions
) => {
  const finalWidth = Math.min(modifiers.width, 1600);
  const ratio = ratioAsNumber(modifiers.ratio);
  const height = Math.ceil(finalWidth / ratio);

  return {
    url: constructShopifyImageUrl({
      src,
      width: finalWidth,
      height,
      // quality,
    }),
  };
};

export const lineItemsSumByType = (productType) => (lineItems) =>
  lineItems.reduce((sum, item) => {
    return item.product_type === productType ? sum + item.quantity : sum;
  }, 0);

export const findProductTag = (product: ProductFieldsFragment, tag: string) =>
  product.tags.find((_tag) => _tag == tag);

export const findProductTagStartsWith = (
  product: ProductFieldsFragment,
  tag: string
) => product.tags.find((_tag) => _tag.startsWith(tag));
