<template>
  <div class="overlay-cart-heading">
    <CommonClose class="overlay-cart-heading__close" @click="close" />
    <h1 class="overlay-cart-heading__title">Cart ({{ itemCount }} Items)</h1>
  </div>
</template>

<script lang="ts" setup>
import { useCartInject } from '../../../context/useCartContext'
import { useOverlayInject } from '../../../context/useOverlayContext'
import CommonClose from '../../common/CommonClose.vue'

const { itemCount } = useCartInject()
const { close } = useOverlayInject()
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-cart-heading {
  &__title {
    @include text-small;
    @include uppercase;
    margin: 20px 0 20px;
    text-align: center;
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}
</style>
