<template>
  <div class="overlay-account" :class="`${isActive ? 'overlay-account--active' : ''}`" ref="container">
    <div class="overlay-account__heading">
      <h2 class="overlay-account__title">{{ mode }}</h2>
      <CommonClose class="overlay-account__close" @click="close" />
    </div>
    <div class="overlay-account__form">
      <FormLogin v-if="mode == 'Login'" />
      <FormForgot v-else-if="mode == 'Forgot'" />
      <FormRegister v-else-if="mode == 'Register'" />
    </div>

    <div class="overlay-account__footer" v-if="mode == 'Login'">
      <button class="overlay-account__switch" @click="mode = 'Forgot'">Forgot Password?</button>
      <button class="overlay-account__switch" @click="mode = 'Register'">Create Account</button>
    </div>

    <div class="overlay-account__footer" v-if="mode !== 'Login'">
      <button class="overlay-account__switch" @click="mode = 'Login'">Cancel</button>
    </div>
    <OverlayBorderLeft />
  </div>
</template>

<script lang="ts" setup>
import { Ref, computed, ref, watchEffect } from 'vue'
import { useOverlayInject } from '../../context/useOverlayContext'
import FormLogin from '../form/FormLogin.vue'
import FormForgot from '../form/FormForgot.vue'
import FormRegister from '../form/FormRegister.vue'
import CommonClose from '../common/CommonClose.vue'
// import OverlayBorderRight from './OverlayBorderRight.vue'
import OverlayBorderLeft from './OverlayBorderLeft.vue'

type Mode = 'Login' | 'Forgot' | 'Register'

const { current, close } = useOverlayInject()
const container = ref<HTMLElement | null>(null)
const mode: Ref<Mode> = ref('Login')
const isActive = computed(() => current.value == 'ACCOUNT')

watchEffect(() => {
  if (isActive.value) {
    const firstInput = container?.value?.querySelector('input')
    if (firstInput) firstInput.focus()
  }
})
</script>

<style lang="scss">
@import '~styles/base.scss';

.overlay-account {
  @include text-xsmall;
  position: fixed;
  padding: 0;
  background: var(--color-lightbackground);
  color: var(--color-dark);
  overflow-y: auto;

  top: 0;
  height: var(--100vh);
  min-height: auto;
  max-width: 100vw;
  width: var(--drawer-width);
  right: 0;

  &:not(.overlay-account--active) {
    opacity: 0;
    pointer-events: none;
  }

  &__heading {
    @include uppercase;
    display: flex;
    justify-content: space-around;
    padding-top: 30px;
    padding-bottom: 140px;
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    button {
      @include animate-underline;
      @include text-small;
      @include uppercase;
      line-height: 1.3;
      margin-right: auto;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    padding: 40px 20px 0;
    gap: 20px;
  }

  &__switch {
    @include animate-underline;
    @include text-xsmall;
    margin-right: auto;
    color: var(--color-midgrey);
  }
}
</style>
