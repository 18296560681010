<template>
  <form action="/search" :class="`search-form search-form--${mode}`" @submit.prevent="onSubmit">
    <label class="search-form__label">
      <input
        v-model="query"
        type="text"
        name="q"
        placeholder=""
        required
        :class="`search-form__input ${!query.length ? '' : 'search-form__input--empty'}`"
      />
      <span class="search-form__text">Search</span>
    </label>
    <button aria-label="Submit" type="submit" class="search-form__button">
      <SvgSearchIcon />
    </button>
  </form>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import SvgSearchIcon from '../svg/SvgSearchIcon.vue'

const query = ref('')

defineProps<{
  mode?: 'DESKTOP' | 'MOBILE'
}>()

const onSubmit = () => {
  const params = new URLSearchParams({
    type: 'product',
    q: query.value,
  }).toString()

  const _query = `/search?${params} -tag:search-exclude`
  window.location.href = encodeURI(_query)
}
</script>

<style lang="scss">
.search-form {
  display: flex;

  &__input,
  &__label {
    width: 100%;
  }

  &__input {
    padding-right: 5px;
    line-height: inherit;
  }

  &__label {
    position: relative;
    display: flex;
    line-height: 1;
  }

  &__text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
  }

  .search-form__input--empty,
  .search-form__input:focus {
    + .search-form__text {
      opacity: 0;
    }
  }

  &--MOBILE {
    padding: var(--nav-pad);
    padding-left: var(--digit-inset);
    border-bottom: 1px solid;

    .search-form__text {
      text-transform: uppercase;
    }
  }
}
</style>
