<template>
  <div class="polish-set-accordion">
    <ProductAccordionItem title="Details" class="polish-set-accordion__item">
      <div v-html="description"></div>
    </ProductAccordionItem>
    <ProductAccordionItem title="Shipping & Returns" class="polish-set-accordion__item">
      <div v-html="defaultShippingInfo"></div>
    </ProductAccordionItem>

    <button @click="() => setOverlay('QUESTION')" class="polish-set-accordion__ask">
      Ask a question
    </button>
  </div>
</template>

<script lang="ts" setup>
// import SvgArrowDown from '../svg/SvgArrowDown.vue'
import ProductAccordionItem from '../product/ProductAccordionItem.vue'
import { useAccordionContext } from '../../context/useAccordionContext'
import { usePolishSetInject } from '../../context/usePolishSetContext'
import { useOverlayInject } from '../../context/useOverlayContext'
useAccordionContext({
  initial: 'Details',
})

const shopMeta = window?._data?.shop.metafields
const { setOverlay } = useOverlayInject()
const { product } = usePolishSetInject()

const description = product.description.split('<!--split-->')[0]
const defaultShippingInfo = shopMeta.shipping.shipping_info || null
</script>

<style lang="scss">
.polish-set-accordion {
  &__item {
    padding-bottom: 20px;
  }

  &__ask {
    color: var(--color-midgrey);
    text-decoration: underline;
  }
}
</style>
