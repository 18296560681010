<template>
  <div class="tab-feature-tab" :class="{ 'tab-feature-tab--active': active }">
    <div class="tab-feature-tab__content">
      <slot :active="active" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'

const props = defineProps<{
  title: TabFeatureTabType['title']
  id: TabFeatureTabType['id']
}>()

const activeTab = inject('activeTab') as any

const active = computed(() => activeTab.value === props.id)
</script>

<style lang="scss">
.tab-feature-tab {
  width: 100%;
  display: flex;
  grid-area: 1 / 1;
  overflow: hidden;
  border: 1px solid;
  flex-direction: column;
  padding: var(--tab-padding);
  background: var(--color-background);

  &--active {
    z-index: 10;
  }

  &__content {
    width: 100%;
    height: 100%;
  }
}
</style>
