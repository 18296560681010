<template>
  <div class="instock-group">
    <CollectionInStockGroupHeading :group="group" />

    <div class="instock-group__products">
      <CollectionInStockGroupProduct
        v-for="product in group.products"
        class="instock-group__product"
        :product="product"
        :key="generateKey(product)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CollectionInStockGroupType } from './CollectionInStock.vue'
import CollectionInStockGroupHeading from './CollectionInStockGroupHeading.vue'
import CollectionInStockGroupProduct from './CollectionInStockGroupProduct.vue'

defineProps<{
  group: CollectionInStockGroupType
}>()

const generateKey = (obj: any) => {
  const str = JSON.stringify(obj)
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash |= 0 // Convert to 32bit integer
  }
  return Math.abs(hash).toString(36).substring(0, 6)
}
</script>

<style lang="scss">
@import '~styles/base.scss';

.instock-group {
}
</style>
