<template>
  <span ref="el" :class="{ 'shopify-link--active': isActive, 'shopify-link--focus': hasFocus }">
    <span class="shopify-link__text">{{ `${frontendSymbol} ${frontendCode}` }}</span>
    <nav :class="`shopify-link__submenu shopify-link__submenu--${isActive ? 'open' : 'closed'}`">
      <button
        v-for="code in SHOPIFY_DEFAULT_CURRENCIES"
        :key="code"
        @click.prevent="() => setCurrency(code)"
        class="shopify-link__button"
      >
        {{ code }}
      </button>
    </nav>
  </span>
</template>

<script lang="ts" setup>
import { ref, onMounted, nextTick } from 'vue'
import { useCurrencyInject } from '../../../context/useCurrencyContext'
import { SHOPIFY_DEFAULT_CURRENCIES } from '../../../services/currency'

withDefaults(
  defineProps<{
    depth: number
  }>(),
  {
    depth: 0,
  }
)

const { frontendCode, frontendSymbol, setCurrency } = useCurrencyInject()
const el = ref<HTMLButtonElement | null>(null)
const hasFocus = ref(false)
const isActive = ref(false)

onMounted(() => {
  console.log(el.value)
  el.value?.addEventListener('mouseover', () => {
    isActive.value = true
  })

  el.value?.addEventListener('mouseout', () => {
    isActive.value = false
  })

  const children = el.value?.querySelectorAll('a,button') || []

  for (const child of children) {
    child.addEventListener('focus', () => {
      hasFocus.value = true
    })
    child.addEventListener('blur', () => {
      hasFocus.value = false
    })
  }
})
</script>

<style lang="scss">
@import '~styles/base';

.shopify-link--currencies {
  .shopify-link {
    &__text {
      cursor: pointer;
    }

    &__submenu {
      display: flex;
      flex-direction: column;
      gap: 4px;

      pointer-events: none;
      opacity: 0;
      transition: opacity 0.2s ease;

      &--open {
        pointer-events: auto;
        opacity: 1;
      }
    }

    &__button {
      margin-left: auto;
    }
  }

  &.shopify-link--focus,
  &:focus {
    .shopify-link {
      &__submenu {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
</style>
