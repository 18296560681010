import { ref, Ref, onMounted, onUnmounted } from 'vue'

interface UseIntersectionResult {
  isIntersecting: Ref<boolean>
}

export default function useIntersection(elementRef: Ref<HTMLElement | null>): UseIntersectionResult {
  const intersectionObserver = ref<IntersectionObserver | null>(null)
  const isIntersecting = ref(false)

  onMounted(() => {
    if (!elementRef.value) {
      return
    }

    intersectionObserver.value = new IntersectionObserver((entries) => {
      isIntersecting.value = entries[0].isIntersecting
    })
    intersectionObserver.value.observe(elementRef.value)
  })

  onUnmounted(() => {
    if (!intersectionObserver.value) {
      return
    }

    intersectionObserver.value.disconnect()
  })

  return {
    isIntersecting,
  }
}
