<template>
  <div class="polish-set-item" v-if="product">
    <button class="polish-set-item__button" @click.prevent="onUpdate">
      <CommonImage :src="swatchImage" :alt="product.featured_image.alt" ratio="1x1" />
    </button>
    <h2 class="polish-set-item__title">{{ product.title.replace(' - Unboxed', '') }}</h2>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { PolishSetContextType, usePolishSetInject } from '../../context/usePolishSetContext'
import { LiquidProduct } from '../../types/liquid'
import { ElementOf } from '../../types/utils'
import CommonImage from '../common/CommonImage.vue'

const props = defineProps<{
  item: ElementOf<PolishSetContextType['items']['value']>
  products: LiquidProduct[]
}>()

const polishSet = usePolishSetInject()

const product = computed(() => {
  return props.products.find((product) => `${product.id}` === props.item.productId)
})

const swatchImage = computed(() => {
  const src = product.value?.metafields.polish_set.swatch[0].cloudinary_src
  return src ? src : product.value?.featured_image.src
})

const quantity = computed(() => {
  const selection = polishSet.selection.value.find(
    (selection) => selection.productId === `${product.value?.id}`
  )
  return selection ? selection.count : 0
})

const onUpdate = () => {
  const variant = product.value?.variants[0]
  if (variant) polishSet.updateVariant(variant, -1 * quantity.value)
}
</script>

<style lang="scss">
.polish-set-item {
  &__title {
    text-transform: uppercase;
    margin-top: 10px;
  }

  &__button {
    position: relative;
    > * {
      display: flex;
    }

    &:before {
      position: absolute;
      content: ' ';
      left: -3px;
      top: -3px;
      right: -3px;
      bottom: -3px;
      border: 1px solid var(--color-dark);
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
      opacity: 1;
    }
  }
}
</style>
