import { provide, inject, Ref, ref, watch } from 'vue'

export type OverlayContextType = {
  current: Ref<string>
  showNewsletter: Ref<boolean>
  close: () => void
  setOverlay: (overlay: string) => void
}

export const OVERLAY_INJECTION_KEY = Symbol('OVERLAY')

export const useOverlayContext = () => {
  const current = ref('')
  const showNewsletter = ref(false)

  const setOverlay: OverlayContextType['setOverlay'] = (overlay: string) => {
    current.value = overlay
  }

  const close = () => setOverlay('')

  watch(current, (overlay) => {
    document.body.classList.toggle('overlay-active', !!overlay)
  })

  document.body.addEventListener('keyup', (event) => {
    if (current.value && event.key === 'Escape') {
      close()
    }
  })

  const values = {
    close,
    current,
    showNewsletter,
    setOverlay,
  }

  provide<OverlayContextType>(OVERLAY_INJECTION_KEY, values)
  return values
}

export const useOverlayInject = () => {
  const context = inject<OverlayContextType>(OVERLAY_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
