export const distinct = (value, index, self) =>
  self.map((v) => JSON.stringify(v)).indexOf(JSON.stringify(value)) === index;

export const groupBy =
  (property = "") =>
  (groups, item) => ({
    ...groups,
    [item[property]]: [...(groups[item[property]] || []), item],
  });

export const isObjectLiteral = (obj) => obj === Object(obj);

const formatForComparison = (collection: any) => {
  return Object.keys(collection)
    .sort()
    .reduce((c: any, key: string) => {
      if (key == "updatedAt") return c;

      return {
        ...c,
        [key]: collection[key],
      };
    }, {});
};

export const areObjectsEqual = (object1: any, object2: any) => {
  const _object1 = formatForComparison({ ...object1 });
  const _object2 = formatForComparison({ ...object2 });

  return JSON.stringify(_object1) == JSON.stringify(_object2);
};
