<template>
  <TransitionHeight>
    <div v-show="isVisible" class="banner" ref="bannerEl">
      <div class="banner__inner">
        <HeaderMarquee :content="htmlContent" />
        <OverlayCloseButton class="banner__close" @click.capture="close" />
      </div>
    </div>
  </TransitionHeight>
</template>

<script lang="ts" setup>
import { Ref, computed, onMounted, onUnmounted, ref } from 'vue'
import { useAppInject } from '../../context/useAppContext'
import TransitionHeight from '../transition/TransitionHeight.vue'
import { useHeaderContext } from '../../context/useHeaderContext'
import HeaderMarquee from './HeaderMarquee.vue'
import OverlayCloseButton from '../overlay/OverlayCloseButton.vue'

const {
  settings: {
    show_banner: show,
    show_banner_row: showRow,
    banner_text: content,
    banner_text_row: contentRow,
  },
} = useAppInject()
const { setBannerHeight } = useHeaderContext()

const country = ref('')
const isHidden = ref(false)
const bannerEl: Ref<HTMLElement | null> = ref(null)

const htmlContent = computed(() => {
  if (country.value === 'US') return content
  return contentRow || content
})
const isVisible = computed(() => {
  if (isHidden.value) return false
  if (!country.value) return false
  if (!htmlContent.value) return false
  if (country.value === 'US' && show) return true
  if (country.value !== 'US' && showRow) return true
  return false
})

const close = () => {
  localStorage.setItem('hideBanner', '1')
  isHidden.value = true
}

onMounted(async () => {
  // const previouslyHidden = localStorage.setItem('hideBanner', htmlContent.value)
  // isHidden.value = `${previouslyHidden}` == htmlContent.value
  isHidden.value = localStorage.getItem('hideBanner') === '1'

  if (bannerEl.value) setBannerHeight(bannerEl.value.offsetHeight)

  const response = await fetch('/browsing_context_suggestions.json')
  const suggestions = await response.json()
  country.value = suggestions?.detected_values?.country.handle || ''
})

onUnmounted(() => {
  setBannerHeight(0)
})
</script>

<style lang="scss" scoped>
@import '~styles/variables';

.banner {
  background: var(--color-background);
  border-bottom: 1px solid;
  pointer-events: auto;

  &.height-enter-active {
    transition-duration: 0ms;
  }

  &__inner {
    /* min-height: 25px; */
    position: relative;
    text-align: center;
    padding: 10px 30px 7px;
    line-height: 1.2;
  }

  &__close {
    top: 11px;
    right: 12px;
    width: 10px;
    height: 10px;
  }

  /* 
  &__close {
    position: absolute;
    cursor: pointer;
    display: flex;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    padding: 5px;
  }

  &__close-icon {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;

    span {
      width: 100%;
      height: 1px;
      background: #000;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);

      + span {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  } */
}
</style>
