<template>
  <div class="home-collection-headlines">
    <div
      v-for="(block, index) in blocks"
      :key="block.collection_url"
      class="home-collection-headlines__headline"
    >
      <a :href="block.collection_url">
        {{ `${block.collection_title}${index < blocks.length ? ',' : ''}` }}
      </a>
      <div class="home-collection-headlines__caption">
        <CommonImage
          :src="block.image_url"
          :alt="`${block.image_alt}`"
          class="home-collection-headlines__image"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import CommonImage from '../common/CommonImage.vue'
// import CaptionedImage from '../common/CommonImageWithCaption.vue'

const props = defineProps<{
  section: {
    blocks: {
      collection_title: string
      collection_url: string
      image_alt: string
      image_url: string
    }[]
  }
}>()

const blocks = computed(() => props.section.blocks)
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-collection-headlines {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: calc(var(--index-section-spacing) * 2) auto;
  max-width: 1200px;
  pointer-events: none;
  position: relative;
  width: 100%;
  z-index: 1;

  &__background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-background);
    z-index: -1;
    opacity: 0;
    transition: opacity 300ms ease 50ms;
  }

  &__headline {
    transition: opacity 150ms ease 100ms;

    > a {
      @include body-xl;

      position: relative;
      z-index: 1;
      pointer-events: auto;
      font-weight: 200;

      &:not(:last-child) {
        margin-right: 0.5ch;
      }

      @include breakpoint('l') {
        @include heading-l;
      }
    }

    @media (hover: hover) {
      &:focus,
      &:hover {
        > .home-collection-headlines__caption {
          opacity: 1;
        }
      }
    }
  }

  &__caption {
    transition: opacity 150ms ease 100ms;
    transform: translate(-50%, -50%);
    position: absolute;
    width: columns(6);
    top: 50%;
    left: 50%;
    opacity: 0;

    @include breakpoint('l') {
    }
  }

  @media (hover: hover) {
    &:focus,
    &:hover {
      .home-collection-headlines {
        &__background {
          opacity: 90%;
        }

        &__headline {
          opacity: 0;

          &:focus,
          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
</style>
