<template>
  <div class="title">
    <a v-if="!isEngraving" :href="line.url">
      <div v-if="titleArray.length > 1" class="name">
        <div>{{ titleArray[0] }}</div>
        <span class="type">({{ titleArray[1] }}</span>
      </div>
      <span v-else-if="titleArray[0]" class="name">{{ titleArray[0] }}</span>
      <span v-else>{{ line.title }}</span>
    </a>
    <div v-else>
      <div v-if="titleArray.length > 1" class="name">
        <div>{{ titleArray[0] }}</div>
        <span class="type">({{ titleArray[1] }}</span>
      </div>
      <span v-else class="name">{{ titleArray[0] }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { LiquidLineItem } from '../../types/liquid';

const props = defineProps<{
  line: LiquidLineItem
}>()

const isEngraving = computed(() => props.line.product?.handle.includes('engraving'))
const titleArray = computed(() => props.line.product?.title.split('(') || [])
</script>

<style lang="scss">
@import '~styles/base.scss';
.title {
  padding-bottom: 15px;

  .name {
    @include uppercase;
  }
}
</style>
