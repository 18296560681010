<template>
  <div class="product-polish-set">
    <div class="product-polish-set__image">
      <ShopifyImage ratio="5x7" :src="product.images[0].src" :alt="product.title" />
    </div>
    <PolishSetHeading class="product-polish-set__heading" />
    <PolishSetAccordion class="product-polish-set__description" />
    <PolishSetSelection class="product-polish-set__selection" :products="products" />
    <OverlayQuestion :product-title="product.title" />
  </div>
</template>

<script lang="ts" setup>
import { usePolishSet } from '../../context/usePolishSetContext'
import { LiquidProduct } from '../../types/liquid'
import ShopifyImage from '../shopify/ShopifyImage.vue'
import PolishSetSelection from '../polish/PolishSetSelection.vue'
import PolishSetHeading from '../polish/PolishSetHeading.vue'
import PolishSetAccordion from '../polish/PolishSetAccordion.vue'
import OverlayQuestion from '../overlay/OverlayQuestion.vue'

const props = defineProps<{
  product: LiquidProduct
  products: LiquidProduct[]
}>()

usePolishSet({ setProduct: props.product })
</script>

<style lang="scss">
@import '~styles/base.scss';
@import '~styles/legacy.scss';

.product-polish-set {
  gap: var(--gutter);
  display: grid;

  @include breakpoint('l+') {
    --columns: #{columns(3)} #{columns(5)} 1fr;
    margin-left: calc(var(--inset) + columns(3, 0));
    grid-template: repeat(2, auto) 1fr / var(--columns);
  }

  @include breakpoint('l') {
    flex-direction: column;
    display: flex;
    margin: 0 var(--inset);
  }

  &__heading {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
  }

  &__image {
    grid-column: 1 / 2;

    @include breakpoint('l+') {
      grid-row: 3;
      margin-top: 30px;
      transform: none;
    }

    @include breakpoint('l') {
      transform: translateX(calc(-1 * var(--inset) + var(--gutter)));
      width: calc(100% + var(--inset) + var(--gutter) * 2);
      margin: 0 calc(-1 * var(--inset)) 30px;
    }
  }

  &__description {
    grid-column: 2 / 3;
    margin-right: 20px;
    max-width: 230px;
    width: auto;

    @include breakpoint('l') {
      max-width: none;
      padding: 0;
      order: 1;
    }
  }

  &__selection {
    grid-column: 3 / 4;
    gap: 20px;
  }
}
</style>
