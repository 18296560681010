<template>
  <div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { ProductFieldsFragment } from '_library/src/storefront/generated'
import { useProductContext } from '../../context/useProductContext'

const props = defineProps<{
  product: ProductFieldsFragment
}>()

useProductContext({
  product: props.product,
})
</script>

<style lang="scss"></style>
