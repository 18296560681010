<template>
  <section class="activate">
    <div class="heading">
      <div class="title">
        <h2>Account Activation</h2>
        <h3>Welcome!</h3>
      </div>
    </div>
    <!-- <div class="form form-activate active">
    {% form 'activate_customer_password' %}
      <p>Enter your new password below to activate your account.</p>
      {{ form.errors | default_errors }}
      <div class="inputs">
        <div class="input"><input name="customer[password]" type="password" placeholder="Password"></div>
        <div class="input"><input name="customer[password_confirmation]" type="password" placeholder="Password Confirmation"></div>
      </div>
      <div class="controls">
        <button>ACTIVATE</button>
        <p class="a"><a href="/">Cancel</a></p>
      </div>
    {% endform %}
  </div> -->
  </section>
</template>

<script lang="ts" setup></script>

<style lang="scss">
@import '~styles/base.scss';
</style>
