<template>
  <div class="NavigationItem">
    <ShopifyAnchor v-if="link.levels === 0" :link="link" class="link">{{ link.title }}</ShopifyAnchor>
    <AccordionItem
      v-else
      :id="_uid"
      :active="current === _uid"
      :heading="link.title"
      :select="() => toggle(_uid)"
    >
      <div class="links">
        <NavigationItem
          v-for="(sublink, i) in link.links"
          :key="i + link.levels + link.url"
          :link="sublink"
        />
      </div>
    </AccordionItem>
  </div>
</template>

<script lang="ts" setup>
import { uniqueId } from 'lodash'
import AccordionItem from './accordion/AccordionItem.vue'
import ShopifyAnchor from './shopify/ShopifyAnchor.vue'
import NavigationItem from './NavigationItem.vue'
import { useAccordionInject } from '../context/useAccordionContext'

const name = 'NavigationItem'
const _uid = uniqueId()

const { current, toggle } = useAccordionInject()

defineProps<{
  link: any
}>()
</script>

<style lang="scss" scoped>
.link {
  display: block;
}
</style>
