<template>
  <div class="home-collections-list">
    <CollectionIndex>
      <CollectionIndexItem
        v-for="(block, index) in section.blocks"
        :key="`collection_index_item_${index}`"
        :count="block.collection_count"
        :index="block.index"
        :title="block.collection_title"
        :url="block.collection_url"
      />

      <template #thumbnail="thumbnailProps">
        <div
          v-for="(block, index) in section.blocks"
          :key="`collection_index_thumbnail_${index}`"
          class="image"
          :class="{ active: block.index == thumbnailProps.activeIndex }"
        >
          <CommonImage
            :key="block.collection_id"
            :alt="block.collection_title"
            :src="block.collection_image"
          />
        </div>
      </template>
    </CollectionIndex>
  </div>
</template>

<script lang="ts" setup="">
import { computed } from 'vue'
import CommonImage from '../common/CommonImage.vue'
import CollectionIndex from '../collection/index/CollectionIndex.vue'
import CollectionIndexItem from '../collection/index/CollectionIndexItem.vue'

const props = defineProps<{
  section: {
    blocks: {
      index: number
      collection_id: number
      collection_title: string
      collection_url: string
      collection_count: number
      collection_image: string
    }[]
  }
}>()

const blocks = computed(() => props.section.blocks)
</script>

<style lang="scss">
@import '~styles/base.scss';

.home-collections-list {
  @include wrapper;
  width: 100%;
}
</style>
