<template>
  <div
    ref="overlayGalleryContainer"
    role="complementary"
    aria-label="Product Gallery"
    class="overlay-gallery"
    :class="`${isActive ? 'overlay-gallery--active' : ''}`"
  >
    <OverlayCloseButton class="overlay-gallery__close" @click="close" />
    <div class="overlay-gallery__pager">{{ pager }}</div>
    <div class="overlay-gallery__container">
      <div class="overlay-gallery__slide" v-for="(slide, slideIdx) in slides" :key="slideIdx">
        <ShopifyMedia :media="slide" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useGalleryIndex } from '../../composables/useGalleryIndex'
import { useOverlayInject } from '../../context/useOverlayContext'
import ShopifyMedia from '../shopify/ShopifyMedia.vue'
import { MediaImage, mapGraph } from '_library'
import { useProductInject } from '../../context/useProductContext'
import OverlayCloseButton from '../overlay/OverlayCloseButton.vue'

const { current, close } = useOverlayInject()
const isActive = computed(() => current.value == 'PRODUCT_GALLERY')
const { product } = useProductInject()
const slides = computed(() => mapGraph(product.media) as MediaImage[])

const overlayGalleryContainer = ref<HTMLElement | null>(null)
const { currentSlide } = useGalleryIndex(overlayGalleryContainer)

const pager = computed(() => `VIEW ${currentSlide.value}/${slides.value.length}`)
</script>

<style lang="scss">
@import '~styles/base';

.overlay-gallery {
  background: var(--color-lightbackground);
  color: var(--color-dark);
  overflow-y: auto;
  position: fixed;
  padding: 0;

  height: var(--100vh);
  left: 0;
  right: 0;
  top: 0;
  z-index: 2000;

  &:not(.overlay-gallery--active) {
    pointer-events: none;
    opacity: 0;
  }

  &__close {
    position: fixed;
    top: 20px;
    right: 30px;
  }

  &__pager {
    text-align: center;
    pointer-events: none;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    line-height: 80px;
    font-size: 28px;
  }

  &__container {
    @include wrapper;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: columns(3, 0);
    padding-right: columns(3, 0);
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include breakpoint('l') {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
