<template>
  <section class="collection-products">
    <div class="collection-products__inner">
      <slot />
      <template v-if="loaded">
        <template v-for="tile in collectionTiles">
          <figure v-if="tile.src" :key="tile.id + '-if'" :class="{ hide: !filteredProducts.length }">
            <CommonImage :src="`${tile.cloudinary_src}w_600,h_800`" :alt="handle" />
          </figure>
          <ProductCard v-else :key="tile.id" :product="tile" />
        </template>
        <CollectionTrigger />
      </template>
      <div class="collection-products__empty" v-if="!loaded || isLoading">
        <ProductCardEmpty />
        <ProductCardEmpty />
        <ProductCardEmpty />
        <ProductCardEmpty />
        <ProductCardEmpty />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import CollectionTrigger from './CollectionTrigger.vue'
import ProductCard from '../product/card/ProductCard.vue'
import ProductCardEmpty from '../product/card/ProductCardEmpty.vue'
import CommonImage from '../common/CommonImage.vue'
import { useCollectionContext } from '../../context/useCollectionContext'
import { computed, unref } from 'vue'

const props = withDefaults(
  defineProps<{
    title: string
    handle: string
    assets?: any
  }>(),
  {
    assets: () => [],
  }
)

const { collectionTiles, filteredProducts, isLoading } = useCollectionContext({
  handle: props.handle,
  assets: props.assets,
})

const loaded = computed(() => unref(collectionTiles).length > 0 && unref(filteredProducts).length > 0)
</script>

<style lang="scss">
@import '~styles/base';

.collection-products {
  @include wrapper;
  position: relative;
  padding-left: columns(2, 0);
  padding-right: columns(2, 0);

  @include breakpoint('l') {
    padding: 0;
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 120px columns(1, 1);

    @include breakpoint('xxl') {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint('xl') {
      grid-template-columns: 1fr 1fr;
      padding: 0 var(--inset);
      grid-auto-rows: auto;
    }

    @include breakpoint('l') {
      grid-auto-rows: auto;
      gap: 60px 20px;
      padding: 0 0;
    }
  }

  &__empty {
    // @include display-m;
    display: contents;
  }

  .IntersectionElement {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 200vh;
    width: 100%;
    pointer-events: none;
  }

  figure {
    display: flex;
    transition: var(--transition-insersection);
    transition: opacity 1s ease;

    @include breakpoint('xl') {
      grid-column: span 2;
      padding-right: 15%;
    }

    &.hide {
      opacity: 0;
    }
  }
}
</style>
