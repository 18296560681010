import { AddLineItemProps, DEFAULT_COUNTRY_CODE, fetchProductByHandle } from '_library'
import { ProductFieldsFragment } from '_library/src/storefront/generated'
import { Ref, computed, onMounted, ref, toValue } from 'vue'
import { ProductSelectionType, useProductSelection } from './useProductSelection'
import { gidToNumber } from '../utils/shopify'

export type ProductChainType = {
  enabled: boolean
  title: string
  isActive: Ref<boolean>
  selection: Ref<ProductSelectionType>
  chainProduct: Ref<ProductFieldsFragment>
  setIsActive: (isActive: boolean) => void
  cartItem: Ref<AddLineItemProps>
}

export const useProductChain = (product: ProductFieldsFragment) => {
  const enabled = product.chainEnabled?.value || false
  const title = product.chainTitle?.value || 'Chain'
  const handle = product.chainProduct?.value || ''
  const isActive: Ref<boolean> = ref(false)
  const chainProduct: Ref<ProductFieldsFragment | null> = ref(null)
  const selection: Ref<ProductSelectionType | null> = ref(null)

  onMounted(async () => {
    if (!enabled) return
    if (!handle) return
    chainProduct.value = await fetchProductByHandle(handle, { countryCode: DEFAULT_COUNTRY_CODE })
    if (chainProduct.value) selection.value = useProductSelection(chainProduct.value)
  })

  const setIsActive = (newIsActive: boolean) => (isActive.value = newIsActive)

  const cartItem = computed(() => {
    if (!enabled) return
    if (!isActive.value) return
    if (!selection.value) return

    const selectedVariant = toValue(selection.value.selectedVariant)
    const variantId = gidToNumber(selectedVariant.id)

    return {
      variantId,
      quantity: 1,
      properties: {
        Product: `${product.title}`,
      },
    }
  })

  return {
    enabled,
    title,
    isActive,
    selection,
    chainProduct,
    setIsActive,
    cartItem,
  } as ProductChainType
}
