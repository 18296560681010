declare var window: any;

const CACHE_MINUTES = 5;

export const wait = (delay: number = 1000): Promise<void> =>
  new Promise((res) => setTimeout(res, delay));

// Check for IndexedDB support
const hasIndexedDB = (): boolean => {
  return "indexedDB" in window;
};

const dbPromise: Promise<IDBDatabase> = new Promise((resolve, reject) => {
  const request = window.indexedDB.open("fetchCacheDB", 1);

  request.onupgradeneeded = (event: any) => {
    const db = event.target.result;
    db.createObjectStore("fetchCache", { keyPath: "key" });
  };

  request.onsuccess = () => {
    resolve(request.result);
  };

  request.onerror = (event: any) => {
    reject(event.target.error);
  };
});

const readFromDB = async (key: string): Promise<any> => {
  const db = await dbPromise;
  const transaction = db.transaction("fetchCache", "readonly");
  const store = transaction.objectStore("fetchCache");
  return new Promise((resolve, reject) => {
    const request = store.get(key);
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject(request.error);
  });
};

const writeToDB = async (key: string, data: any): Promise<void> => {
  const db = await dbPromise;
  const transaction = db.transaction("fetchCache", "readwrite");
  const store = transaction.objectStore("fetchCache");
  return new Promise((resolve, reject) => {
    const request = store.put({ key, data });
    request.onsuccess = () => resolve();
    request.onerror = () => reject(request.error);
  });
};

// LocalStorage fallback functions
const readFromLocalStorage = (key: string): any => {
  const cachedItem = window.localStorage.getItem(key);
  return cachedItem ? JSON.parse(cachedItem) : null;
};

const writeToLocalStorage = (key: string, data: any): void => {
  try {
    window.localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    console.error("LocalStorage write failed", e);
  }
};

export const fetchWithCacheClient = async (
  key: string,
  fetcher: () => Promise<any>,
  expireMinutes: number = CACHE_MINUTES
): Promise<any> => {
  const currentTime = Date.now();

  const readFromCache = hasIndexedDB() ? readFromDB : readFromLocalStorage;
  const writeToCache = hasIndexedDB() ? writeToDB : writeToLocalStorage;

  try {
    const cachedItem = await readFromCache(key);
    if (cachedItem && currentTime < cachedItem.data.expiry) {
      return cachedItem.data.data;
    }
  } catch (error) {
    console.error("Error reading from cache", error);
  }

  // Fetch and cache
  await wait(60); // Throttle uncached responses
  const expiry = currentTime + expireMinutes * 60 * 1000;
  const response = await fetcher();
  const itemToCache = {
    data: response,
    expiry,
  };

  try {
    await writeToCache(key, itemToCache);
  } catch (error) {
    console.error("Error writing to cache", error);
  }

  return response;
};
