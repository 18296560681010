import { provide, inject, Ref, ref, onMounted } from 'vue'
import emblaCarouselVue, { EmblaOptionsType } from 'embla-carousel-vue'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'

export type SlideshowContextProps = {
  sliderConfig?: EmblaOptionsType
  thumbnailConfig?: EmblaOptionsType
}

export type SlideshowContextType = {
  index: Ref<number>
  total: Ref<number>
  emblaRef?: any
  emblaApi?: any
  emblaPaginationRef?: any
  emblaPaginationApi?: any
}

export const SLIDESHOW_INJECTION_KEY = Symbol('SLIDESHOW')

export const useSlideshowContext = (options?: SlideshowContextProps) => {
  const index = ref(0)
  const total = ref(0)
  const sliderConfig = options?.sliderConfig
  const thumbnailConfig = options?.thumbnailConfig

  const [emblaRef, emblaApi] = emblaCarouselVue(
    {
      loop: false,
      ...sliderConfig,
    },
    [WheelGesturesPlugin()]
  )
  const [emblaPaginationRef, emblaPaginationApi] = emblaCarouselVue(
    {
      loop: false,
      ...thumbnailConfig,
    },
    [WheelGesturesPlugin()]
  )

  onMounted(() => {
    if (!emblaApi.value) return
    total.value = emblaApi.value.slideNodes().length

    emblaApi.value?.on('select', () => {
      index.value = emblaApi.value?.selectedScrollSnap() || 0
      if (!emblaPaginationApi.value) return
      emblaPaginationApi.value?.scrollTo(index.value)
    })
  })

  const values = {
    index,
    total,
    emblaRef,
    emblaApi,
    emblaPaginationRef,
    emblaPaginationApi,
  }

  provide<SlideshowContextType>(SLIDESHOW_INJECTION_KEY, values)
  return values
}

export const useSlideshowInject = () => {
  const context = inject<SlideshowContextType>(SLIDESHOW_INJECTION_KEY)
  if (!context) throw new Error()
  return context
}
