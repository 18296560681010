declare var window: any;

type FetcherProps = {
  method?: "GET" | "POST";
  body?: { [key: string]: any };
};

export const fetcher = async (path: string, args?: FetcherProps) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  try {
    const method = args?.method || "GET";
    const body = args?.body ? JSON.stringify(args?.body) : undefined;

    const response = await window?.fetch(path, {
      headers,
      method,
      body,
    });

    return await response.json();
  } catch (e: any) {
    console.log("Fetch error: ", e.message);
    throw e;
  }
};
