<template>
  <button
    aria-label="Close Overlay"
    :class="`overlay-close overlay-close--${current == '' ? 'closed' : 'open'}`"
    @click="close"
  ></button>
</template>

<script lang="ts" setup>
import { useOverlayInject } from '../../context/useOverlayContext'

const { close, current } = useOverlayInject()
</script>

<style lang="scss">
.overlay-close {
  background: var(--color-background);
  transition: opacity 0.5s ease-out;
  pointer-events: none;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 910;
  opacity: 0;
  left: 0;
  top: 0;

  &--open {
    pointer-events: auto;
    opacity: 0.4;
  }
}
</style>
