<template>
  <form class="form-address" @submit.prevent="(e) => emits('onSubmit', e)">
    <InputHidden v-if="address?.id" name="id" :value="`${address?.id || ''}`" />
    <div class="form-address__input form-address__input--half">
      <label for="firstName">First Name</label>
      <InputText required name="firstName" :value="address?.first_name" />
    </div>
    <div class="form-address__input form-address__input--half">
      <label for="lastName">Last Name</label>
      <InputText required name="lastName" :value="address?.last_name" />
    </div>
    <div class="form-address__input">
      <label for="firstName">Company</label>
      <InputText required name="company" :value="address?.company" />
    </div>

    <div class="form-address__input form-address__input--half">
      <label for="firstName">Address 1</label>
      <InputText required name="address1" :value="address?.address1" />
    </div>
    <div class="form-address__input form-address__input--half">
      <label for="firstName">Address 2</label>
      <InputText name="address2" :value="address?.address2" />
    </div>

    <div class="form-address__input form-address__input--half">
      <label for="country">Country</label>
      <InputSelect
        title="Country"
        name="country"
        :options="countryOptions"
        @input="onCountrySelect"
        :value="`${address?.country || ''}`"
      />
    </div>

    <div class="form-address__input form-address__input--half" v-show="provinceOptions">
      <label for="province">Province</label>
      <InputSelect
        v-if="provinceOptions"
        title="Province"
        name="province"
        :options="provinceOptions"
        :value="`${address?.province || ''}`"
      />
    </div>
    <div class="form-address__input form-address__input--half">
      <label for="firstName">City</label>
      <InputText required name="city" :value="address?.city" />
    </div>
    <div class="form-address__input form-address__input--half">
      <label for="firstName">Phone</label>
      <InputText required name="phone" :value="address?.phone || ''" />
    </div>

    <div class="form-address__input">
      <!-- <InputCheckbox title="Default Address" name="default" /> -->
    </div>

    <div class="form-address__controls">
      <button type="submit">Save</button>
      <button @click.prevent="emits('onCancel')">Cancel</button>
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, defineProps, inject, onMounted, ref } from 'vue'
import { LiquidAddress } from '../../types/liquid'
import InputText from '../input/InputText.vue'
import InputCheckbox from '../input/InputCheckbox.vue'
import InputSelect from '../input/InputSelect.vue'
import InputHidden from '../input/InputHidden.vue'
import { CountryProvince } from '../../utils/shopify'

const props = defineProps<{
  address?: LiquidAddress
}>()

const emits = defineEmits(['onCancel', 'onSubmit'])
const countries = inject('countries', []) as CountryProvince[]
const provinceOptions = ref<{ name: string; value: string }[] | null>(null)

const countryOptions = countries.map((_country: CountryProvince) => ({
  name: _country.name,
  value: _country.name,
}))

const onCountrySelect = (value: string) => {
  const country = countries.find((country) => country.name === value)
  if (!country?.provinces) return

  const options =
    country.provinces.map((province) => ({
      name: province.name,
      value: province.name,
    })) || []

  provinceOptions.value = options
}

onMounted(() => {
  if (!props.address?.country) return
  onCountrySelect(props.address.country)
})
</script>

<style lang="scss">
@import '~styles/base.scss';

.form-address {
  display: flex;
  flex-wrap: wrap;
  gap: 0 10px;

  &__input {
    width: 100%;
    margin-bottom: 20px;
    &--half {
      width: calc(50% - 5px);
    }

    label {
      display: block;
    }
    > :not(label) {
      width: 100%;
      padding: 4px 0;
    }
  }

  &__controls {
    display: flex;
    gap: 10px;
    button {
      text-decoration: underline;
    }
  }
}
</style>
