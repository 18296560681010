<template>
  <div class="cart-line-properties">
    <div v-for="[key, value] in filteredProperties" :key="key" class="property">
      <h3>{{ key }}:</h3>
      <span>{{ value }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { LiquidLineItem } from '../../types/liquid';

const props = defineProps<{
  line: LiquidLineItem
}>();

const propertyEntries = computed(() => {
  return Object.entries(props.line.properties || {})
});
  

const filteredProperties = computed(() => {
  return propertyEntries.value.filter(([key]) => !key.match(/^_|adjustment|stock/i))
})
</script>

<style lang="scss" scoped>
h3 {
  text-transform: capitalize;
  margin-right: 0.5ch;
}
.property {
  display: flex;
}
</style>