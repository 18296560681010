<template>
  <div class="product-card-prices">
    <span v-if="!product.availableForSale" class="product-card-prices__sold">Sold Out</span>

    <template v-else-if="hasPriceRange">
      <span>From</span>
      <ShopifyPrice :amount="priceMin" />
      <span>—</span>
      <ShopifyPrice :amount="priceMax" />
    </template>

    <ShopifyPrice class="product-card-prices__price" v-else :amount="priceMin" />
    <ShopifyPrice class="product-card-prices__compare" v-if="priceCompare" :amount="priceCompare" />
  </div>
</template>

<script lang="ts" setup>
import { useProductInject } from '../../../context/useProductContext'
import ShopifyPrice from '../../shopify/ShopifyPrice.vue'

const { product, priceMin, priceMax, priceCompare, hasPriceRange } = useProductInject()
</script>

<style lang="scss">
@import '~styles/base';

.product-card-prices {
  display: flex;
  vertical-align: baseline;
  transition: var(--transition-opacity);
  gap: 4px;

  &__compare {
    margin-left: 5px;
    text-decoration: line-through;
  }

  @media (min-width: 1400px) {
    text-align: right;
  }

  @include breakpoint('xl') {
    margin-top: 10px;
  }
}
</style>
