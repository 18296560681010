import { ShopifySdk } from "../client";
import {
  CollectionByHandleQuery,
  ProductFieldsFragment,
  InputMaybe,
  CountryCode,
} from "../generated";
import { simpleProductsByGids } from "./product";

type WithCountryCode = { countryCode: InputMaybe<CountryCode> };

async function fetchCollectionProducts(
  productIds: string[],
  { countryCode }: WithCountryCode
) {
  const productsResponse: ProductFieldsFragment[] = await simpleProductsByGids(
    productIds,
    { countryCode }
  );

  return productsResponse;
}

type CollectionParams = WithCountryCode & {
  handle: string;
  productLimit?: number;
  productCursor?: string;
};

export async function fetchCollectionWithProducts(
  collectionParams: CollectionParams
) {
  const collectionResponse: CollectionByHandleQuery =
    await ShopifySdk.collectionByHandle({
      ...collectionParams,
      productLimit: collectionParams.productLimit || 200,
    });

  const productIds: string[] =
    collectionResponse.collectionByHandle?.products?.edges?.map(
      (product: any) => product.node.id
    ) || [];

  const products = await fetchCollectionProducts(productIds, {
    countryCode: collectionParams.countryCode,
  });

  return {
    ...collectionResponse.collectionByHandle,
    products: [...products],
    hasNextPage:
      collectionResponse.collectionByHandle?.products.pageInfo.hasNextPage,
    lastItemCursor:
      collectionResponse.collectionByHandle?.products?.edges?.slice(-1)[0]
        ?.cursor,
  };
}

export async function fetchSearchWithProducts(query = "", cursor: null) {
  const { products } = await ShopifySdk.searchWithProducts({
    query,
    cursor,
  });

  return {
    hasNextPage: products.pageInfo.hasNextPage,
    lastItemCursor: products?.edges.slice(-1)[0]?.cursor,
    products: [...products.edges.map((edge) => edge.node)],
  };
}

export async function fetchSearchWithAllProducts(handle = "", onProgress: any) {
  let results: any = [];

  const fetchProducts: any = async (cursor: any) => {
    const { products, hasNextPage, lastItemCursor } =
      await fetchSearchWithProducts(handle, cursor);

    results = [...results, ...products];

    if (hasNextPage) {
      onProgress(results);
      return fetchProducts(lastItemCursor);
    }

    onProgress(results);
    return true;
  };

  return fetchProducts();
}
