<template>
  <div class="route-div" v-if="isActive"></div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useCartInject } from '../../../context/useCartContext'

const { itemCount, cart } = useCartInject()

const isActive = computed(() => {
  if (!cart.value) return false
  if (itemCount.value < 1) return false

  const url = window.location.hostname
  if (url.includes('localhost')) return false

  return true
})
</script>

<style lang="scss">
@import '~styles/base.scss';

.route-div {
  padding: 0 10px;
  border-top: 1px solid var(--color-dark);

  .route-widget {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    .rw-contents {
      margin: auto;
      line-height: inherit;
      .rw-right {
        .rw-checkbox-span {
          &.rw-checked {
            background: var(--color-dark);
          }
          &.rw-unchecked {
            background: var(--color-lightestgrey);
          }
          .rw-on-text,
          .rw-off-text {
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
    }
  }
  .rw-center {
    .rw-text-top {
      font-size: inherit;
      font-weight: inherit;
    }
    .rw-text-bottom {
      font-size: inherit;
      line-height: inherit;
    }
  }
}
</style>
